import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-billing-logos-section',
  templateUrl: './billing-logos-section.component.html',
  styleUrls: ['./billing-logos-section.component.scss'],
})
export class BillingLogosSectionComponent {
  @Input() title?: string;
  @Input() logos: { filename: string, alt: string, width: string, height: string }[] = [];
  @Input() alignment: 'center center' | 'start center' | 'end center' = 'center center';
  @Input() logoPadding = '0';
  @Input() opacity = '0.5';
  @Input() applySocialProofClass = false;

  constructor() {}
}
