<app-billing-detail-base-item>
  <div left-column class="left-column-wrapper">
    <span *ngIf="label">{{ label }}</span>
    <ng-content select="[label-info]"></ng-content>
  </div>
  <div right-column fxLayout style="column-gap: 16px">
    <span *ngIf="content; else contentTemplate">{{ content }}</span>
    <mat-icon *ngIf="actionableIcon" style="color: #A4A7B2; cursor: pointer"
        (click)="handleIconClick()">{{ actionableIcon }}
    </mat-icon>
    <ng-content select="[callout]"></ng-content>
  </div>
</app-billing-detail-base-item>

<footer>
  <ng-content select="[footer-content]"></ng-content>
</footer>

<ng-template #contentTemplate>
  <ng-content select="[content]"></ng-content>
</ng-template>
