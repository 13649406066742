import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { take, tap } from 'rxjs/operators';
import { BillingError } from 'src/app/pages/new-billing/models/billing-error';
import { ConcretePlan } from 'src/app/pages/new-billing/models/plan.model';
import { InvoicePreviewData, InvoicePreviewItem } from 'src/app/pages/new-billing/types/api.types';
import { BillingService } from 'src/app/services/billing/billing.service';


export type InvoicePreviewDialogData = {
  currentPlan: ConcretePlan;
};


@Component({
  selector: 'app-upcoming-invoice-preview',
  templateUrl: './upcoming-invoice-preview.component.html',
  styleUrls: ['./upcoming-invoice-preview.component.scss'],
})
export class UpcomingInvoicePreviewComponent implements OnInit {
  skeletonItems = Array(7);
  status: 'loading' | 'idle' | 'error' = 'loading';
  errorTitle: string;
  errorDesc: string;

  displayedColumns: string[] = ['product', 'quantity', 'unitPrice', 'amount'];
  dataSource = new MatTableDataSource([] as InvoicePreviewItem[]);
  upcomingInvoiceData: InvoicePreviewData;

  get disclaimerRecurrence() {
    return this.data.currentPlan.paymentModel.paymentRecurrence === 'month'
      ? 'billing.recurrenceMonthly'
      : 'billing.recurrenceAnnual';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InvoicePreviewDialogData,
    private billingService: BillingService,
  ) {}

  public async ngOnInit() {
    this.initTable();
  }

  private initTable(): void {
    this.billingService.getUpcomingInvoicePreview().pipe(
      take(1),
      tap((invoicePreviewData) => this.upcomingInvoiceData = invoicePreviewData),
    ).subscribe({
      next: (invoicePreviewData) => {
        this.dataSource.data = invoicePreviewData.items;
        this.status = 'idle';
      },
      error: (error: unknown) => {
        this.status = 'error';
        this.errorTitle = 'billing.invoice.errors.default.title';
        this.errorDesc = 'billing.invoice.errors.default.description';

        if (error instanceof BillingError && error.errorCode === 'unsupported_subscription_status') {
          this.errorTitle = 'billing.invoice.errors.subscriptionLocked.title';
          this.errorDesc = 'billing.invoice.errors.subscriptionLocked.description';
        }
      },
    });
  }
}
