<ng-container *ngIf="status === 'loading'">
  <ng-container *ngTemplateOutlet="loader"></ng-container>
</ng-container>
<ng-container *ngIf="status === 'idle'">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
<ng-container *ngIf="status === 'error'">
  <ng-container *ngTemplateOutlet="error"></ng-container>
</ng-container>

<ng-template #content>
  <section class="content-wrapper">
    <header>
      <h3 class="typography-h6">{{ 'billing.invoice.upcomingPreview' | translate }}</h3>
      <mat-icon class="close-icon" mat-dialog-close>close</mat-icon>
    </header>

    <div class="row-group">
      <h6 class="col-item">{{ 'billing.invoice.billingPeriod' | translate }}</h6>
      <span class="col-item">
        {{upcomingInvoiceData.billingCycleStart | date}}
        {{ 'common.to' | translate | lowercase }}
        {{upcomingInvoiceData.billingCycleEnd | date}}
      </span>
    </div>

    <mat-table matSort [dataSource]="dataSource" class="invoice-table" #table="matTable">
      <!-- Product Column -->
      <ng-container matColumnDef="product">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'billing.invoice.product' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let invoice;table: table">
          <h5 class="cell-title">{{ 'billing.invoice.product' | translate }}</h5>
          <div>
            <span>{{ invoice.product | translate }}</span>
            <span *ngIf="invoice.startDate && invoice.endDate">
              {{invoice.startDate | date}}
              {{ 'common.to' | translate | lowercase }}
              {{invoice.endDate | date}}
            </span>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Quantity Column -->
      <ng-container matColumnDef="quantity">
        <mat-header-cell class="border" *matHeaderCellDef mat-sort-header>{{ 'billing.invoice.quantity' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let invoice;table: table">
          <h5 class="cell-title">{{ 'billing.invoice.quantity' | translate }}</h5>
          <span>{{invoice.quantity}}</span>
        </mat-cell>
      </ng-container>

      <!-- Unit price Column -->
      <ng-container matColumnDef="unitPrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'billing.invoice.unitPrice' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let invoice;table: table">
          <h5 class="cell-title">{{ 'billing.invoice.unitPrice' | translate }}</h5>
          <span>{{invoice.unitPrice / 100 | currency}}</span>
        </mat-cell>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'billing.amount' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let invoice;table: table">
          <h5 class="cell-title">{{ 'billing.amount' | translate }}</h5>
          <span>{{invoice.amount / 100 | currency}}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <div class="row-group with-top-divider">
      <h6 class="col-item">{{ 'billing.disc' | translate }}</h6>
      <span class="col-item totals">
        {{ ((+upcomingInvoiceData.totals.discount / 100) === 0 ? '' : '-') + (+upcomingInvoiceData.totals.discount / 100
        |
        currency) }}
      </span>
    </div>
    <div class="row-group">
      <h6 class="col-item">{{ 'billing.tax' | translate }}</h6>
      <span class="col-item totals">{{+upcomingInvoiceData.tax / 100 | currency}}</span>
    </div>
    <div class="row-group">
      <h6 class="col-item">{{ ('billing.total' | translate) + '*' }}</h6>
      <span class="col-item totals">{{+upcomingInvoiceData.totals.grandTotal / 100 | currency}}</span>
    </div>

    <footer>
      <p class="typography-caption">
        {{ 'billing.invoice.disclaimerForTotal' | translate: { recurrence: disclaimerRecurrence | translate } }}
      </p>
    </footer>
  </section>
</ng-template>

<ng-template #loader>
  <div class="skeleton-loader">
    <span class="skeleton-item" style="height: 40px; width: 50%"></span>
    <div fxLayout="column" fxLayoutGap="8px">
      <span *ngFor="let item of skeletonItems" class="skeleton-item"></span>
    </div>
    <span class="skeleton-item" [style.height]="'48px'"></span>
  </div>
</ng-template>

<ng-template #error>
  <div class="error-wrapper">
    <mat-icon class="close-icon" mat-dialog-close>close</mat-icon>
    <app-empty-state>
      <span title>{{ errorTitle | translate }}</span>
      <span description>{{ errorDesc | translate }}</span>
    </app-empty-state>
  </div>
</ng-template>
