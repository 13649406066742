<div class="completed-steps">
  <mat-checkbox class="custom-checkbox" [checked]="isChecked" [disabled]="true">
  </mat-checkbox>
  <div class="completed-steps-text-container">
    <span class="completed-steps-text" [class.completed]="isChecked">
      {{ stepTitle }}
    </span>
    <a class="completed-steps-link" routerLink="/dashboard" *ngIf="isGettingStartedExperiment">
      {{ 'animations.stepCompletionNotification.seeYourProgress' | translate }}
    </a>
  </div>
</div>
