import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-step-completion-notification',
  templateUrl: './step-completion-notification.component.html',
  styleUrls: ['./step-completion-notification.component.scss'],
})
export class StepCompletionNotificationComponent implements OnInit {
  @Input() stepId!: string;
  @Input() isGettingStartedExperiment?: boolean;

  stepTitle = '';
  isChecked = false;

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit() {
    this.stepTitle = this.translateService.instant(`sidebar.title.${this.stepId}`);
    setTimeout(() => {
      this.isChecked = true;
    }, 1000);
  }
}
