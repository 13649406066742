import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of, switchMap } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { getDisableOnboarding, isTimeDoctor } from 'src/app/app/app.constants';
import { hasMandatoryCall } from 'src/app/common/menu-def.provider';
import { NewBillingState } from 'src/app/pages/new-billing/store/state';
import { BrandingService } from 'src/app/services/branding.service';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';
import { AuthState } from 'src/app/store/auth/auth.state';
import { SetGettingStartedScreenExperimentVariant } from 'src/app/store/onboarding/onboarding.actions';
import { AuthCompany, CompanySubscription } from 'src/models';
import { GrowthBookService } from '../growthbook/service';
import { ExperimentProps } from '../growthbook/types';
const allowedStatuses: Array<CompanySubscription['status']> = ['paid', 'trial'];

@Injectable({
  providedIn: 'root',
})

export class TrialWidgetService {
  constructor(
    private readonly store: Store,
    private readonly branding: BrandingService,
    private readonly growthBook: GrowthBookService,
  ) { }

  canShowTrialWidget() {
    const billingDetails$ = this.store.select(NewBillingState.updatedBillingDetails);

    return this.store.select(AuthSelectors.company).pipe(
      switchMap((company) => {
        return !company?.id ? of(false) : billingDetails$.pipe(
          filter((billingDetails) => !!billingDetails?.customerStatus),
          map(({ customerStatus }) => {
            const { userSettings, role, id } = company;
            const isCompanyInTrial = customerStatus === 'trial';
            const hasBillingAccess = !this.branding.billingDisabled() && (role === 'owner' || userSettings?.billingAccess);

            return !isTimeDoctor(id) && isCompanyInTrial && hasBillingAccess;
          }),
        );
      }),
    );
  }

  canSeeOnboardingWidget(): Observable<boolean> {
    const company = this.store.selectSnapshot(AuthState.company);

    const { userSettings, id: companyId, subscription } = company;
    const custom = userSettings?.custom;

    const hideSidebar = getDisableOnboarding();
    const trackedAllCompleted = custom?.completedStep?.['trackedAll'];
    const allowedSubscriptionStatus = allowedStatuses.includes(subscription.status);

    return of(
      !hideSidebar &&
      !isTimeDoctor(companyId) &&
      allowedSubscriptionStatus &&
      !custom?.welcomeSidebarCompleted &&
      !trackedAllCompleted &&
      !hasMandatoryCall(company),
    );
  }

  canSeeSetupYourAccountWidget(): Observable<boolean> {
    return this.store.select(AuthState.company).pipe(
      filter((company): company is AuthCompany => !!company),
      take(1),
      mergeMap(({ userSettings: { custom } }) => {
        // Early return for existing experiment variants
        if (custom?.gettingStartedExperimentVariant === 'B') {
          return of(!custom.completedStep?.trackedAll);
        }
        if (custom?.gettingStartedExperimentVariant === 'A') {
          return of(false);
        }

        // Get new experiment variant
        return this.growthBook.getFeature<ExperimentProps>('getting-started-experiment').pipe(
          filter((exp): exp is ExperimentProps => !!exp?.variationid && ['A', 'B'].includes(exp.variationid)),
          take(1),
          tap(({ variationid }) => {
            this.store.dispatch(new SetGettingStartedScreenExperimentVariant(variationid));
          }),
          map(({ variationid }) => variationid === 'B'),
        );
      }),
    );
  }
}
