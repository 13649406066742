import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { PaymentSummaryInfo } from 'src/app/pages/new-subscribe-plans/store/model';
import { Store } from '@ngxs/store';
import { NewBillingState } from 'src/app/pages/new-billing/store/state';
import { SubscriptionStatus } from 'src/app/pages/new-billing/types/api.types';

@UntilDestroy()
@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss'],
})
export class PaymentSummaryComponent implements OnInit {
  @Input() inputData: PaymentSummaryInfo;

  isLoadingAction: boolean;
  errorMsg: string;

  cardInfo: PaymentSummaryInfo['cardInfo'];
  planInfo: PaymentSummaryInfo['planInfo'];
  buttons: PaymentSummaryInfo['buttons'];

  data: PaymentSummaryInfo;
  planSelected: string;

  status: SubscriptionStatus;
  currentDate: Date = new Date();


  get titleDate(): string {
    return this.titleMap(this.status);
  }

  get paymentDate(): Date {
    return this.status === 'trial' ? this.data.nextPaymentDate as unknown as Date : new Date();
  }

  get paymentMethodTextKey() {
    return this.cardInfo ? 'billing.paymentMethod' : 'billing.updatePaymentMethod';
  }

  get iconName() {
    switch (this.dialogData.type) {
      case 'info': return 'info';
      case 'error': return 'error';
      case 'warning': return 'report_problem';
      default: return 'check_circle';
    }
  }

  constructor(
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public dialogData: PaymentSummaryInfo,
  ) {
  }

  ngOnInit() {
    this.data = this.inputData ?? this.dialogData;
    const { cardInfo, planInfo, buttons, onCardUpdate } = this.data;


    this.cardInfo = cardInfo;
    this.planInfo = planInfo;
    this.buttons = buttons;

    this.status = this.store.selectSnapshot(NewBillingState.updatedBillingDetails).customerStatus;

    this.planSelected = this.translateService.instant(`billing.${planInfo.name}`);
    onCardUpdate?.pipe(
      untilDestroyed(this),
    )?.subscribe(({ cardLast4 }) => {
      this.cardInfo.cardLastFour = cardLast4;
      this.changeDetectorRef.markForCheck();
    });
  }

  titleMap(status: SubscriptionStatus) {
    return {
      trial: 'billing.nextPaymentDate',
      past_due: 'billing.date',
    }[status];
  }
}
