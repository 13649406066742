<div class="modal-overlay">
  <div class="modal-content">
    <div class="theme-preview-section">
      <div class="theme-preview-backgrounds">
        <div class="theme-background old"></div>
        <div class="theme-background new" [@slideOverlay]="animationState"></div>
        <div class="background-shape" [class.animate]="animationState === 'visible'"></div>
      </div>
      <div class="theme-images">
        <img src="assets/images/theme-change-modal/old-theme.svg" alt="Old Theme" class="old-theme" (load)="onImageLoad()">
        <img src="assets/images/theme-change-modal/new-theme.svg" alt="New Theme" class="new-theme" (load)="onImageLoad()" [@slideOverlay]="animationState">
        <div class="reveal-line" [class.animate]="animationState === 'visible'"></div>
      </div>
    </div>

    <div class="modal-info">
      <h2>{{'themeChangeModal.title' | translate}}</h2>
      <p>{{'themeChangeModal.description' | translate}}</p>
      <button mat-flat-button color="primary" (click)="onGotItClick()">{{'themeChangeModal.gotIt' | translate}}</button>
    </div>
  </div>
</div>
