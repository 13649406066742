import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { take } from 'rxjs';
import { STEP_PROGRESS_MESSAGES } from 'src/app/components/account-setup-accordion/step-ids.const';
import { AddCreditCardStep } from 'src/app/components/starter-sidebar/items/add-credit-card';
import { AnimationService } from 'src/app/services/animation/animation.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserSettingsChanged } from '../auth/auth.actions';
import { AuthState } from '../auth/auth.state';
import {
  CompleteWelcomeStep,
  DismissWelcomeBanner,
  DismissWelcomeStep,
  OnboardingWizardConfig,
  ResetOnboardingWizardConfig, SetGettingStartedScreenExperimentVariant,
  SetOnboardingWizardConfig,
  ToggleSidebarCollapsed
} from './onboarding.actions';

export interface OnboardingStateModel {
  sidebarCollapsed: boolean;
  showWizard: boolean;
  wizardConfig: OnboardingWizardConfig;
  trackedSteps?: {
    [key: string]: boolean;
  };
}

@State<OnboardingStateModel>({
  name: 'onboarding',
  defaults: {
    sidebarCollapsed: false,
    showWizard: false,
    wizardConfig: {},
  },
})
@Injectable()
export class OnboardingState {

  @Selector()
  public static sidebarCollapsed(state: OnboardingStateModel) {
    return state.sidebarCollapsed;
  }

  @Selector()
  public static wizardConfig(state: OnboardingStateModel) {
    return state.wizardConfig;
  }

  constructor(
    private companyService: CompanyService,
    private userService: UserService,
    private store: Store,
    private segment: SegmentService,
    private readonly animationService: AnimationService,
  ) {
  }

  @Action(ToggleSidebarCollapsed)
  toggleSidebarCollapsed({ patchState }: StateContext<OnboardingStateModel>, { collapsed }: ToggleSidebarCollapsed) {
    patchState({ sidebarCollapsed: collapsed });
  }

  @Action(CompleteWelcomeStep)
  CompleteWelcomeStep({ dispatch }: StateContext<OnboardingStateModel>, { stepId }: CompleteWelcomeStep) {
    const company = this.store.selectSnapshot(AuthState.company);
    const { id, companySettings, userSettings } = company;
    const role = this.store.selectSnapshot(AuthState.role);

    if (role !== 'owner') return;
    if (userSettings?.custom?.trackedSteps?.[stepId]) return;

    // Handle credit card step specific logic when added during onboarding
    if (stepId === AddCreditCardStep.stepId && !companySettings?.custom?.completedStep?.[stepId]) {
      this.companyService.putCompanySettings(id, { [`custom.completedStep.${stepId}`]: true })
        .pipe(take(1))
        .subscribe();
    }

    // Track progress
    const isGettingStartedExperiment = userSettings?.custom?.gettingStartedExperimentVariant === 'B';
    if (isGettingStartedExperiment && stepId !== 'trackedAll') {
      this.segment.trackInAppEvents('Setup Your Account Widget Progress', {
        progress: STEP_PROGRESS_MESSAGES[stepId],
      });
    } else {
      this.segment.trackSideBarActivity(stepId);
    }

    // Mark the step as completed
    this.userService.editProperties('me', { [`custom.completedStep.${stepId}`]: true, [`custom.trackedSteps.${stepId}`]: true})
      .pipe(take(1))
      .subscribe();

    dispatch(new UserSettingsChanged(null, {
      completedStep: { [stepId]: true },
      trackedSteps: { [stepId]: true },
    }));

    // Celebrate completion
    if (stepId !== 'trackedAll') {
      this.animationService.celebrateCompletedStep(stepId, isGettingStartedExperiment);
    }
  }


  @Action(DismissWelcomeStep)
  DismissWelcomeStep({ dispatch }: StateContext<OnboardingStateModel>, { stepId }: DismissWelcomeStep) {
    if (this.store.selectSnapshot(AuthState.role) !== 'owner') { return; }
    this.segment.trackSideBarActivity(stepId, true);
    this.userService.editProperties('me', { [`custom.dismissedStep.${stepId}`]: true }).subscribe();
    dispatch(new UserSettingsChanged(null, { dismissedStep: { [stepId]: true } }));
  }

  @Action(DismissWelcomeBanner)
  DismissWelcomeBanner({ dispatch }: StateContext<OnboardingStateModel>) {
    if (this.store.selectSnapshot(AuthState.role) !== 'owner') { return; }
    this.userService.editProperties('me', { 'custom.welcomeSidebarCompleted': true } as any).subscribe();
    dispatch(new UserSettingsChanged(null, { welcomeSidebarCompleted: true }));
  }

  @Action(SetOnboardingWizardConfig)
  SetOnboardingWizardConfig({ patchState, getState }: StateContext<OnboardingStateModel>, { config }: SetOnboardingWizardConfig) {
    patchState({ wizardConfig: { ...getState().wizardConfig, ...config } });
  }

  @Action(ResetOnboardingWizardConfig)
  ResetOnboardingWizardConfig({ patchState }: StateContext<OnboardingStateModel>) {
    patchState({ wizardConfig: {} });
  }

  @Action(SetGettingStartedScreenExperimentVariant)
  SetGettingStartedScreenExperimentVariant({ dispatch, patchState, getState }: StateContext<OnboardingStateModel>, { variantId }) {
    this.userService.editProperties('me', { 'custom.gettingStartedExperimentVariant': variantId } as any).subscribe();
    dispatch(new UserSettingsChanged(null, { gettingStartedExperimentVariant: variantId }));
  }
}
