import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Event as NavigationEvent, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { noSideBarUrls } from 'src/app/components/nav-sidebar/nav-sidebar.component';
import { RevenueHeroModel } from 'src/app/integrations/revenuehero/revenue-hero-model';
import { MeetingBookedEvent } from 'src/app/integrations/revenuehero/types';
import { ConcretePlan } from 'src/app/pages/new-billing/models/plan.model';
import { NewBillingState } from 'src/app/pages/new-billing/store/state';
import { BillingApiData, BillingDetailsApiData } from 'src/app/pages/new-billing/types/api.types';
import { ExperimentsService } from 'src/app/services/experiments/experiments.service';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { PlanSelectionService } from 'src/app/services/plan-selection/plan-selection.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { AuthState } from 'src/app/store/auth/auth.state';
import { PopLoading, PushLoading } from 'src/app/store/loading/loading.actions';
import { TrialWidgetState } from 'src/app/store/trial-widget/trial-widget.state';
import { environment } from 'src/environments/environment';
import { AuthCompany, AuthUser } from 'src/models';
import { ExperimentProps } from 'src/app/services/growthbook/types';

@UntilDestroy()
@Component({
  selector: 'app-trial-widget',
  templateUrl: './trial-widget.component.html',
  styleUrls: ['./trial-widget.component.scss'],
})
export class TrialWidgetComponent implements OnInit, AfterViewInit  {
  @Select(AuthState.company) company$: Observable<AuthCompany>;
  @Select(NewBillingState.billingMode) billingMode$: Observable<BillingDetailsApiData['billingMode']>;
  @Select(NewBillingState.billingData) billingData$: Observable<BillingApiData>;
  @Output() panelOpened = new EventEmitter<number>();
  @Output() panelClosed = new EventEmitter<unknown>();

  hasCard = false;
  @Input() isDragging = false;

  //properties related to getting started screen
  panelOpenState = false;
  readonly widgetHeightMinimized = 40;
  readonly fullWidgetHeightWith = 140;
  readonly fullWidgetHeightWithBookACall = 200;

  @Input()
  set isCollapsed(willBeCollapsed: boolean) {
    if (!willBeCollapsed && this._isCollapsed) {
      this.trackWidgetViewed();
    }

    this._isCollapsed = willBeCollapsed;
  }

  get isCollapsed() { return this._isCollapsed; }

  @Input() variant: 'raised' | 'outlined' | 'accordion' = 'raised';
  @Input() isOnFloatingWidget?: boolean = false;

  private currentPlan: ConcretePlan;
  private _isCollapsed: boolean;
  trialDaysLeft = 0;
  trialStartDate: DateTime;
  trialEndDate: DateTime;
  currentUrl: string;
  user: AuthUser;
  revenueHeroInit: RevenueHeroModel;
  isWidgetExpanded = true;
  basePlan = '';
  isAddCardLoading = false;

  get isAddCardExperiment() {
    return this.experimentsService.isAddCardExperiment;
  }

  @HostBinding('class.outlined') get outlined() {
    return this.variant === 'outlined';
  }

  @HostBinding('class.collapsed') get collapsed() {
    return this.isCollapsed;
  }

  @HostBinding('class.dragging') get draggingClass(): boolean {
    return this.isDragging;
  }

  btnClickTrackData() {
    const standardTrialWidgetTrackName = this.isAddCardExperiment
        ? 'Clicked Add credit card On Trial Widget'
        : 'Clicked Upgrade On Trial Widget';
    const gettingStartedWidgetTrackName = this.isAddCardExperiment
        ? 'Clicked Add credit card On Getting Started Trial Widget'
        : 'Clicked Choose Your Plan On Getting Started Trial Widget';
    const page = this.variant !== 'accordion' ? standardTrialWidgetTrackName : gettingStartedWidgetTrackName;
    return {
      page,
      trialstartdate: this.trialStartDate?.toFormat('yyyy-MM-dd'),
      trialenddate: this.trialEndDate?.toFormat('yyyy-MM-dd'),
      currenturl: this.currentUrl,
    };
  }

  constructor(
      private readonly router: Router,
      private readonly store: Store,
      private readonly segment: SegmentService,
      private readonly growthbookService: GrowthBookService,
      private readonly planSelectionService: PlanSelectionService,
      private readonly experimentsService: ExperimentsService,
      private readonly cdr: ChangeDetectorRef,
  ) {
    this.user = this.store.selectSnapshot(AuthState.user);

    this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });

    router.events.pipe(
      untilDestroyed(this),
      filter((event) => event instanceof NavigationEnd
        && !noSideBarUrls.some((url) => event.urlAfterRedirects.includes(url)),
      ),
      filter(() => !this.isCollapsed && this.variant !== 'accordion'),
    ).subscribe(() => {
      this.trackWidgetViewed();
    });
  }

  private trackWidgetViewed() {
    if (this.variant === 'accordion') return;
    this.segment.trackInAppEvents('Trial Widget Viewed', {
      status: 'trial',
      version: this.store.selectSnapshot(TrialWidgetState.getTrialWidgetType),
    });
  }

  ngOnInit() {
    this.displayTrialDays();
    this.initializeBillingData();
  }

  ngAfterViewInit() {
    if (this.isOnFloatingWidget) {
      document.body.classList.add('has-floating-widget');
    }
  }

 private initializeBillingData(): void {
    this.billingData$
      .pipe(
        untilDestroyed(this),
        filter(this.isValidBillingData),
        map(billingData => ({
          billingData,
          userCount: this.store.selectSnapshot(AuthState.company).userCount,
        })),
      )
      .subscribe(({ billingData, userCount }) => {
        this.currentPlan = new ConcretePlan(
          billingData,
          userCount,
          this.growthbookService.getFeatureValue('billing:discount_removal_companies', false),
        );
      });
  }

  private isValidBillingData(billingData: BillingApiData): boolean {
    return Boolean(billingData?.plans?.length);
  }

  private getBasePlan(pricingPlan) {
    if (!pricingPlan) return '';

    const basePlan = pricingPlan.includes('_') ? pricingPlan.split('_')[0] : pricingPlan;
    return basePlan.charAt(0).toUpperCase() + basePlan.slice(1);
  }

  private hasCreditCard(company: AuthCompany) {
    return company?.companySettings?.custom?.completedStep?.addCreditCard
      || company?.userSettings?.custom?.completedStep?.addCreditCard
      || false;
  }

  private async displayTrialDays() {
    this.company$
      .pipe(untilDestroyed(this))
      .subscribe(company => {
        this.basePlan = this.getBasePlan(company?.pricingPlan);
        this.hasCard = this.hasCreditCard(company);

        if (company) {
          this.trialStartDate = DateTime.fromISO(company.companyCreatedAt);
          this.trialEndDate = DateTime.fromISO(company.subscription.expires);
          this.trialDaysLeft = Math.floor(this.trialEndDate.diff(DateTime.now(), 'days').days);
        }
      });
  }

  async trialUpgrade() {
    if (this.isAddCardExperiment) {
      this.planSelectionService.handlePlanSelection(
        this.currentPlan, this.shouldShowSocialProofOnBillingPage, {
        page: 'trial widget',
        location: this.variant === 'accordion' ? 'floating widget' : 'sidebar widget',
      });
      this.toggleClosePanelState();
      return;
    }
    const currentPath = this.router.url;
    const subscription = this.store.selectSnapshot(AuthState.company)?.subscription;

    let path = subscription?.provider === 'paddle'
      ? '/new-subscribe-plans'
      : '/subscribe-plans';

    path = !this.hasCard ? path : '/new-billing';
    this.router.navigate([path], {queryParams: {url: encodeURI(currentPath)}});
  }

  get hasBookACallAccess() {
    return this.growthbookService.getFeatureValue('book-call-sales-v2');
  }

  contactSales() {
    this.store.dispatch(new PushLoading());
    this.segment.trackInAppEvents(this.variant !== 'accordion' ? 'Clicked Book A Call On Trial Widget' : 'Clicked Book a Call On Getting Started Trial Widget');
    this.revenueHeroInit = new RevenueHeroModel({
      user: this.user,
      routerId: environment.trialWidgetRevenueHeroRouterId,
      scheduleId: environment.trialWidgetRevenueHeroScheduleId,
    });
      this.revenueHeroInit.initialize();
      this.store.dispatch(new PopLoading());
      this.revenueHeroInit.onMeetingBooked = (e: MeetingBookedEvent) => {
        this.trackContactSales(e);
      };
  }

  private trackContactSales(e: MeetingBookedEvent) {
    const {
      booker_email: bookerEmail,
      booking_participants: bookingParticipants,
      booker_name: bookerName,
      reschedule_count: rescheduleCount,
      status: meetingStatus,
    } = e.data.meeting.attributes;

    this.segment.trackInAppEvents('Book a Call Confirmation Pop up', {
      bookingParticipants,
      bookerEmail,
      bookerName,
      rescheduleCount,
      meetingStatus,
    });
  }

  toggleOpenPanelState() {
    this.panelOpenState = true;
    this.panelOpened.emit(this.hasBookACallAccess ? this.fullWidgetHeightWithBookACall : this.fullWidgetHeightWith);
  }

  toggleClosePanelState() {
    this.panelOpenState = false;
    this.panelClosed.emit(this.widgetHeightMinimized);
    this.cdr.markForCheck();
  }

  getButtonText(): string {
    if (this.isAddCardExperiment && !this.hasCard) {
      return 'sidebar.widget.buttonAddCard';
    }
    return this.hasCard ? 'sidebar.widget.buttonManagePlan' : 'sidebar.widget.button';
  }

  get shouldShowSocialProofOnBillingPage(): boolean {
    const feature = this.growthbookService.getFeatureValue<ExperimentProps>('billing-page-social-proof-exp');
    return feature?.variationid === 'B';
  }
}
