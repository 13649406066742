<div class="upgrade-billing-container">
  <button mat-icon-button class="close-button" (click)="handleCancel()">
    <mat-icon>clear</mat-icon>
  </button>
  <app-billing-details>
    <p *ngIf="cardPlan.paymentModel.cardLast4 && data.currentPlan.subscription.status === 'trial'" description>
      {{ 'billing.creditCardChargedMessage' | translate}}
    </p>
    <app-actionable-item [label]="'billing.plan' | translate" [content]="'billing.' + cardPlan.name  | translate"
      billing-detail-item></app-actionable-item>
    <app-actionable-item [label]="'billing.usersInCompany' | translate" [content]="cardPlan.usersCount.toString()"
      billing-detail-item></app-actionable-item>
    <!-- Minimum billable users -->
    <app-actionable-item *ngIf="cardPlan.paymentModel.minBillableQuantity > 1"
      [content]="cardPlan.paymentModel.minBillableQuantity.toString()" billing-detail-item>
      <div class="tooltip-container" label-info>
        <span>
          {{ 'billing.minBillableUsers' | translate }}
        </span>
        <app-info [tooltip]="'billing.minBillableUsersTooltip' | translate"></app-info>
      </div>
    </app-actionable-item>
    <app-actionable-item [label]="'billing.price' | translate" billing-detail-item>
      <span style="text-transform: lowercase" content>
        {{ cardPlan.paymentModel.priceMonthly | currency: '$' }}
        /{{ 'common.user' | translate }}/{{ 'billing.month' | translate }}
      </span>
    </app-actionable-item>
    <app-actionable-item *ngIf="cardPlan.paymentModel.paymentRecurrence === 'year'"
      [label]="'billing.totalAnnualBill' | translate" billing-detail-item>
      <span style="text-transform: lowercase" content>{{ cardPlan.paymentModel.totalBillingPrice | currency: '$'
        }}</span>
    </app-actionable-item>
    <app-actionable-item [label]="'billing.annualBillingDiscount' | translate" billing-detail-item>
      <div class="toggle-container" content>
        {{ cardPlan.paymentModel.annualPayDiscount * -1 | currency: '$' }}
        <mat-slide-toggle *ngIf="!hasFixedRecurrence" color="primary" (change)="toggleChanges($event)"
          [checked]="cardPlan.paymentModel.paymentRecurrence === 'year'"></mat-slide-toggle>
      </div>
    </app-actionable-item>

    <!-- Discount -->
    <app-actionable-item *ngIf="cardPlan.paymentModel.discount" [label]="'billing.disc' | translate"
      billing-detail-item>
      <span style="text-transform: lowercase" content>
        {{ cardPlan.paymentModel.discount.value * -1 | currency: '$' }}
        ({{ cardPlan.paymentModel.discount.percentage }}% {{ 'billing.disc' | translate }})
      </span>
    </app-actionable-item>

    <app-actionable-item [label]="'billing.nextPaymentAmount' | translate" billing-detail-item>
      <span content class="next-payment-amount">
        <b>
          {{ cardPlan.paymentModel.nextPaymentAmountCalculated | currency: '$' }}
        </b>
        <em *ngIf="cardPlan.paymentModel.hasAddons" class="addon-fee-label">
          {{ 'billing.includeAddons' | translate }}
        </em>
      </span>
    </app-actionable-item>
    <app-add-credit-card-item billing-detail-item [plan]="cardPlan"></app-add-credit-card-item>
    <app-actionable-item [label]="'billing.nextPaymentDate' | translate"
      [content]="cardPlan.paymentModel.nextPaymentDate | date" billing-detail-item>
    </app-actionable-item>
  </app-billing-details>
</div>

<div *ngIf="errorMsg" class="error-wrapper" data-testid="error-msg-block">
  <mat-icon>cancel</mat-icon>
  <span class="typography-caption">{{ errorMsg | translate }}</span>
</div>

<div class="btn-container">
  <button mat-stroked-button (click)="handleCancel()" class="cancel-button" aria-label="cancel button">
    {{ 'common.cancel' | translate | uppercase }}
  </button>
  <button *ngIf="cardPlan.paymentModel.paymentRecurrence === 'year'" mat-raised-button color="primary"
    (click)="handleSwitchPlan()" class="upgrade-plan-button">
    <ng-container *ngIf="!isLoadingAction; else loading">{{ 'billing.switchToAnnual' | translate | uppercase
      }}</ng-container>
  </button>
  <button *ngIf="cardPlan.paymentModel.paymentRecurrence === 'month'" mat-raised-button color="primary"
    (click)="handleSwitchPlan()" class="upgrade-plan-button">
    <ng-container *ngIf="!isLoadingAction; else loading">{{ 'billing.changePlan' | translate | uppercase
      }}</ng-container>
  </button>
</div>

<ng-template #loading>
  <mat-spinner class="btn-loader" diameter="24"></mat-spinner>
</ng-template>
