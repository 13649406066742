import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent {
  @Input() icon = 'block';
  @Input() svgIcon: string;
  @Input() @HostBinding('class') color = 'empty';

  @Input() size = 96;
  @Input() iconSize = 50;
}
