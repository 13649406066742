<div class="starter-sidebar" fxLayout="column" fxLayoutAlign="start stretch" [class.collapsed]="collapsed"
  [class.expanded]="!collapsed"
     *ngIf="(user$ | async) && canShowSidebar && steps && (canShowTrialWidget || !allCompleted)"
     (touchstart)="onSwipe($event, 'start')" (touchend)="onSwipe($event, 'end')">
  <div class="starter-sidebar-top" fxLayout="column" fxLayoutAlign="start stretch">
    <app-trial-widget *ngIf="canShowTrialWidget | async"[isCollapsed]="collapsed">
    </app-trial-widget>
    <ng-container *ngIf="isOwner && !allCompleted && steps ">
      <div class="progress" fxLayout="column" fxLayoutAlign="center stretch">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="progress-label">
            {{ 'sidebar.header' | translate }}
          </div>
          <div class="progress-text">
            {{ progress }}
          </div>
        </div>
        <div class="progress-bar">
          <div class="progress-bar-fill" [style.width]="progress"></div>
        </div>
      </div>

      <div *ngFor="let step of steps" class="step" fxLayout="row" fxLayoutAlign="center center"
        [class.completed]="step.completed" [matTooltip]="('sidebar.title.' + step.id) | translate"
        [matTooltipDisabled]="!collapsed" matTooltipPosition="right">

        <div class="step-icon">
          <mat-icon *ngIf="step.completed">check_box</mat-icon>
          <mat-icon *ngIf="step.completed === false"
            (click)="dismissStep(step)"
            [class.clickable-icon]="!step.preventDismiss">
            check_box_outline_blank
          </mat-icon>

          <a mat-icon-button
            *ngIf="!step.completed"
            [routerLink]="step.link || ''"
            [skipNavigation]="!step.link"
            (click)="clickStep(step)"
            id="link-{{ step.id }}">
            <mat-progress-spinner *ngIf="step.completed === undefined" mode="indeterminate" diameter="18">
            </mat-progress-spinner>
          </a>
        </div>

        <span class="step-text">
          <a [routerLink]="getStepRouterLink(step)"
            [skipNavigation]="!step.link"
            (click)="clickStep(step)"
            [class.noHover]="!step.link"
            [ngClass]="{
              'clickable-credit-card': isAddCardExperiment &&
                                     step.id === 'addCreditCard' &&
                                     !step.completed
            }">
            {{ 'sidebar.title.' + step.id | translate }}
            {{ !step.completed && step.action ? '.' : '' }}
          </a>

          <span *ngIf="!step.completed && step.action" (click)="step.onActionClick()"
            [class.has-action]="!!step.onActionClick">
            {{ step.action }}
          </span>
        </span>
      </div>
    </ng-container>

    <div class="collapse-section-container">
      <div fxLayout="row" *ngIf="!isReporMenuItem; else reportMenu" fxLayoutAlign="space-between left" class="expand-row">
        <button mat-icon-button style="min-width: 36px;" (click)="toggleSidebar()">
          <mat-icon>{{collapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}}</mat-icon>
        </button>
        <div style="flex: 1 1;"></div>
      </div>

      <ng-template #reportMenu>
        <div fxLayout="row" fxLayoutAlign="space-between left" class="expand-row-reports">
          <button mat-icon-button style="min-width: 36px;" (click)="toggleSidebar()">
            <mat-icon>{{collapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}}</mat-icon>
          </button>
          <div style="flex: 1 1;"></div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
