import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PricePeriodicity } from 'src/app/pages/new-billing/types/plan.types';
import { provideTrackingCtx } from 'src/app/services/tracking/context';
import { ConcretePlan } from '../models/plan.model';

export type UpgradeBillingData = {
  currentPlan: ConcretePlan;
  onSwitchPlan: (cardPlan: ConcretePlan) => void;
  paymentRecurrence: PricePeriodicity;
  hasFixedRecurrence?: boolean;
};

export type UpgradeBillingResult = {
  didChangePlan: boolean;
  action?: () => void;
};


@UntilDestroy()
@Component({
  selector: 'app-upgrade-billing-details',
  templateUrl: './upgrade-billing-details.component.html',
  styleUrls: ['./upgrade-billing-details.component.scss'],
  providers: [
    provideTrackingCtx({ location: 'billing details' }),
  ],
})
export class UpgradeBillingDetailsComponent {
  cardPlan: ConcretePlan;
  hasFixedRecurrence: boolean;
  isLoadingAction = false;
  errorMsg = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UpgradeBillingData,
    private dialogRef: MatDialogRef<UpgradeBillingDetailsComponent, UpgradeBillingResult>,
  ) {
    const { currentPlan, paymentRecurrence, hasFixedRecurrence } = data;
    this.cardPlan = currentPlan;
    this.hasFixedRecurrence = hasFixedRecurrence;
    this.cardPlan.switchPlanRecurrence(paymentRecurrence);
  }

  public handleCancel() {
    this.dialogRef.close({ didChangePlan: false });
  }

  public async handleSwitchPlan() {
    this.data.onSwitchPlan(this.cardPlan);
  }

  public toggleChanges(billingDiscountEvent: MatSlideToggleChange) {
    const payRecurrence = billingDiscountEvent.checked ? 'year' : 'month';
    this.cardPlan.switchPlanRecurrence(payRecurrence);
  }
}
