import { CheckoutEventsPaymentMethodCardTypes } from '@paddle/paddle-js';
import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { PaymentSummaryComponent } from 'src/app/components/new-billing/payment-summary/payment-summary.component';
import { PlanCardClickEvent } from 'src/app/components/new-billing/plan-card/plan-card.component';
import { BillingError } from 'src/app/pages/new-billing/models/billing-error';
import { ConcretePlan } from 'src/app/pages/new-billing/models/plan.model';
import {
  DialogOptions,
  UpgradeDowngradeBaseModel
} from 'src/app/pages/new-billing/models/upgrade-downgrade-model/upgrade-downgrade-base.model';
import {
  UpgradeBillingResult
} from 'src/app/pages/new-billing/upgrade-billing-details/upgrade-billing-details.component';
import { PaymentSummaryInfo, PaymentSummaryOptions } from 'src/app/pages/new-subscribe-plans/store/model';

export class UpgradeDowngradeOnPaidModel extends UpgradeDowngradeBaseModel {
  private cardLast4Subject$ = new Subject<{ cardLast4: string }>();

  public handleError(err: unknown, cardPlan: ConcretePlan): void {
    if (err instanceof BillingError && err.errorCode === 'subscription_payment_declined') {
      this.handlePaymentDeclinedError(cardPlan);
      return;
    }

    this.dialogRef.componentInstance.errorMsg = 'billing.switchPlan.errorSwitching';
  }

  public openDialog(cardPlan: ConcretePlan, { actionType, paymentRecurrence }: DialogOptions) {
    if (paymentRecurrence) cardPlan.switchPlanRecurrence(paymentRecurrence);

    const paymentSummaryOptions = this.getPaymentSummaryOptions(cardPlan, actionType);

    this.dialogRef = this.matDialog.open<PaymentSummaryComponent, PaymentSummaryInfo, UpgradeBillingResult>(
      PaymentSummaryComponent,
      {
        data: {
          cardInfo: {
            cardLastFour: cardPlan.paymentModel.cardLast4,
            cardBrand: CheckoutEventsPaymentMethodCardTypes.UNKNOWN,
          },
          planInfo: {
            name: cardPlan.name,
            unitPrice: cardPlan.paymentModel.nextPaymentPerUser,
            recurrence: cardPlan.paymentModel.paymentRecurrence,
          },
          hasAddons: cardPlan.paymentModel.hasAddons,
          nextPaymentDate: actionType === 'upgrade' ? null : cardPlan.paymentModel.nextPaymentDate,
          totalAmount: cardPlan.paymentModel.nextPaymentAmountCalculated,
          billableUsers: cardPlan.paymentModel.billableUsers,
          onCardUpdate: this.cardLast4Subject$.asObservable(),
          ...paymentSummaryOptions,
        },
        autoFocus: false,
        panelClass: 'payment-summary-dialog',
        disableClose: true,
      },
    );

    return this.dialogRef;
  }

  private getPaymentSummaryOptions(
    cardPlan: ConcretePlan,
    actionType: PlanCardClickEvent['actionType'],
  ): PaymentSummaryOptions {
    const withLessThan30DaySub = cardPlan.subscription.hasLessThan30DaysSub
      && actionType === 'downgrade';
    const showDiscountRemovalNote = this.growthBookService.getFeatureValue('billing:discount_removal_companies', false);
    const footerText = showDiscountRemovalNote
      ? `${this.translateService.instant('billing.paymentSummary.footerText', {
        termsUrl: 'https://www.timedoctor.com/terms-of-service',
      })} <br> <br> ${this.translateService.instant('billing.discountRemovalNote')}`
      : `${this.translateService.instant('billing.paymentSummary.footerText', {
        termsUrl: 'https://www.timedoctor.com/terms-of-service',
      })}`;
    return {
      title: `billing.paymentSummary.${actionType}.confirmTitle`,
      description: `${withLessThan30DaySub
        ? 'billing.paymentSummary.downgrade.messageUserForMore30Days'
        : `billing.paymentSummary.${actionType}.confirmMessage`}`,
      type: actionType === 'downgrade' ? 'warning' : 'success',
      paymentSummaryText: `billing.paymentSummary.${actionType}.paymentSummaryTxt`,
      buttons: {
        action: {
          text: `billing.paymentSummary.${actionType}.confirmBtnTxt`,
          onClick: () => this.handleSwitchPlan(cardPlan, actionType),
        },
        dismiss: {
          text: 'common.cancel',
          onClick: () => this.dialogRef.close({ didChangePlan: false }),
        },
      },
      footerText,
    };
  }

  private handlePaymentDeclinedError(cardPlan: ConcretePlan) {
    const checkoutDialogRef = this.billingActions.openCheckoutDialog({
      currentPlan: cardPlan,
      isUpdatingCard: true,
      paymentFailureSummaryData: {
        cardInfo: null,
        planInfo: {
          name: cardPlan.name,
          unitPrice: cardPlan.paymentModel.nextPaymentPerUser,
          recurrence: cardPlan.paymentModel.paymentRecurrence,
        },
        hasAddons: cardPlan.paymentModel.hasAddons,
        nextPaymentDate: null,
        billableUsers: cardPlan.paymentModel.billableUsers,
        totalAmount: cardPlan.paymentModel.nextPaymentAmountCalculated,
        ...this.getPaymentFailureSummaryOptions(),
      },
      withOverlayLoader: false,
      trackingCtx: {},
    });

    checkoutDialogRef.afterClosed().pipe(
      take(1),
      filter(result => !!result?.billingDetails),
    ).subscribe(({ billingDetails }) => {
      this.cardLast4Subject$.next({ cardLast4: billingDetails.cardLastFourDigits });
    });
  }

  private getPaymentFailureSummaryOptions(): PaymentSummaryOptions {
    return {
      title: 'billing.paymentSummary.failure.confirmTitle',
      description: 'billing.paymentSummary.failure.confirmMessage',
      type: 'warning',
      paymentSummaryText: 'billing.paymentSummary.failure.paymentSummaryTxt',
      footerText: this.translateService.instant('billing.paymentSummary.failure.footerText', {
        termsUrl: 'https://www.timedoctor.com/terms-of-service',
      }),
    };
  }
}
