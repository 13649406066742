import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { filter, tap } from 'rxjs';
import { take } from 'rxjs/operators';
import { PlanCardClickEvent } from 'src/app/components/new-billing/plan-card/plan-card.component';
import { ConcretePlan } from 'src/app/pages/new-billing/models/plan.model';
import {
  UpgradeDowngradeBaseModel
} from 'src/app/pages/new-billing/models/upgrade-downgrade-model/upgrade-downgrade-base.model';
import {
  UpgradeDowngradeOnPaidModel
} from 'src/app/pages/new-billing/models/upgrade-downgrade-model/upgrade-downgrade-on-paid.model';
import {
  UpgradeDowngradeOnTrialModel
} from 'src/app/pages/new-billing/models/upgrade-downgrade-model/upgrade-downgrade-on-trial.model';
import { PricePeriodicity } from 'src/app/pages/new-billing/types/plan.types';
import {
  UpgradeBillingResult
} from 'src/app/pages/new-billing/upgrade-billing-details/upgrade-billing-details.component';
import { BillingActionsService } from 'src/app/services/billing/billing-actions.service';

@Component({
  selector: 'app-upgrade-downgrade-plan',
  templateUrl: './upgrade-downgrade-plan.component.html',
})
export class UpgradeDowngradePlanComponent {
  @Input()
  set currentPlan(plan: ConcretePlan) {
    this._currentPlan = plan;
    this.upgradeDowngradeModel = plan.subscription.status === 'trial'
      ? new UpgradeDowngradeOnTrialModel(this.injector)
      : new UpgradeDowngradeOnPaidModel(this.injector);
  }

  get currentPlan(): ConcretePlan { return this._currentPlan; }

  @Output()
  planChanged = new EventEmitter<void>();

  cardPlan: ConcretePlan;
  payRecurrence: PricePeriodicity = 'year';

  private _currentPlan: ConcretePlan;
  private upgradeDowngradeModel: UpgradeDowngradeBaseModel;

  constructor(
    private billingActions: BillingActionsService,
    private translateService: TranslateService,
    private injector: Injector,
  ) { }

  public handlePlanChange({ plan, actionType }: PlanCardClickEvent) {
    const dialogRef = this.upgradeDowngradeModel.openDialog(plan, {
      actionType,
    });
    this.setDialogAfterClosed(dialogRef);
  }

  private setDialogAfterClosed(dialogRef: MatDialogRef<unknown, UpgradeBillingResult>) {
    dialogRef.afterClosed()
      .pipe(
        take(1),
        filter((data) => data?.didChangePlan),
        tap(() => this.planChanged.emit()),
      )
      .subscribe(() => {
        this.billingActions.openSnackbar({
          message: this.translateService.instant('billing.planChanged'),
        });
      });
  }
}
