import { ConcretePlan } from 'src/app/pages/new-billing/models/plan.model';
import {
  DialogOptions,
  UpgradeDowngradeBaseModel
} from 'src/app/pages/new-billing/models/upgrade-downgrade-model/upgrade-downgrade-base.model';
import {
  UpgradeBillingData,
  UpgradeBillingDetailsComponent,
  UpgradeBillingResult
} from 'src/app/pages/new-billing/upgrade-billing-details/upgrade-billing-details.component';


export class UpgradeDowngradeOnTrialModel extends UpgradeDowngradeBaseModel {
  public isLoading: boolean;

  public handleError(): void {
    this.dialogRef.componentInstance.errorMsg = 'billing.switchPlan.errorSwitching';
  }

  public openDialog(cardPlan: ConcretePlan, { hasFixedRecurrence, paymentRecurrence, actionType }: DialogOptions) {
    this.dialogRef = this.matDialog.open<UpgradeBillingDetailsComponent, UpgradeBillingData, UpgradeBillingResult>(
      UpgradeBillingDetailsComponent,
      {
        data: {
          currentPlan: cardPlan,
          hasFixedRecurrence,
          paymentRecurrence: paymentRecurrence ?? cardPlan.paymentModel.paymentRecurrence,
          onSwitchPlan: (dialogCardPlan: ConcretePlan) => this.handleSwitchPlan(dialogCardPlan, actionType),
        },
        autoFocus: false,
      },
    );

    return this.dialogRef;
  }

}
