import { StripeElementsOptionsClientSecret } from '@stripe/stripe-js';
import {
  BasePricingPlan, NewBasePricingPlan, PricingPlan,
  PricingPlanFeatureType,
  PricingPlanLevel, PricingPlanType, PricingTierLimit,
  TierPricingPlan
} from './billing-types';

export const stripeElementsOptions: StripeElementsOptionsClientSecret = {
  fonts: [{
    cssSrc: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500',
  }],
};

export const stripeElementClasses = {
  focus: 'focused',
  empty: 'empty',
  invalid: 'invalid',
};

export const stripeElementStyles = {
  base: {
    color: '#2C2E3C',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#a4a7b2',
      fontSize: '12px',
    },
  },
};

export const perUserPerMonth: Record<BasePricingPlan, number> = {
  basic_plan: 7,
  basic_plan_annual: 70,
  standard_plan: 10,
  standard_plan_annual: 100,
  premium_plan: 20,
  premium_plan_annual: 200,
};

export const tierPricing: Record<TierPricingPlan, Record<PricingTierLimit, number>> = {
  basic_new: { 1: 7, 5: 35, 10: 70, 15: 105, 20: 140, 30: 210, 40: 280, 50: 350 },
  standard_new: { 1: 10, 5: 49, 10: 98, 15: 147, 20: 196, 30: 294, 40: 392, 50: 490 },
  premium: { 1: 20, 5: 99, 10: 198, 15: 297, 20: 396, 30: 594, 40: 792, 50: 990 },
  basic_new_annual: { 1: 70, 5: 350, 10: 700, 15: 1050, 20: 1400, 30: 2100, 40: 2800, 50: 3500 },
  standard_new_annual: { 1: 100, 5: 490, 10: 980, 15: 1470, 20: 1960, 30: 2940, 40: 3920, 50: 4900 },
  premium_annual: { 1: 200, 5: 990, 10: 1980, 15: 2970, 20: 3960, 30: 5940, 40: 7920, 50: 9900 },
  business: { 1: 12, 5: 49, 10: 99, 15: 149, 20: 199, 30: 299, 40: 399, 50: 499 },
  enterprise: { 1: 24, 5: 99, 10: 199, 15: 299, 20: 399, 30: 599, 40: 799, 50: 999 },
  business_annual: { 1: 120, 5: 490, 10: 990, 15: 1490, 20: 1990, 30: 2990, 40: 3990, 50: 4990 },
  enterprise_annual: { 1: 240, 5: 990, 10: 1990, 15: 2990, 20: 3990, 30: 5990, 40: 7990, 50: 9990 },
  premium_july20: { 1: 24, 5: 119, 10: 238, 15: 357, 20: 476, 30: 714, 40: 953, 50: 1190 },
  premium_july20_annual: { 1: 240, 5: 1190, 10: 2380, 15: 3570, 20: 4760, 30: 7140, 40: 9530, 50: 11900 },
};

export const pricingPlanTypes: Record<PricingPlanType, PricingPlan[]> = {
  defaultPricingType: ['standard_plan'],
  pricingTypeA: ['business', 'enterprise', 'business_annual', 'enterprise_annual'],
  pricingTypeB: [
    'basic_new', 'standard_new', 'premium',
    'basic_new_annual', 'standard_new_annual', 'premium_annual',
  ],
  pricingTypeC: ['basic_july20', 'standard_july20', 'premium_july20'],
  pricingTypeD: [
    'basic_plan', 'standard_plan', 'premium_plan',
    'basic_plan_annual', 'standard_plan_annual', 'premium_plan_annual',
  ],
};

export const nextPlan: Record<Exclude<PricingPlanLevel, 'enterprise'>, PricingPlanLevel> = {
  basic_plan: 'standard_plan',
  standard_plan: 'premium_plan',
  premium_plan: 'enterprise',
  basic_new: 'standard_new',
  standard_new: 'premium',
  premium: 'enterprise',
  business: 'enterprise',
};

export const prevPlan: Record<PricingPlan, PricingPlan> = {
  basic_plan: 'basic_plan',
  standard_plan: 'basic_plan',
  premium_plan: 'standard_plan',
  basic_new: 'basic_new',
  standard_new: 'basic_new',
  premium: 'basic_plan',
  enterprise: 'business',
  business: 'business',
  basic: 'basic',
  basic_annual: 'basic_annual',
  basic_plan_annual: 'basic_plan_annual',
  basic_new_annual: 'basic_new_annual',
  basic_july20: 'basic_july20',
  premium_annual: 'standard_plan_annual',
  premium_plan_annual: 'standard_plan_annual',
  premium_july20: 'standard_july20',
  premium_july20_annual: 'standard_july20',
  standard_new_annual: 'basic_plan_annual',
  standard_plan_annual: 'basic_plan_annual',
  standard_july20: 'basic_july20',
  business_annual: 'business_annual',
  enterprise_annual: 'business_annual',
  '10peruserpermonth': 'basic_plan',
  resellers: 'enterprise',
  premium_plan_quarterly: 'standard_plan',
  standard_quarterly: 'basic_plan',
  premium_plan_semi_annual: 'standard_plan',
};

export const pricingPlanFeaturesType: Record<PricingPlan, PricingPlanFeatureType> = {
  basic_plan: 'basic',
  standard_plan: 'standard',
  premium_plan: 'premium',
  basic_new: 'basic',
  standard_new: 'standard',
  premium: 'premium',
  enterprise: 'enterprise',
  business: 'business',
  basic: 'basic',
  basic_annual: 'basic',
  basic_plan_annual: 'basic',
  basic_new_annual: 'basic',
  basic_july20: 'basic',
  premium_annual: 'premium',
  premium_plan_annual: 'premium',
  premium_july20: 'premium',
  premium_july20_annual: 'premium',
  standard_new_annual: 'standard',
  standard_plan_annual: 'standard',
  standard_july20: 'standard',
  business_annual: 'business',
  enterprise_annual: 'enterprise',
  '10peruserpermonth': 'standard',
  resellers: 'enterprise',
  premium_plan_quarterly: 'premium',
  standard_quarterly: 'standard',
  premium_plan_semi_annual: 'premium',
};

export const newPlanFeaturesMap: Record<PricingPlanFeatureType, NewBasePricingPlan> = {
  basic: 'basic_monthly',
  standard: 'standard_monthly',
  premium: 'premium_monthly',
  business: 'standard_monthly',
  enterprise: 'premium_monthly',
};
