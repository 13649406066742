import { ApplicationRef, ComponentRef, createComponent, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import lottie, { AnimationItem } from 'lottie-web';
import { StepCompletionNotificationComponent } from '../../components/step-completion-notification/step-completion-notification.component';

const ANIMATION_CONSTANTS = {
  CONFETTI_DELAY: 100,
  FADE_OUT_DURATION: 300,
  NOTIFICATION_DURATION: 7000,
} as const;

const ANIMATION_DELAYS = {
  addEmployee: 3000,
  employeeTrack: 2000,
} as const;

@Injectable({
  providedIn: 'root',
})
export class AnimationService {
  private confettiAnimation: AnimationItem | null = null;
  private notificationComponentRef: ComponentRef<StepCompletionNotificationComponent> | null = null;

  constructor(
    private readonly translateService: TranslateService,
    private readonly appRef: ApplicationRef,
    private readonly snackBar: MatSnackBar,
  ) {}

  playConfettiAnimation(): void {
    if (this.confettiAnimation) {
      return;
    }

    const confettiContainer = this.createConfettiContainer();
    this.confettiAnimation = this.initializeConfettiAnimation(confettiContainer);
    this.setupConfettiCleanup(confettiContainer);
  }

  celebrateCompletedStep(stepId?: string, isGettingStartedExperiment?: boolean): void {
    this.cleanupExistingCelebration();

    const componentRef = this.createNotificationComponent(stepId, isGettingStartedExperiment);
    const notificationDelay = ANIMATION_DELAYS[stepId] || 0;

    this.scheduleNotificationDisplay(componentRef, notificationDelay);
    this.scheduleCleanup(componentRef);
  }

  private createConfettiContainer(): HTMLDivElement {
    const container = document.createElement('div');
    container.classList.add('confetti-container');
    document.body.appendChild(container);
    return container;
  }

  private initializeConfettiAnimation(container: HTMLDivElement): AnimationItem {
    return lottie.loadAnimation({
      container,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: 'assets/animations/confetti.json',
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    });
  }

  private setupConfettiCleanup(container: HTMLDivElement): void {
    this.confettiAnimation?.addEventListener('complete', () => {
      container.remove();
      this.confettiAnimation = null;
    });
  }

  private cleanupExistingCelebration(): void {
    if (this.notificationComponentRef) {
      this.cleanupNotification(this.notificationComponentRef);
    }
  }

  private createNotificationComponent(
    stepId?: string,
    isGettingStartedExperiment?: boolean,
  ): ComponentRef<StepCompletionNotificationComponent> {
    const componentRef = createComponent(StepCompletionNotificationComponent, {
      environmentInjector: this.appRef.injector,
    });
    componentRef.instance.stepId = stepId;
    componentRef.instance.isGettingStartedExperiment = isGettingStartedExperiment;
    return componentRef;
  }

  private scheduleNotificationDisplay(
    componentRef: ComponentRef<StepCompletionNotificationComponent>,
    delay: number,
  ): void {
    setTimeout(() => {
      this.snackBar.dismiss();
      document.body.appendChild(componentRef.location.nativeElement);
      this.appRef.attachView(componentRef.hostView);
      this.notificationComponentRef = componentRef;

      setTimeout(() => this.playConfettiAnimation(), ANIMATION_CONSTANTS.CONFETTI_DELAY);
    }, delay);
  }

  private scheduleCleanup(componentRef: ComponentRef<StepCompletionNotificationComponent>): void {
    setTimeout(
      () => this.cleanupNotification(componentRef),
      ANIMATION_CONSTANTS.NOTIFICATION_DURATION,
    );
  }

  private cleanupNotification(componentRef: ComponentRef<StepCompletionNotificationComponent>): void {
    componentRef.location.nativeElement.classList.add('hide');
    setTimeout(() => {
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
      this.notificationComponentRef = null;
    }, ANIMATION_CONSTANTS.FADE_OUT_DURATION);
  }
}
