import {
  BillingApiData,
  DiscountApiData,
  LineItem,
  PlanApiData,
  PlanId,
  SubscriptionStatus
} from 'src/app/pages/new-billing/types/api.types';
import { PricePeriodicity } from 'src/app/pages/new-billing/types/plan.types';
import { monthlyMultiplierByRecurrence, planIdSwitch, pricePeriodByBillingPeriod } from 'src/app/pages/new-billing/utils/plan-maps';


export type Discount = {
  id: string;

  /** Represents the discount code. This will always match the {@link DiscountApiData.discountId} */
  code: string;

  /** Represents the discount value in the currency of the user's account */
  value: number;

  /** Represents the discount value as a percentage of the total price */
  percentage: number;

  /** The number of billing periods (in months) the discount is applied to */
  recurringIntervals: number;

  /** The type of discount */
  type?: 'percentage' | 'amount' | 'amount_per_seat';
};

export type AddOn = PlanApiData & LineItem;

export interface PaymentModel {
  cardLast4: string | null;
  nextPaymentDate: string;
  nextPaymentAmount: number;
  nextPaymentAmountCalculated: number;
  nextPaymentPerUser: number;
  annualPayDiscount: number;
  paymentRecurrence: PricePeriodicity;
  priceMonthly: number;
  pricePerUser: number;
  totalBillingPrice: number;
  lastPaymentError?: string;
  lastPaymentAt?: string;
  tax: number;
  minBillableQuantity: number;
  billableUsers: number;
  discount: Discount | null;
  hasAddons: boolean;

  switchRecurrence(recurrence: PricePeriodicity): void;
}

const PAYMENT_DECIMALS = 100;

const oldPricingPlans = new Set(['standard_monthly', 'standard_yearly', 'basic_monthly', 'basic_yearly']);

export class PaymentModelImp implements PaymentModel {
  private _discount: DiscountApiData | null;
  private readonly _availableDiscounts: DiscountApiData[];
  private readonly _status: SubscriptionStatus;
  private readonly _initialPlanData: {
    plan: string;
    plans: PlanApiData[];
    discount: DiscountApiData | null;
    discountCode: string;
    paymentRecurrence: PricePeriodicity;
  };
  private _planId: PlanId;
  private _paymentRecurrence: PricePeriodicity;

  /** Holds a discount code that is set externally, e.g., from a query parameter.
   * It may or may not match with {@link DiscountApiData.discountId} */
  private _discountCode: string;

  cardLast4: string;
  readonly lastPaymentError: string;
  readonly lastPaymentAt: string;
  readonly priceMonthly: number;
  readonly nextPaymentDate: string;
  readonly nextPaymentAmount: number;
  readonly unitPrice: number;
  readonly tax: number;
  readonly minBillableQuantity: number;
  readonly addOns: AddOn[];
  enableDiscountRemoval: boolean;


  get paymentRecurrence(): PricePeriodicity { return this._paymentRecurrence; }

  set paymentRecurrence(value: PricePeriodicity) {
    this._paymentRecurrence = value;
  }

  get monthlyMultipier(): number {
    return monthlyMultiplierByRecurrence[this.paymentRecurrence];
  }

  get discountedMonthlyMultiplier() {
    const discountedMonths = this.paymentRecurrence === 'year' ? 2 : 0;
    return this.monthlyMultipier - discountedMonths;
  }

  get pricePerUser() {
    return this.priceMonthly * this.discountedMonthlyMultiplier;
  }

  get totalBillingPrice() {
    return this.priceMonthly * this.billableUsers * this.monthlyMultipier;
  }

  get annualPayDiscount() {
    return this.paymentRecurrence === 'year'
      ? this.priceMonthly * this.billableUsers * 2
      : 0;
  }

  get addOnsAmount() {
    const paymentRecurrence = this.paymentRecurrence === 'year' ? 12 : 1;
    return this.addOns.reduce((acc, addOn) => acc + (addOn.unitPrice * addOn.quantity * paymentRecurrence), 0);
  }

  get nextPaymentAmountCalculated() {
    const discount = this.discount?.value || 0;
    const subtotal = this.totalBillingPrice - this.annualPayDiscount - discount + this.addOnsAmount;

    return subtotal < 0 ? 0 : subtotal;
  }

  get nextPaymentPerUser() {
    return this.nextPaymentAmountCalculated / this.billableUsers;
  }

  get billableUsers() {
    if (this.minBillableQuantity > this.usersCount) return this.minBillableQuantity;

    return this.usersCount;
  }

  get discount(): Discount | null {
    if (this.cardLast4) {
      const discountToParse = this._status === 'trial'
        ? this.getDiscountToParse(this.findAppliedDiscount())
        : this._discount;
      return this.parseDiscount(discountToParse);
    }

    const foundDiscount = this.findDiscountByCode();
    return this.parseDiscount(this._discount || foundDiscount);
  }

  get hasAddons() {
    return this.addOns.length > 0;
  }

  constructor({ billingDetails, plans, discounts, discountCode }: BillingApiData,
    private usersCount: number, enableDiscountRemoval: boolean) {
    const currentPlan = plans.find(plan => plan.planId === billingDetails.currentPlanId);

    this._planId = currentPlan.planId;
    this.cardLast4 = billingDetails.cardLastFourDigits || null;
    this.unitPrice = currentPlan.unitPrice;
    this.paymentRecurrence = pricePeriodByBillingPeriod[currentPlan.billingPeriod];
    this.priceMonthly = this.getActivePlan(plans, currentPlan)?.unitPrice || 0;
    this.addOns = billingDetails?.lineItems?.map(addOn => ({
      ...plans.find(plan =>
        plan.planId === addOn.planId,
      ),
      ...addOn,
    }));
    this.lastPaymentError = billingDetails.lastPaymentError || null;
    this.lastPaymentAt = billingDetails.lastPaymentAt || null;
    this.nextPaymentDate = billingDetails.nextPaymentDate;
    this.nextPaymentAmount = billingDetails.nextPaymentAmount / PAYMENT_DECIMALS;
    this.tax = billingDetails.tax / PAYMENT_DECIMALS || 0;
    this.minBillableQuantity = billingDetails.minBillableQuantity || 0;
    this.enableDiscountRemoval = enableDiscountRemoval;
    this._initialPlanData = {
      plan: billingDetails.currentPlan,
      plans,
      discount: billingDetails.discount,
      discountCode: discountCode.toUpperCase(),
      paymentRecurrence: pricePeriodByBillingPeriod[currentPlan.billingPeriod],
    };
    // If the current plan is the same as the one in the billing details, apply the discount
    if (enableDiscountRemoval) {
      if (billingDetails.currentPlan === currentPlan.name) {
        this._discountCode = discountCode.toUpperCase();
        this._discount = billingDetails.discount;
      } else {
        this._discount = null;
        this._discountCode = null;
      }
    } else {
      this._discountCode = discountCode.toUpperCase();
      this._discount = billingDetails.discount;
    }
    this._availableDiscounts = discounts;
    this._status = billingDetails.customerStatus;
  }

  //TODO: Update this function later once active/current property is available already from the back-end service
  getActivePlan(plans: PlanApiData[] | undefined, currentPlan: PlanApiData): PlanApiData | undefined {
    if (!plans || plans.length === 0) return undefined;

    if (oldPricingPlans.has(currentPlan.planId)) {
      return plans.find(plan => plan.productId === currentPlan.productId && plan.billingPeriod === 'monthly');
    }

    // Iterate from the end to find the most recent plan matching the criteria
    for (let i = plans.length - 1; i >= 0; i--) {
      const plan = plans[i];
      if (plan.productId === currentPlan.productId && plan.billingPeriod === 'monthly') {
        return plan;
      }
    }

    return undefined;
  }

  switchRecurrence(recurrence: PricePeriodicity) {
    const currentPlan = this._initialPlanData.plans.find(plan => plan.planId === planIdSwitch[this._planId]);

    // On switching recurrence, the discount is removed if the current plan is different from the initial plan
    if (this.enableDiscountRemoval) {
      if (this._initialPlanData.paymentRecurrence !== recurrence) {
        this._discount = null;
        this._discountCode = null;
      } else if (currentPlan.name === this._initialPlanData.plan) {
        this._discountCode = this._initialPlanData.discountCode;
        this._discount = this._initialPlanData.discount;
      }
    }
    this._planId = planIdSwitch[this._planId];
    this.paymentRecurrence = recurrence;
  }

  parseDiscount(rawDiscount: DiscountApiData): Discount | null {
    if (!rawDiscount) return null;

    const value = rawDiscount.type === 'percentage' ? this.pricePerUser * (rawDiscount.value / 100) : rawDiscount.value;
    const discountPeriod = rawDiscount.period === 'All billing periods' ? 0 : rawDiscount.period;
    const recurringIntervals = discountPeriod * this.monthlyMultipier;

    return {
      id: rawDiscount.externalDiscountId,
      code: rawDiscount.discountId,
      value,
      percentage: rawDiscount.value,
      recurringIntervals,
      type: rawDiscount.type,
    };
  }

  private findAppliedDiscount(): DiscountApiData | undefined {
    return this._availableDiscounts.find(discount => discount.discountId === this._discount?.discountId);
  }

  private findDiscountByCode(): DiscountApiData | undefined {
    return this._availableDiscounts.find(discount => {
      const discountCode = discount.discountId.toUpperCase();
      const isPlanAvailable = !discount.availablePlans || discount.availablePlans.includes(this._planId);

      return isPlanAvailable && discountCode === this._discountCode;
    });
  }

  private getDiscountToParse(discount: DiscountApiData | undefined): DiscountApiData | null {
    return (!discount?.availablePlans || discount.availablePlans.length === 0 || discount.availablePlans.includes(this._planId))
      ? this._discount
      : null;
  }
}
