<div class="header-wrapper">
  <h4 class="typography-h6">{{ plan.nameToDisplay | translate }}</h4>
  <span
    *ngIf="badgeInfo$ | async as badge"
    [class]="badge.className">
    {{ badge.translationKey | translate | capitalize }}
  </span>
</div>

<div class="price-wrapper">
  <span class="typography-h3">${{ priceToDisplay() }}</span>
    <div class="billed-messages-container">
      <span class="recurrence-label">{{ 'common.user' | translate }} / {{ 'billing.month' | translate }}</span>
      <span class="recurrence-label" > {{ recurrenceMessage | translate }}</span>
    </div>
</div>

<ng-container *ngIf="!useChoosePlan && !isAddCardExperiment; else choosePlanBtn">
  <ng-container [ngSwitch]="determinePlanState()">
    <button
      *ngSwitchCase="'downgrade'"
      mat-stroked-button color="primary"
      class="change-plan-button"
      (click)="handlePlanCardBtnClick()"
      [trackClick]="{ page: 'Clicked Downgrade Plan on Billing Details', trialstartdate: subscription?.trialStartDate, trialenddate: subscription?.trialEndDate }">
      {{ 'billing.downgradePlan' | translate }}
    </button>
    <button mat-raised-button disabled class="change-plan-button" *ngSwitchCase="'current'">
      {{ 'billing.currentPlan' | translate }}
    </button>
    <button
      *ngSwitchCase="'upgrade'"
      mat-raised-button color="primary"
      class="change-plan-button"
      (click)="handlePlanCardBtnClick()"
      [trackClick]="{ page: 'Clicked Upgrade Plan on Billing Details', trialstartdate: subscription?.trialStartDate, trialenddate: subscription?.trialEndDate }"
    >
      {{ 'billing.upgradePlan' | translate }}
    </button>
  </ng-container>
</ng-container>

<div class="features-wrapper">
  <ul id="features" class="features-list" [attr.data-expanded]="isListExpanded">
      <li class="typography-body2">
        <b class="test-selector">{{ recurrenceMessageEachPlan | translate }}</b>
      </li>
    <li *ngFor="let feature of plan.featuresSummary" class="typography-body2">
      {{ feature }}
    </li>
  </ul>

  <button
    class="expand-collapse-wrapper" aria-controls="features"
    [attr.data-expanded]="isListExpanded"
    (click)="isListExpanded = !isListExpanded"
  >
    <span>
      {{ (isListExpanded ? 'subscription.seeLess' : 'subscription.seeMore') | translate }}
    </span>
    <mat-icon>keyboard_arrow_up</mat-icon>
  </button>
</div>

<ng-template #choosePlanBtn>
    <button
      [ngClass]="{
        'mat-primary': isCurrentPlanOrNoCard,
        'mat-flat-button': isCurrentPlanOrAddCard,
        'mat-stroked-button': isChoosePlanButton
      }"
      class="change-plan-button"
      (click)="handlePlanCardBtnClick()"
      [trackClick]="choosePlanTrackData()"
      [disabled]="isCurrentPlanWithCard"
      *ngIf="isAddCardExperiment"
    >
      <ng-container [ngSwitch]="buttonState">
        <ng-container *ngSwitchCase="'addCard'">
          {{ 'sidebar.widget.buttonAddCard' | translate | uppercase }}
        </ng-container>
        <ng-container *ngSwitchCase="'nextPayment'">
          {{ 'subscription.nextPayment' | translate | uppercase }} - {{ currentPlan.subscription?.expiry | date:'MM/dd/yyyy' }}
        </ng-container>
        <ng-container *ngSwitchCase="'choosePlan'">
          {{ 'billing.choosePlanBtn' | translate }}
        </ng-container>
      </ng-container>
    </button>
    <button
      *ngIf="!isAddCardExperiment"
      mat-raised-button
      color="primary"
      class="change-plan-button"
      (click)="handlePlanCardBtnClick()"
      [trackClick]="choosePlanTrackData()"
    >
      {{ 'billing.choosePlanBtn' | translate }}
    </button>
</ng-template>
