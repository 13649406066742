import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import type { CheckoutEventsData } from '@paddle/paddle-js';
import { CheckoutComponent } from 'src/app/components/new-billing/checkout/checkout.component';
import { ConcretePlan } from 'src/app/pages/new-billing/models/plan.model';
import { BillingDetail } from 'src/app/pages/new-billing/types/api.types';
import { BillingActionsService } from 'src/app/services/billing/billing-actions.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { ExperimentsService } from 'src/app/services/experiments/experiments.service';
import { TrackingContext } from 'src/app/services/tracking/context';

@UntilDestroy()
@Component({
  selector: 'app-add-credit-card-item',
  templateUrl: './add-credit-card-item.component.html',
  styleUrls: ['./add-credit-card-item.component.scss'],
})
export class AddCreditCardItemComponent implements BillingDetail {
  @Input() label = 'billing.yourCreditCard';

  @Input() plan: ConcretePlan;

  @Input() canUpdateCard: boolean;

  @Input() canUpdatePlan: boolean;

  @Input() socialProof: boolean;

  get isAddCardExperiment() {
    return this.experimentsService.isAddCardExperiment;
  }

  constructor(
    private readonly companyService: CompanyService,
    private readonly billingActions: BillingActionsService,
    private readonly trackingCtx: TrackingContext,
    private readonly experimentsService: ExperimentsService,
  ) {}

  handleAddCreditCardBtnClick() {
    this.billingActions.openCheckoutDialog({
      currentPlan: this.plan,
      socialProof: this.socialProof ?? false,
      checkoutSuccessCallback: (eventData, dialogRef) => this.updateCardCompleted(eventData, dialogRef),
      trackingCtx: this.trackingCtx.context,
    });
  }

  public handleEditCreditCard() {
    this.billingActions.openCheckoutDialog({
      currentPlan: this.plan,
      isUpdatingCard: true,
      socialProof: this.socialProof ?? false,
      checkoutSuccessCallback: (eventData, dialogRef) => this.updateCardCompleted(eventData, dialogRef),
      trackingCtx: this.trackingCtx.context,
    });
  }

  addCreditCardTrackData() {
    return {
      page: 'Add card',
      terms: this.plan.periodicityToDisplay.replace('billing.', ''),
      plan: this.plan.productId,
      price: this.plan.unitPrice,
    };
  }

  private async updateCardCompleted(paddleEventData: CheckoutEventsData, dialogRef: MatDialogRef<CheckoutComponent>) {
    const dialogPlanId = dialogRef.componentInstance.dialogData.planId;
    if (dialogPlanId && dialogPlanId !== this.plan.planId && this.canUpdatePlan) {
      this.companyService.switchCurrentCompanyPlan(dialogRef.componentInstance.dialogData.planId);
    }

    this.billingActions.onCardUpdatedCompleted(paddleEventData, dialogRef);
  }
}
