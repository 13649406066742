import { nextPlan, prevPlan, pricingPlanFeaturesType } from 'src/app/components/billing/billing-helpers';
import { BillingApiData, BillingDetailsApiData, PlanApiData, PlanId } from 'src/app/pages/new-billing/types/api.types';
import { LegacyBasePlan, LegacyPricingPlans, PricePeriodicity } from 'src/app/pages/new-billing/types/plan.types';
import {
  planIdSwitch,
  planIdToPricingPlan, pricingPlanSwitch,
  tierOverPlan,
  tierUnderPlan
} from 'src/app/pages/new-billing/utils/plan-maps';
import { PricingPlan } from 'src/models';
import { PaymentModel, PaymentModelImp } from './payment.model';
import { SubscriptionModel, SubscriptionModelImp } from './subscription.model';

export interface PlanModel {
  name: PricingPlan;
  planId: PlanId;
  unitPrice: number;
  featuresLong: Record<string, unknown>[];
  featuresSummary: string[];
  usersCount: number;
  subscription?: SubscriptionModel;
  futurePlanId: PlanId;
}

export class ConcretePlan implements PlanModel {
  private readonly basicPlanOptions: LegacyPricingPlans[] = ['basic_plan', 'basic_plan_annual'];

  name: LegacyPricingPlans;
  planId: PlanId;

  readonly featuresLong: Record<string, unknown>[];
  readonly featuresSummary: string[];
  readonly usersCount: number;
  readonly productId: PlanApiData['productId'];
  readonly unitPrice: number;
  readonly subscription: SubscriptionModel;
  readonly billingApiData: BillingApiData;
  readonly paymentModel: PaymentModel;
  readonly futurePlanId: PlanId;
  readonly billingMode: BillingDetailsApiData['billingMode'];

  readonly plans: PlanApiData[];
  enableDiscountRemoval: boolean;

  get baseName(): LegacyBasePlan {
    return this.name.replace(/_(annual|semi_annual|quarter)$/, '') as LegacyBasePlan;
  }

  get nameToDisplay(): string {
    return `billing.${this.name}`;
  }

  get pricePerRecurrence(): number {
    const multiplier = this.paymentModel.paymentRecurrence === 'year' ? 12 : 1;
    return Math.floor(this.unitPrice * multiplier);
  }

  get periodicityToDisplay(): string {
    return `billing.${this.paymentModel.paymentRecurrence}`;
  }

  get tierAbovePlan(): string {
    return pricingPlanFeaturesType[nextPlan[this.baseName]];
  }

  get tierAbovePlanFeatures() {
    return this.plans.find(plan => plan.planId === tierOverPlan[this.planId])?.featureSummary ?? [];
  }

  get tierUnderPlan() {
    return pricingPlanFeaturesType[prevPlan[this.baseName]];
  }

  get tierUnderPlanName() {
    return prevPlan[this.baseName];
  }

  get tierUnderPlanFeatures() {
    return this.plans.find(plan => plan.planId === tierUnderPlan[this.planId])?.featureSummary ?? [];
  }

  get willDowngradePlan(): boolean {
    return this.billingApiData.billingDetails.currentPlanId !== this.billingApiData.billingDetails.planId;
  }

  get hasAddons(): boolean {
    return !!this.billingApiData?.billingDetails?.lineItems?.length;
  }

  constructor(billingApiData: BillingApiData, usersCount: number, enableDiscountRemoval) {
    const { billingDetails, plans } = billingApiData;
    const currentPlan = billingApiData.plans.find(plan => plan.planId === billingDetails.currentPlanId);

    this.name = planIdToPricingPlan[billingDetails.currentPlanId];
    this.planId = billingDetails.currentPlanId;
    this.unitPrice = +currentPlan.unitPrice;
    this.featuresLong = currentPlan.feature;
    this.featuresSummary = currentPlan.featureSummary;
    this.usersCount = billingDetails.quantity;
    this.plans = plans;
    this.productId = currentPlan.productId;
    this.futurePlanId = billingDetails.planId;
    this.billingMode = billingDetails.billingMode;
    this.enableDiscountRemoval = enableDiscountRemoval;
    this.subscription = new SubscriptionModelImp(billingDetails);
    this.billingApiData = billingApiData;
    this.paymentModel = new PaymentModelImp(billingApiData, billingDetails.quantity, enableDiscountRemoval);
  }

  public clone(): ConcretePlan {
    return new ConcretePlan(this.billingApiData, this.usersCount, this.enableDiscountRemoval);
  }

  public hasFeaturesInOtherPlan() {
    return !this.basicPlanOptions.includes(this.name);
  }

  public switchPlanRecurrence(recurrence: PricePeriodicity) {
    if (this.paymentModel.paymentRecurrence !== recurrence) {
      this.name = pricingPlanSwitch[this.name];
      this.planId = planIdSwitch[this.planId];
      this.paymentModel.switchRecurrence(recurrence);
    }
  }
}

export class ConcretePlanBasic extends ConcretePlan {
  get nameToDisplay(): string {
    return 'billing.basic';
  }

  constructor(billingApiData: BillingApiData, usersCount: number, enableDiscountRemoval: boolean) {
    // If current plan is legacy basic plan, we need to show the legacy one always
    const currentPlan = (billingApiData.billingDetails?.planId === 'basic_monthly' || billingApiData.billingDetails?.planId === 'basic_yearly')
      ? billingApiData.plans.find(plan => plan.planId === 'basic_monthly')
      : billingApiData.plans.find(plan => plan.planId === 'basic_monthly_01');
    const extendedBillingApiData: BillingApiData = {
      ...billingApiData,
      billingDetails: {
        ...billingApiData.billingDetails,
        ...currentPlan,
        currentPlanId: currentPlan.planId,
      },
    };

    super(extendedBillingApiData, usersCount, enableDiscountRemoval);
  }
}

export class ConcretePlanStandard extends ConcretePlan {
  get nameToDisplay(): string {
    return 'billing.standard';
  }

  constructor(billingApiData: BillingApiData, usersCount: number, enableDiscountRemoval: boolean) {
    // If current plan is legacy standard plan, we need to show the legacy one always
    const currentPlan = (billingApiData.billingDetails?.planId === 'standard_monthly' || billingApiData.billingDetails?.planId === 'standard_yearly')
      ? billingApiData.plans.find(plan => plan.planId === 'standard_monthly')
      : billingApiData.plans.find(plan => plan.planId === 'standard_monthly_01');
    const extendedBillingApiData: BillingApiData = {
      ...billingApiData,
      billingDetails: {
        ...billingApiData.billingDetails,
        ...currentPlan,
        currentPlanId: currentPlan.planId,
      },
    };

    super(extendedBillingApiData, usersCount, enableDiscountRemoval);
  }
}

export class ConcretePlanPremium extends ConcretePlan {
  get nameToDisplay(): string {
    return 'billing.premium';
  }

  constructor(billingApiData: BillingApiData, usersCount: number, enableDiscountRemoval: boolean) {
    const currentPlan = billingApiData.plans.find(plan => plan.planId === 'premium_monthly');
    const extendedBillingApiData: BillingApiData = {
      ...billingApiData,
      billingDetails: {
        ...billingApiData.billingDetails,
        ...currentPlan,
        currentPlanId: currentPlan.planId,
      },
    };

    super(extendedBillingApiData, usersCount, enableDiscountRemoval);
  }
}


