<section class="card-checkout-success">
  <header class="card-header">
    <div class="card-header__check" [attr.data-type]="data.type || 'success'">
      <mat-icon class="check-icon material-icons-outlined">{{ iconName }}</mat-icon>
    </div>
    <div class="card-header__content">
      <h2 class="typography-h5">{{ data.title | translate }}</h2>
      <p class="subtitle">{{ data.description | translate }}</p>
      <div *ngIf="buttons?.action || buttons?.dismiss" fxLayout class="buttons-container">
        <button *ngIf="buttons?.action as actionBtn" mat-raised-button color="primary" class="typography-button2"
          (click)="actionBtn.onClick()" [trackClick]="actionBtn.trackingCtx">
          <ng-container *ngIf="!isLoadingAction; else loading">
            {{ actionBtn.text | translate }}
          </ng-container>
        </button>
        <button *ngIf="buttons?.dismiss as dismissBtn" mat-stroked-button class="typography-button2"
          (click)="dismissBtn.onClick()" [trackClick]="{ page: 'Clicked Cancel' }">
          {{ dismissBtn.text | translate }}
        </button>
      </div>

      <p *ngIf="errorMsg" class="card-header__content__error-msg">{{ errorMsg | translate }}</p>
    </div>
  </header>

  <div class="card-content">
    <div class="payment-summary">
      <h3 class="typography-subtitle1 bold">{{ data.paymentSummaryText | translate }}</h3>
      <div class="payment-summary__item user-price">
        <span>{{ ('billing.paymentSummary.newPlan' | translate) + ': ' + planSelected }}</span>
        <span class="right">{{ 'common.#users' | translate: { count: data.billableUsers } | lowercase }},<br
            ngClass.gt-xs="hidden">
          {{ planInfo.unitPrice | currency: '$' }}
          {{ 'common.user' | translate | lowercase }}/{{ 'billing.' + planInfo.recurrence | translate }}</span>
      </div>
      <div class="payment-summary__item">
        <span>{{ 'subscription.totalAmount' | translate }}</span>
        <span class="total-amount">
          <b>{{ data.totalAmount | currency }}</b>
          <em *ngIf="data.hasAddons" class="addon-fee-label">{{ 'billing.includeAddons' | translate }}</em>
        </span>
      </div>
    </div>

    <div class="payment-details">
      <div class="payment-details__item">
        <h4 class="typography-subtitle2">
          {{ titleDate | translate }}
        </h4>
        <span class="typography-subtitle1">
          {{ paymentDate | date }}
        </span>
      </div>
      <div class="payment-details__item" data-testid="card-info-wrapper">
        <h4 class="typography-subtitle2">{{ paymentMethodTextKey | translate }}</h4>
        <div *ngIf="cardInfo" class="card-info-wrapper">
          <mat-icon [svgIcon]="'cards:' + (cardInfo.cardBrand)"></mat-icon>
          <span class="typography-subtitle1">*** *** *** {{ cardInfo.cardLastFour }}</span>
        </div>
      </div>
    </div>
  </div>
  <footer class="card-footer" [innerHTML]="data.footerText | translate"></footer>
</section>

<ng-template #loading>
  <mat-spinner class="spinner-white" diameter="20"></mat-spinner>
</ng-template>
