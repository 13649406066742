import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import {
  ConcretePlan,
  ConcretePlanBasic,
  ConcretePlanPremium,
  ConcretePlanStandard
} from 'src/app/pages/new-billing/models/plan.model';
import { PricePeriodicity } from 'src/app/pages/new-billing/types/plan.types';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { PlanCardClickEvent } from '../plan-card/plan-card.component';

type CardPlan = {
  plan: ConcretePlan;
  isPopular?: boolean;
};

@Component({
  selector: 'app-choose-plan-generic',
  templateUrl: './choose-plan-generic.component.html',
  styleUrls: ['./choose-plan-generic.component.scss'],
})
export class ChoosePlanGenericComponent {
  @Input()
  get currentPlan(): ConcretePlan { return this._currentPlan; }
  set currentPlan(value: ConcretePlan) {
    this._currentPlan = value;
    this.plans = [
      { plan: new ConcretePlanBasic(this._currentPlan.billingApiData, this._currentPlan.usersCount, this.growthBookService.getFeatureValue('billing:discount_removal_companies', false)) },
      { plan: new ConcretePlanStandard(this._currentPlan.billingApiData, this._currentPlan.usersCount, this.growthBookService.getFeatureValue('billing:discount_removal_companies', false)) },
      { plan: new ConcretePlanPremium(this._currentPlan.billingApiData, this._currentPlan.usersCount, this.growthBookService.getFeatureValue('billing:discount_removal_companies', false)), isPopular: true },
    ];
  }

  @Input()
  payRecurrence: PricePeriodicity = 'year';

  @Output()
  planCardBtnClick = new EventEmitter<PlanCardClickEvent>();

  @Output()
  payRecurrenceChange = new EventEmitter<PricePeriodicity>();

  private _currentPlan: ConcretePlan;

  plans: CardPlan[] = [];

  constructor(private growthBookService: GrowthBookService) { }

  handlePayRecurrenceChange(event: MatRadioChange) {
    this.payRecurrenceChange.emit(event.value);
    this.payRecurrence = event.value;
  }
}
