import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ActionableItemComponent,
  AddCreditCardItemComponent,
  BillingDetailBaseItemComponent
} from 'src/app/components/new-billing/billing-detail-items';
import { BillingDetailsComponent } from 'src/app/components/new-billing/billing-details/billing-details.component';
import {
  ChoosePlanGenericComponent
} from 'src/app/components/new-billing/choose-plan-generic/choose-plan-generic.component';
import { ChoosePlanComponent } from 'src/app/components/new-billing/choose-plan/choose-plan.component';
import { PaymentSummaryComponent } from 'src/app/components/new-billing/payment-summary/payment-summary.component';
import {
  UpcomingInvoicePreviewComponent
} from 'src/app/components/new-billing/upcoming-invoice-preview/upcoming-invoice-preview.component';
import {
  UpgradeDowngradePlanComponent
} from 'src/app/components/new-billing/upgrade-downgrade-plan/upgrade-downgrade-plan.component';
import { SharedModule } from 'src/app/modules/shared.module';
import { CheckoutComponent } from './checkout/checkout.component';
import { PlanCardComponent } from './plan-card/plan-card.component';
import {
  BillingLogosSectionComponent
} from 'src/app/components/new-billing/billing-logos-section/billing-logos-section.component';
import { NgOptimizedImage } from '@angular/common';

const components = [
  ActionableItemComponent,
  AddCreditCardItemComponent,
  BillingDetailBaseItemComponent,
  BillingDetailsComponent,
  ChoosePlanGenericComponent,
  PlanCardComponent,
  CheckoutComponent,
  ChoosePlanComponent,
  UpgradeDowngradePlanComponent,
  PaymentSummaryComponent,
  UpcomingInvoicePreviewComponent,
  BillingLogosSectionComponent,
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    MatRadioModule,
    SharedModule,
    MatSlideToggleModule,
    MatTableModule,
    NgOptimizedImage,
  ],
  exports: [
    ...components,
  ],
})
export class NewBillingComponentsModule {
  constructor(
    matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    matIconRegistry
      .addSvgIconInNamespace('cards', 'american_express', this.setIcon('amex.svg'))
      .addSvgIconInNamespace('cards', 'diners_club', this.setIcon('diners.svg'))
      .addSvgIconInNamespace('cards', 'discover', this.setIcon('discover.svg'))
      .addSvgIconInNamespace('cards', 'jcb', this.setIcon('jcb.svg'))
      .addSvgIconInNamespace('cards', 'mastercard', this.setIcon('mastercard.svg'))
      .addSvgIconInNamespace('cards', 'union_pay', this.setIcon('union-pay.svg'))
      .addSvgIconInNamespace('cards', 'visa', this.setIcon('visa.svg'))
      .addSvgIconInNamespace('cards', 'unknown', this.setIcon('unknown.svg'));
  }

  private setIcon(iconName: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/images/subscribe-plans/${iconName}`);
  }
}
