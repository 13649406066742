import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  CheckoutOpenOptions,
  CountryCode,
  initializePaddle,
  InitializePaddleOptions,
  Paddle,
  PaddleEventData
} from '@paddle/paddle-js';
import { lastValueFrom, Subject } from 'rxjs';
import { ConcretePlan } from 'src/app/pages/new-billing/models/plan.model';
import { BillingService, TransactionIdTypes } from 'src/app/services/billing/billing.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { AuthState } from 'src/app/store/auth/auth.state';
import { environment } from 'src/environments/environment';

const isPaddleSandbox = environment.env.TD_APP_PADDLE_SANDBOX === 'true';

type PaddleCheckoutOpenOptions = {
  transactionType?: TransactionIdTypes;
};

@Injectable({
  providedIn: 'root',
})
export class PaddleService {
  private readonly _eventCallback = new Subject<PaddleEventData>();
  private _paddle: Paddle;

  public readonly initialized: Promise<void>;
  public isInitialized = false;
  public isSandbox = isPaddleSandbox;
  public eventCallback$ = this._eventCallback.asObservable();

  constructor(
    private store: Store,
    private segmentService: SegmentService,
    private billingService: BillingService,
  ) {
    this.initialized = this.init();
  }

  private async init() {
    if (this.initialized) return this.initialized;

    try {
      this._paddle = await PaddleJs.initPaddle({
        environment: isPaddleSandbox ? 'sandbox' : 'production',
        token: environment.env.TD_APP_PADDLE_CLIENT_TOKEN,
        eventCallback: (event) => {
          this._eventCallback.next(event);
        },
      });
    } finally {
      this.isInitialized = true;
    }
  }

  public async renderInlineCheckout(config: CheckoutOpenOptions, options: PaddleCheckoutOpenOptions = {}) {
    await this.init();

    const { settings, customer: rawCustomer, customData: customDataFromConfig, items, transactionId } = config;
    const { transactionType = 'checkout' } = options;
    const { id: customerEmailId, address, ...customerFromConfig } = rawCustomer || {};
    const { id: addressId, ...customerAddress } = address || {};
    const geoInfo = await this.segmentService.getGeoInfo();

    /* When the transaction type is `update`, we should not pass the customer and custom data */
    const customer: CheckoutOpenOptions['customer'] = transactionType === 'checkout' ? {
      email: this.store.selectSnapshot(AuthState.company)?.companyOwnerEmail,
      address: {
        countryCode: geoInfo?.geo?.country as CountryCode,
        ...customerAddress,
      },
      ...customerFromConfig,
    } : undefined;

    const customData: CheckoutOpenOptions['customData'] = transactionType === 'checkout' ? {
      tdCompanyId: this.store.selectSnapshot(AuthState.company)?.id,
      deploymentId: environment.deployment,
      ...customDataFromConfig,
    } : undefined;

    this._paddle.Checkout.open({
      settings: {
        allowLogout: false,
        displayMode: 'inline',
        frameTarget: 'inline-checkout-container',
        frameStyle: 'position:relative; width: 100%; min-height: 100%',
        frameInitialHeight: 450,
        locale: 'en',
        ...settings,
      },
      customer,
      customData,
      transactionId,
    });
  }

  public async updatePlan(plan: ConcretePlan) {
    const payload = await lastValueFrom(this.billingService.getSwitchBillingPeriod(plan.planId));
    // @ts-expect-error: This is using the `updateCheckout` method from the augmented `Paddle` interface
    this._paddle.Checkout.updateCheckout(payload);
  }
}

// Temporary workaround to allow spyOn to work with the PaddleJs class
export class PaddleJs {
  static initPaddle(options?: InitializePaddleOptions): Promise<Paddle> {
    return initializePaddle(options);
  }
}
