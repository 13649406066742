import { Component, Input, SkipSelf } from '@angular/core';
import {
  PlanCardClickEvent,
  USE_CHOOSE_PLAN_INJECTION_TOKEN
} from 'src/app/components/new-billing/plan-card/plan-card.component';
import { ConcretePlan } from 'src/app/pages/new-billing/models/plan.model';
import { PricePeriodicity } from 'src/app/pages/new-billing/types/plan.types';
import { PlanSelectionService } from 'src/app/services/plan-selection/plan-selection.service';
import { TrackingContext } from 'src/app/services/tracking/context';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { ExperimentProps } from 'src/app/services/growthbook/types';

@Component({
  selector: 'app-choose-plan',
  templateUrl: './choose-plan.component.html',
  providers: [
    { provide: USE_CHOOSE_PLAN_INJECTION_TOKEN, useValue: true },
  ],
})
export class ChoosePlanComponent {
  @Input() currentPlan: ConcretePlan;

  paymentRecurrence: PricePeriodicity = 'year';

  constructor(
    private readonly planSelectionService: PlanSelectionService,
    private readonly growthBookService: GrowthBookService,
    @SkipSelf() private readonly trackingCtx: TrackingContext,
  ) { }

  public handlePlanCardBtnClick({ plan }: PlanCardClickEvent) {
    this.planSelectionService.handlePlanSelection(plan, this.shouldShowSocialProofOnBillingPage, this.trackingCtx.context);
  }

  get shouldShowSocialProofOnBillingPage(): boolean {
    const feature = this.growthBookService.getFeatureValue<ExperimentProps>('billing-page-social-proof-exp');
    return feature?.variationid === 'B';
  }
}
