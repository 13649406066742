<ng-container *ngIf="!isCollapsed">
  <div class="trial-box" fxLayout="column" *ngIf="variant === 'raised'; else outlinedVariant">
    <div class="trial-title">
      {{ 'time.duration.#day' | translate: {count: trialDaysLeft} }} {{ 'sidebar.widget.title' | translate }}
    </div>
    <div class="trial-subtitle">{{ 'sidebar.widget.subtitle' | translate: { plan: basePlan } }}</div>
    <div class="trial-description">{{ 'sidebar.widget.description' | translate }}</div>
    <ng-container *ngTemplateOutlet="upgradeButton"></ng-container>
    <ng-container *ngTemplateOutlet="contactSalesSection"></ng-container>
  </div>
</ng-container>

<ng-template #outlinedVariant>
  <div *ngIf="variant === 'outlined'; else accordionVariant">
    <span class="icon-wrapper">
      <mat-icon class="material-icons-outlined">timer</mat-icon>
    </span>
    <h6 class="typography-body2 bold">
      {{ 'time.duration.#day' | translate: { count: trialDaysLeft } }} {{ 'sidebar.widget.title' | translate }}
      {{ 'sidebar.widget.subtitle' | translate: { plan: basePlan } }}
    </h6>
    <p class="typography-caption">{{ 'sidebar.widget.description' | translate }}</p>
    <button
      *ngIf="(billingMode$ | async) !=='invoicing'"
      mat-stroked-button color="primary"
      (click)="trialUpgrade()"
      [trackClick]="btnClickTrackData()"
    >
      {{ 'sidebar.widget.button' | translate | uppercase }}
    </button>
  </div>
</ng-template>

<ng-template #accordionVariant>
  <mat-accordion>
    <mat-expansion-panel
      [(expanded)]=" panelOpenState"
      [class.dragging]="isDragging"
      (opened)="toggleOpenPanelState()"
      (closed)="toggleClosePanelState()"
      #expansionPanel>
      <!-- Expansion panel header -->
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h6 class="typography-subtitle1">
            <span [ngClass]="{ highlighted: !panelOpenState} ">
              {{ 'time.duration.#day' | translate: { count: trialDaysLeft } }}
              {{ 'sidebar.widget.title' | translate }}
            </span>
            {{ 'sidebar.widget.subtitle' | translate: { plan: basePlan } }}
          </h6>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <!-- Expansion panel content -->
      <div>
        <p class="typography-caption">{{ 'sidebar.widget.description' | translate }}</p>
        <ng-container *ngTemplateOutlet="upgradeButton"></ng-container>
        <ng-container *ngTemplateOutlet="contactSalesSection"></ng-container>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<!-- Create reusable templates for common elements -->
<ng-template #upgradeButton>
  <button
    *ngIf="(billingMode$ | async) !== 'invoicing' && (!isAddCardExperiment || (isAddCardExperiment && !hasCard))"
    [trackClick]="btnClickTrackData()"
    mat-raised-button
    class="choose-plan-btn mat-primary"
    (click)="trialUpgrade()">
    <span *ngIf="!isAddCardLoading">{{ getButtonText() | translate | uppercase }}</span>
  </button>
</ng-template>

<ng-template #contactSalesSection>
  <div class="contact-sales-widget" *ngIf="isWidgetExpanded && hasBookACallAccess">
    <hr [class.line-separator]="variant === 'accordion'">
    <div class="trial-description" *ngIf="variant !== 'accordion'">{{ 'sidebar.contactSalesWidget.description' | translate }}</div>
    <a (click)="contactSales()" [class.book-a-demo-btn]="variant === 'accordion'" [class.contact-sales-button]="variant !== 'accordion'" mat-stroked-button>
      <mat-icon>calendar_today</mat-icon>
      <span class="contact-sales-text">{{ 'sidebar.contactSalesWidget.cta' | translate }}</span>
    </a>
  </div>
</ng-template>
