import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChangeDetectorRef, Component } from '@angular/core';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthState } from 'src/app/store/auth/auth.state';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { SegmentService } from 'src/app/services/segment/segment.service';
import * as Sentry from '@sentry/angular-ivy';

type AddonItem = {
  imgSrc: string;
  imgAlt: string;
  imgWidth: number;
  title: string;
  price: string;
  subtitle: string;
  description: string;
  linkname: string;
};

type AddonsResponse = {
  flowId: string;
  addons: AddonItem[];
};

@UntilDestroy()
@Component({
  selector: 'app-addons-center',
  templateUrl: './addons-page.component.html',
  styleUrls: ['./addons-page.component.scss'],
})


export class AddonsPageComponent {
  addOnsSubject = new BehaviorSubject<AddonItem[]>([]);
  public addOns$: Observable<AddonItem[]> = this.addOnsSubject.asObservable();
  allowAccess = false;
  hubspotFlowId = null;
  constructor(
    private growthBookService: GrowthBookService,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private router: Router,
    private segment: SegmentService,
    ) {
    const viewAddonsPageGuard = this.growthBookService.getFeatureValue('addons-page', false);
    if (!viewAddonsPageGuard) {
      this.router.navigate(['/billing']);
      return;
    } else {
      this.allowAccess = true;
      this.segment.page('', 'Viewed AddOns Page', { path: '/addons' });
      this.initAddOns();
    }
  }

  private initAddOns(): void {
    this.growthBookService.getFeature('addons-list')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          const response: AddonsResponse = this.growthBookService.getFeatureValue('addons-list', { flowId: '', addons: [] });

          if (response.addons && Array.isArray(response.addons)) {
            this.addOnsSubject.next(response.addons);
            this.hubspotFlowId = response?.flowId;
          } else {
            Sentry.captureMessage('Failed to get addons list from GrowthBook.', {
              level: 'error',
              extra: { response },
            });
          }

          setTimeout(() => {
            this.cdr.detectChanges();
          });
        },
      });
  }

  startHubSpotChat(event: Event, linkname: string): void {
    event.preventDefault();
    this.trackChatWithSalesLinkClick(linkname);
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.load({
        flowId: this.hubspotFlowId,
      });
      window.HubSpotConversations.widget.open();
        this.hubspotChatTracking();
    } else {
      Sentry.captureMessage('Addons Page: HubSpot Conversations widget is not loaded.', {
        level: 'error',
        extra: {  company: this.store.selectSnapshot(AuthState.company) },
      });
    }
  }

  hubspotChatTracking() {
    if (window?.HubSpotConversations) {
      window.HubSpotConversations.on('conversationStarted', payload => {
         this.segment.trackInAppEvents('Addons Events', { name: 'Add-On acquisition', location: 'add-on Page' });
      });
    } else {
      window.hsConversationsOnReady = [
        () => {
          window.HubSpotConversations.on('conversationStarted', payload => {
            this.segment.trackInAppEvents('Addons Events', { name: 'Add-On acquisition', location: 'add-on Page' });
          });
        },
      ];
    }
  }

  trackChatWithSalesLinkClick(linkName: string) {
    this.segment.trackInAppEvents('Link Click',
      {
        linklocation: 'add-on Page',
        linkname: `Chat with Sales - ${linkName}`,
        linkurl: 'hubspotURL',
        pagename: 'addonpage',
        linktype: 'button',
      },
    );
  }
}
