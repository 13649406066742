import { Injectable } from '@angular/core';
import { Action, InitState, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isTimeDoctor } from 'src/app/app/app.constants';
import { AuthService } from 'src/app/services/auth.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { AuthStateModel, AUTH_STATE_TOKEN } from 'src/app/store/auth/auth.model';
import { appendOrUpdate } from 'src/app/util';
import { AuthCompany, AuthUser, PricingPlanCategory, WebAppTrackingMode } from 'src/models';
import { PopLoading, PushLoading } from '../loading/loading.actions';
import { SharedState } from '../shared/shared.state';
import {
  BeforeLogout, CompanySettingsChanged,
  EditProfile,
  ForgotPassword,
  InvalidateUser,
  Login,
  LoginAs,
  Logout,
  Register,
  SelectCompany,
  StatusChanged,
  TokenLogin,
  UpdateCurrentUser,
  UserSettingsChanged
} from './auth.actions';


@State({
  name: AUTH_STATE_TOKEN,
  defaults: {
    usersToRemember: [],
    errorMessage: null,
  },
})
@Injectable()
export class AuthState implements NgxsOnInit {
  static clockFormatFactory(store: Store) {
    return () => store.selectSnapshot(AuthState.hourFormat24) ? 24 : 12;
  }

  constructor(
    private store: Store,
    private authService: AuthService,
    private segment: SegmentService,
  ) { }

  @Selector()
  static authorized(state: AuthStateModel) { return state?.company && state.token; }

  @Selector()
  static token(state: AuthStateModel) { return state?.token; }

  @Selector()
  static user(state: AuthStateModel) { return state.user; }

  @Selector()
  static company(state: AuthStateModel) { return state.company; }

  @Selector()
  static role(state: AuthStateModel) { return state.company?.role; }

  @Selector()
  static companyTimezone(state: AuthStateModel) { return state.company?.companyTimezone; }

  @Selector()
  static userTimezone(state: AuthStateModel) { return state.company?.timezone || state.user?.timezone; }

  @Selector()
  static userLocalTimezone(state: AuthStateModel) { return state.user?.timezone; }

  @Selector()
  static firstDayOfWeek(state: AuthStateModel) { return state.company?.companySettings?.firstDayOfWeek ?? 1; }

  @Selector()
  static screencastsEnabled(state: AuthStateModel) { return state.company?.companySettings?.screencastsFeature !== false; }

  @Selector()
  static defaultTag(state: AuthStateModel) { return state.company?.allUsersTagId; }

  @Selector()
  static combinedId(state: AuthStateModel) {
    if (state.token && state.user && state.company && !state.userRequiresUpdate) {
      return `${state.user.id}__${state.company.id}`;
    }
    return null;
  }

  @Selector()
  static usersToRemember(state: AuthStateModel) { return state.usersToRemember; }

  @Selector()
  static companies(state: AuthStateModel) { return state.user?.companies; }

  @Selector()
  static hourFormat24(state: AuthStateModel) { return state.user.reportIn24HourFormat || false; }

  @Selector()
  static adminLogin(state: AuthStateModel) { return state.adminLogin; }

  @Selector()
  static isActive(state: AuthStateModel) {
    return state.user?.active;
  }

  @Selector()
  static isManager(state: AuthStateModel) {
    return ['manager', 'admin', 'owner'].includes(state.company.role);
  }

  @Selector()
  static isOwnerOrAdmin(state: AuthStateModel) {
    return ['admin', 'owner'].includes(state.company.role);
  }

  @Selector()
  static isRegularUser(state: AuthStateModel) {
    return state.company.role === 'user';
  }

  @Selector()
  static isGuest(state: AuthStateModel) {
    return state.company.role === 'guest';
  }

  @Selector()
  static isNotBasicPlanWithNoBillingChangeDate(state: AuthStateModel): boolean {
    const company: AuthCompany = state.company;
    if (!company) return false;
    const applyRestrictions = !!company?.billingChangeDate;
    return !applyRestrictions || !company?.pricingPlan?.includes('basic');
  }

  @Selector()
  static isBillingChanged(state: AuthStateModel): boolean {
    const company: AuthCompany = state.company;
    if (!company) return false;
    return !!company?.billingChangeDate;
  }

  @Selector()
  static tasksEnabled(state: AuthStateModel) {
    return state.company?.companySettings?.tasksMode !== 'off';
  }

  @Selector()
  static trackingMode(state: AuthStateModel) {
    return state.company?.companySettings?.trackingMode;
  }

  @Selector()
  static mobileAppTracking(state: AuthStateModel) {
    return state.company?.companySettings?.mobileAppTracking;
  }

  @Selector()
  static isSilent(state: AuthStateModel) {
    return state.company?.companySettings?.trackingMode === 'silent';
  }

  @Selector()
  static isPaid(state: AuthStateModel) {
    return state.company?.subscription.status === 'paid';
  }

  @Selector()
  static webAppTrackingMode(state: AuthStateModel) {
    return state.company?.companySettings?.webAndAppTracking;
  }
  @Selector()
  static webAppTrackingModeOn(state: AuthStateModel) {
    return state.company?.companySettings?.webAndAppTracking !== 'off';
  }

  @Selector()
  static extensionEnabledCompany(state: AuthStateModel) {
    return state.company?.companySettings?.custom?.browserExtensionEnabled || false;
  }

  @Selector()
  static extensionSettings(state: AuthStateModel) {
    return state.company?.companySettings?.custom?.browserExtensionSettings || {};
  }

  @Selector()
  static extensionEnabledUser(state: AuthStateModel) {
    const companyEnabled = this.extensionEnabledCompany(state);
    return companyEnabled === 'per-user' ? (state.company?.userSettings?.custom?.browserExtensionEnabled === true) : companyEnabled;
  }

  @Selector()
  static canAdjustRatings(state: AuthStateModel) {
    const company = state.company;
    return !!company && (company.role === 'admin' || company.role === 'owner'
      || (company.companySettings.allowManagerTagCategories && company.role === 'manager'));
  }

  @Selector()
  static canAdjustWorkSchedules(state: AuthStateModel) {
    const company = state.company;
    return !!company && (company.role === 'admin' || company.role === 'owner'
      || (company.companySettings.allowManagerWorkSchedules && company.role === 'manager'));
  }

  @Selector()
  static workScheduleFeatureEnabled(state: AuthStateModel) {
    return state.company?.companySettings?.workScheduleFeature;
  }

  @Selector()
  static allowEditTime(state: AuthStateModel) {
    return state.company?.userSettings?.allowEditTime;
  }

  @Selector()
  static isTimeDoctorCompany(state: AuthStateModel) {
    return isTimeDoctor(state.company?.id);
  }

  @Selector()
  static hasUsers(state: AuthStateModel) {
    return !state.company || state.company?.userCount > 1 || !!state.company?.lastTrack;
  }

  @Selector()
  static isEmpty(state: AuthStateModel) {
    return !this.hasUsers(state);
  }

  @Selector()
  static everHadBreak(state: AuthStateModel) {
    return state.company?.everHadBreak;
  }

  @Selector()
  static allowBreakLimit(state: AuthStateModel) {
    return state.company?.companySettings.allowBreakLimit;
  }

  @Selector()
  static pricingPlan(state: AuthStateModel) { return state.company?.pricingPlan; }

  @Selector()
  static pricingPlanCategory(state: AuthStateModel): PricingPlanCategory | undefined {
    if (typeof state?.company?.pricingPlan !== 'string') return undefined;

    const planCategories = {
      premium: [
        'premium_plan', 'premium_annual', 'premium', 'premium_plan_annual',
        'premium_july20', 'enterprise', 'enterprise_annual', 'resellers',
        'premium_yearly', 'premium_monthly',
      ],
      standard: [
        'standard_new_annual', 'standard_plan_annual', 'standard_new', 'standard_plan',
        'standard_july20', 'business', 'business_annual', '10peruserpermonth',
        'standard_yearly', 'standard_monthly', 'standard_monthly_01', 'standard_yearly_01',
      ],
      basic: [
        'basic', 'basic_annual', 'basic_plan', 'basic_plan_annual', 'basic_new',
        'basic_new_annual', 'basic_july20', 'basic_yearly', 'basic_monthly', 'basic_yearly_01', 'basic_monthly_01',
      ],
    };

    return Object.entries(planCategories).find(([category, plans]) =>
      plans.includes(state.company.pricingPlan))?.[0] as PricingPlanCategory;
  }

  @Selector()
  static AuthManagerProductivityEnabled(state: AuthStateModel) {
    return state.company.role === 'manager'
      && state.company.companySettings?.allowManagerTagCategories
      && state.company.companySettings?.webAndAppTracking !== WebAppTrackingMode.Off;
  }

  @Selector()
  static userCount(state: AuthStateModel) {
    return state.company?.userCount;
  }

  @Selector()
  static hasBillingAccess(state: AuthStateModel) {
    return state.company?.userSettings?.billingAccess || state.company?.role === 'owner';
  }

  @Action(InitState)
  ngxsOnInit({ patchState }: StateContext<AuthStateModel>) {
    patchState({ errorMessage: null, totpNeeded: false, userRequiresUpdate: true });
  }

  @Selector()
  static statusPlan(state: AuthStateModel) {
    return state.company?.subscription?.status;
  }

  @Selector()
  static billingUrl(state: AuthStateModel) {
    return state.company?.subscription?.provider === 'stripe' ? 'billing' : 'new-billing';
  }

  @Selector()
  static shouldIncludeSelf(state: AuthStateModel) {
    const company = state.company;
    if (!company) return false;
    return company.role === 'user' ||
      (!!company.userSettings?.showOnReports && company.role !== 'guest');
  }

  @Selector()
  static steps(state: AuthStateModel) {
    const {company} = state;

    return {
      inviteEmployee: company.userCount > 1,
      interactiveTour: company?.companySettings?.custom?.completedStep?.interactiveTour
        || company?.userSettings?.custom?.completedStep?.interactiveTour
        || false,
      choosePlan: company?.companySettings?.custom?.completedStep?.addCreditCard
        || company?.userSettings?.custom?.completedStep?.addCreditCard
        || false,
      adjustSettings: company?.companySettings?.custom?.completedStep?.companySettings
        || company?.userSettings?.custom?.completedStep?.companySettings
        || false,
      trackTime: company?.companySettings?.custom?.completedStep?.employeeTrack
        || company?.userSettings?.custom?.completedStep?.employeeTrack
        || false,
    };
  }

  @Action(Login)
  async login({ patchState, dispatch, setState }: StateContext<AuthStateModel>, { email, password, rememberMe, totpCode }: Login) {
    await lastValueFrom(dispatch(new PushLoading()));
    patchState({ errorMessage: null, totpNeeded: false, user: null, company: null });

    try {
      const res = await lastValueFrom(this.authService.login(email, password, totpCode));

      if ('status' in res && res.status === 'totpNeeded') {
        patchState({ totpNeeded: true, errorMessage: null });
        return;
      }

      if (!('token' in res)) return;
      const { token, ...user } = res;

      await lastValueFrom(dispatch(new LoginAs(user, token)));

      const newState: Partial<AuthStateModel> = {
        errorMessage: null,
        totpNeeded: false,
        userRequiresUpdate: false,
      };

      setState(patch({
        ...newState,
        ...(rememberMe && { usersToRemember: appendOrUpdate([{ token, user }], (a, b) => a.user.id === b.user.id) }),
      }));
    } catch (error) {
      if (error && error.error === 'invalidCredentials') {
        patchState({ errorMessage: 'login.invalidCredentials' });
      } else if (error && error.error === 'invalidTotpCode') {
        patchState({ errorMessage: 'login.invalidTotpCode' });
      } else if (error && error.error === 'tooManyFailedLogin') {
        patchState({ errorMessage: 'login.tooManyFailedLogin' });
      } else if (error && error.error === 'ssoOnlyAuth') {
        patchState({ errorMessage: 'login.ssoOnlyAuth' });
      } else {
        patchState({ errorMessage: 'login.genericError' });
        throw error;
      }
    } finally {
      await lastValueFrom(dispatch(new PopLoading()));
    }
  }

  @Action(LoginAs)
  async loginAs({ patchState, dispatch }: StateContext<AuthStateModel>, { user, token }: LoginAs) {
    const newState: Partial<AuthStateModel> = { user, token, userRequiresUpdate: true, company: null };
    patchState(newState);

    if (user.companies && user.companies.length === 1) {
      const company = user.companies[0];
      await lastValueFrom(dispatch(new SelectCompany(company)));
      await this.segment.identify();
      this.segment.serverSideCall();
      setTimeout(async () => {
        this.segment.track('Signed In', {
          userId: user.id,
        });
      }, 1000);
      this.segment.dataForExternalTracking();
      this.segment.trackReactivationRequest(company);
    }
  }

  @Action(ForgotPassword)
  async forgotPassword({ patchState, dispatch, setState }: StateContext<AuthStateModel>, { email }: ForgotPassword) {
    await lastValueFrom(dispatch(new PushLoading()));
    patchState({ errorMessage: null });

    try {
      await lastValueFrom(this.authService.forgotPassword(email));
    } catch (error) {
      if (error && error.error === 'invalidCredentials') {
        patchState({ errorMessage: 'forgotPassword.invalidCredentials' });
      } else if (error && error.error === 'badEmail') {
        patchState({ errorMessage: 'forgotPassword.invalidTotpCode' });
      } else {
        patchState({ errorMessage: 'login.genericError' });
        throw error;
      }
    } finally {
      await lastValueFrom(dispatch(new PopLoading()));
    }
  }

  @Action(UpdateCurrentUser)
  async updateCurrentUser(ctx: StateContext<AuthStateModel>, payload: UpdateCurrentUser) {
    if (!payload.hideLoading) {
      ctx.dispatch(new PushLoading());
    }

    try {
      // Update the current user with latest data
      const online = this.store.selectSnapshot(SharedState.online);

      if (online) {
        const me = await lastValueFrom(this.authService.auth());
        const state = ctx.getState();

        let company = me.companies.find(x => x.id === (state.company?.id));

        if (!company && me.companies && me.companies.length === 1) {
          company = me.companies[0];
        }

        ctx.patchState({ company, userRequiresUpdate: false });
        this.changeUser(ctx, me);
      }
    } finally {
      if (!payload.hideLoading) {
        ctx.dispatch(new PopLoading());
      }
    }
  }

  @Action(Logout)
  async logout({ setState, getState, dispatch }: StateContext<AuthStateModel>, payload: Logout) {
    const state = getState();
    if (state.user) {
      await this.segment.track('Signed Out', {
        userId: state.user.id,
      });
    }
    await lastValueFrom(dispatch(new BeforeLogout()));
    const usersToRemember = [...state.usersToRemember];
    if (payload.forget) {
      const ind = usersToRemember.findIndex(x => x.user.id === state.user.id);
      if (ind >= 0) {
        usersToRemember.splice(ind, 1);
      }

      if (state.token) {
        try {
          this.authService.logout();
        } catch (err) {
          console.error(err);
        }
      }
    }

    setState({ usersToRemember, errorMessage: payload.errorMessage });
    this.segment.dataForExternalTracking();
  }

  @Action(SelectCompany)
  selectCompany({ patchState }: StateContext<AuthStateModel>, payload: SelectCompany) {
    patchState({ company: payload.company });
  }

  @Action(UserSettingsChanged)
  userSettingsChanged(ctx: StateContext<AuthStateModel>, payload: UserSettingsChanged) {
    const { company } = ctx.getState();
    const custom = company?.userSettings.custom || {};
    const newCustom = payload.custom || {};
    const newCompany = {
      ...company,
      userSettings: {
        ...company.userSettings,
        ...payload.changes,
        custom: {
          ...custom,
          ...newCustom,
          completedStep: {
            ...(custom.completedStep || {}),
            ...(newCustom.completedStep || {}),
          },
          trackedSteps: {
            ...(custom.trackedSteps || {}),
            ...(newCustom.trackedSteps || {}),
          },
          dismissedStep: {
            ...(custom.dismissedStep || {}),
            ...(newCustom.dismissedStep || {}),
          },
        },
      },
    };

    this.changeCompany(ctx, newCompany);
  }

  @Action(CompanySettingsChanged)
  companySettingsChanged(ctx: StateContext<AuthStateModel>, payload: CompanySettingsChanged) {
    const { company } = ctx.getState();
    const custom = company?.companySettings.custom || {};
    const newCustom = payload.custom || {};
    const newCompany: AuthCompany = {
      ...company,
      ...payload.rootChanges,
      companySettings: {
        ...company.companySettings,
        ...payload.settingChanges,
        custom: {
          ...custom,
          ...newCustom,
          completedStep: {
            ...(custom.completedStep || {}),
            ...(newCustom.completedStep || {}),
          },
          dismissedStep: {
            ...(custom.dismissedStep || {}),
            ...(newCustom.dismissedStep || {}),
          },
        },
      },
    };

    this.changeCompany(ctx, newCompany);
  }

  @Action(StatusChanged)
  statusChanged(ctx: StateContext<AuthStateModel>, payload: StatusChanged) {
    const { company } = ctx.getState();
    const newCompany: AuthCompany = {
      ...company,
      lastSeen: {
        ...company.lastSeen,
        ip: payload.data.remoteIp,
        online: payload.data.mode !== 'offline',
        updatedAt: new Date().toUTCString(),
      },
      lastTrack: {
        ...company.lastTrack,
        ip: payload.data.remoteIp,
        activeAt: new Date().toUTCString(),
        projectId: payload.data.projectId,
        taskId: payload.data.taskId,
        status: payload.data.mode,
        online: payload.data.mode !== 'offline',
        updatedAt: new Date().toUTCString(),
      },
    };

    this.changeCompany(ctx, newCompany);
  }

  private changeCompany(ctx: StateContext<AuthStateModel>, newCompany: AuthCompany) {
    ctx.patchState({ company: newCompany });

    const { user } = ctx.getState();

    if (user) {
      const newCompanies = [...user.companies];
      const ind = user.companies.findIndex(x => x.id === newCompany.id);
      if (ind >= 0) {
        newCompanies[ind] = newCompany;
      }

      const newUser = { ...user, companies: newCompanies };

      this.changeUser(ctx, newUser);
    }
  }

  private changeUser(ctx: StateContext<AuthStateModel>, newUser: AuthUser) {
    const usersToRemember = [...ctx.getState().usersToRemember];
    const ind = usersToRemember.findIndex(x => x.user.id === newUser.id);
    if (ind >= 0) {
      usersToRemember[ind] = { user: newUser, token: usersToRemember[ind].token };
    }

    ctx.patchState({ user: newUser, usersToRemember });
  }

  @Action(InvalidateUser)
  invalidateUser({ getState, setState }: StateContext<AuthStateModel>, { errorMessage }: InvalidateUser) {
    const state = getState();

    setState((draft) => {
      return {
        ...draft,
        usersToRemember: state.usersToRemember.filter(x => x.token !== state.token),
        errorMessage,
        userRequiresUpdate: false,
        user: null,
        company: null,
        token: null,
        adminLogin: false,
      };
    });
  }

  @Action(TokenLogin)
  TokenLogin({ patchState, dispatch, getState }: StateContext<AuthStateModel>, payload: TokenLogin) {
    patchState({ token: payload.token, userRequiresUpdate: true, company: null, adminLogin: payload.adminLogin });
    return dispatch(new UpdateCurrentUser()).pipe(tap(() => {
      const st = getState();
      const companies = st.user.companies;

      const foundCompany = companies?.find(x => x.id === payload.company) || (companies?.length === 1 && companies[0]);
      if (foundCompany) {
        patchState({ company: foundCompany });
        this.segment.dataForExternalTracking();
      }
    }));
  }

  @Action(EditProfile)
  async editProfile({ setState }: StateContext<AuthStateModel>, payload: EditProfile) {
    try {
      const tokenResult = await this.authService.editProfile(payload.properties);

      const { password, ...pl } = payload.properties;
      setState(patch({ user: (x) => ({ ...x, ...pl }), token: (x) => tokenResult.token || x, errorMessage: null }));
    } catch (error) {
      if (error?.error === 'resourceExists') {
        setState(patch({ errorMessage: 'userProfile.emailExists' }));
      } else {
        setState(patch({ errorMessage: 'userProfile.genericError' }));
      }
    }
  }

  @Action(Register)
  async register({ setState }: StateContext<AuthStateModel>, payload: Register) {
    const tokenResult = await this.authService.register(payload.email, payload.name, payload.password);

    setState(patch({ user: (x) => ({ ...x, name: payload.name, active: true }), token: (x) => tokenResult.token || x }));
  }
}
