import { ScrollingModule } from '@angular/cdk/scrolling';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Inject, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule, DomSanitizer, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { menuProvider } from 'src/app/common/menu-def.provider';
import { FloatingWidgetComponent } from 'src/app/components/floating-widget/floating-widget.component';
import { NavSidebarModule } from 'src/app/components/nav-sidebar/nav-sidebar.module';
import { NewBillingComponentsModule } from 'src/app/components/new-billing/new-billing-components.module';
import { TopBannerModule } from 'src/app/components/top-banner/top-banner.module';
import { httpBillingInterceptorProvider } from 'src/app/services/billing/http-billing.interceptor';
import { CompanyDateAdapterModule } from 'src/app/services/company-date-adapter';
import { environment } from 'src/environments/environment';
import { ErrorComponent } from '../components/error/error.component';
import { HeaderComponent } from '../components/header/header.component';
import { LoadingComponent } from '../components/loading/loading.component';
import { SidenavComponent } from '../components/sidenav/sidenav.component';
import { StarterSidebarComponent } from '../components/starter-sidebar/starter-sidebar.component';
import { StepCompletionNotificationComponent } from '../components/step-completion-notification/step-completion-notification.component';
import { CommonSnackBarComponent } from '../dialogs/common-snackbar/common-snack-bar';
import { ProgressDialogComponent } from '../dialogs/progress-dialog/component';
import { UserDeletionWarningDialogComponent } from '../dialogs/warning-dialog/user-deletion-warning-dialog/component';
import { WarningDialogComponent } from '../dialogs/warning-dialog/warning-dialog.component';
import { ThemeChangeModalComponent } from '../components/theme-change-modal/theme-change-modal.component';
import { socketHandlerInitProvider } from '../initializers/socket-handler.init';
import { translateInitProvider } from '../initializers/translate.init';
import { SharedModule } from '../modules/shared.module';
import { API_URL, AppType, APP_NAME, APP_TYPE, APP_URL, DATA_INTEGRITY_APIKEY, DATA_INTEGRITY_APIURL, DEFAULT_API_VERSION, GET_CLOCK_FORMAT, GOOGLE_CLIENT_ID, HTTP_TIMEOUT, SOCKET_API_ROOT, SOCKET_HOST, TDC_CHECK_EMAIL_END_POINT, USE_WEB_SOCKET } from '../services/app.constants';
import { BrandingService } from '../services/branding.service';
import { sentryProviders } from '../services/error.handler';
import { exposeStoreInitProvider } from '../services/expose-store.init';
import { httpEncoderInterceptorProvider } from '../services/http-encode-interceptor.service';
import { httpGzipInterceptorProvider } from '../services/http-gzip-interceptor.service';
import { httpInterceptorProvider } from '../services/http-interceptor.service';
import { NoopSocketService } from '../services/noop-socket.service';
import { socketInitProvider, SocketService } from '../services/socket.service';
import { TimeService } from '../services/time';
import { AuthState } from '../store/auth/auth.state';
import { defaultIndexedDbServiceProvider } from '../store/indexed-db/indexed-db.service';
import { NgxsStoreModule } from '../store/store.module';
import { AppComponent } from './app.component';
import { getAllowSocket, getApiUrl, getHttpTimeout, getSocketUrl, getUseSocketApi } from './app.constants';
import { AppRoutingModule } from './app.routing';
import { createMessageFormatConfig, createTranslateLoader, CustomTranslateParser } from './translate';


@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    HeaderComponent,
    SidenavComponent,
    WarningDialogComponent,
    ProgressDialogComponent,
    CommonSnackBarComponent,
    UserDeletionWarningDialogComponent,
    LoadingComponent,
    StarterSidebarComponent,
    FloatingWidgetComponent,
    StepCompletionNotificationComponent,
    ThemeChangeModalComponent,
  ],
  imports: [
    NgxsStoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSidenavModule,
    MatExpansionModule,
    MatSelectModule,
    MatButtonModule,
    CompanyDateAdapterModule,
    ScrollingModule,
    SharedModule,
    TopBannerModule,
    HammerModule,
    NavSidebarModule,
    NewBillingComponentsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, APP_BASE_HREF],
      },
      parser: {
        provide: TranslateParser,
        useClass: CustomTranslateParser,
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: environment.baseHref },
    { provide: HTTP_TIMEOUT, useFactory: getHttpTimeout },
    { provide: APP_URL, useFactory: BrandingService.appUrlFactory, deps: [BrandingService] },
    { provide: SOCKET_API_ROOT, useValue: environment.socketApiRoot },
    { provide: SOCKET_HOST, useFactory: getSocketUrl, deps: [BrandingService] },
    { provide: API_URL, useFactory: getApiUrl, deps: [BrandingService] },
    { provide: APP_NAME, useValue: environment.appName },
    { provide: APP_TYPE, useValue: 'web' as AppType },
    { provide: GOOGLE_CLIENT_ID, useValue: environment.googleClientId },
    { provide: TDC_CHECK_EMAIL_END_POINT, useValue: environment.tdcCheckEmailEndPoint },
    { provide: DATA_INTEGRITY_APIURL, useValue: environment.dataIntegrityApiUrl },
    { provide: DATA_INTEGRITY_APIKEY, useValue: environment.dataIntegrityApiKey },
    { provide: USE_WEB_SOCKET, useFactory: getUseSocketApi },
    { provide: DEFAULT_API_VERSION, useValue: environment.defaultApiVersion },
    { provide: GET_CLOCK_FORMAT, useFactory: AuthState.clockFormatFactory, deps: [Store] },
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useFactory: createMessageFormatConfig,
    },
    ...sentryProviders,
    { provide: APP_INITIALIZER, useFactory: BrandingService.initialize, deps: [BrandingService], multi: true },
    ...(!environment.production ? [exposeStoreInitProvider] : []),
    translateInitProvider,
    httpInterceptorProvider,
    httpEncoderInterceptorProvider,
    httpGzipInterceptorProvider,
    httpBillingInterceptorProvider,
    defaultIndexedDbServiceProvider,
    { provide: SocketService, useClass: getAllowSocket() ? SocketService : NoopSocketService },
    socketInitProvider,
    socketHandlerInitProvider,
    { provide: APP_INITIALIZER, useFactory: () => () => null, deps: [TimeService], multi: true }, // Initialize TimeService
    menuProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    @Inject(APP_BASE_HREF) baseUrl: string,
  ) {
    matIconRegistry.addSvgIcon(
      `warning_outline`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/warning.svg`),
    );

    matIconRegistry.addSvgIcon(
      `settings_applications`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/settings_applications.svg`),
    );

    matIconRegistry.addSvgIcon(
      `delete_outline`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/delete.svg`),
    );

    matIconRegistry.addSvgIcon(
      `help_outline`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/help_outline.svg`),
    );

    matIconRegistry.addSvgIcon(
      `notifications_twotone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/notifications_twotone.svg`),
    );

    matIconRegistry.addSvgIcon(
      `notifications_important_twotone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/notifications_important_twotone.svg`),
    );

    matIconRegistry.addSvgIcon(
      `notifications_off_twotone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/notifications_off_twotone.svg`),
    );

    matIconRegistry.addSvgIcon(
      `announcement_twotone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/announcement_twotone.svg`),
    );

    matIconRegistry.addSvgIcon(
      `info_towtone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/info_towtone.svg`),
    );

    matIconRegistry.addSvgIcon(
      `warning_twotone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/warning_twotone.svg`),
    );

    matIconRegistry.addSvgIcon(
      `credit_card_twotone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/credit_card_twotone.svg`),
    );

    matIconRegistry.addSvgIcon(
      `more_settings`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/users/more_settings.svg`),
    );

    matIconRegistry.addSvgIcon(
      `dashboard_twotone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/dashboard_twotone.svg`),
    );

    matIconRegistry.addSvgIcon(
      `flag_twotone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/flag_twotone.svg`),
    );

    matIconRegistry.addSvgIcon(
      `warning_generic`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/warning_generic.svg`),
    );

    matIconRegistry.addSvgIcon(
      `check_twotone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/check_twotone.svg`),
    );
    matIconRegistry.addSvgIcon(
      `sync_twotone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/sync_twotone.svg`),
    );
    matIconRegistry.addSvgIcon(
      `flag_twotone_outlined`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/flag_twotone_outlined.svg`),
    );
    matIconRegistry.addSvgIcon(
      `desktop_icon`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/desktop_icon.svg`),
    );
    matIconRegistry.addSvgIcon(
      'timedoctor_logo_blue',
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/brand/timedoctor/logo_blue.svg`),
    );
  }
}
