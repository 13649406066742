export const StepIds = {
  CREATE_ACCOUNT: 'createAccount',
  INTERACTIVE_TOUR: 'interactiveTour',
  INVITE_EMPLOYEE: 'inviteEmployee',
  TRACK_TIME: 'trackTime',
  ADJUST_SETTINGS: 'adjustSettings',
  CHOOSE_PLAN: 'choosePlan',
} as const;

export type StepIds = keyof typeof StepIds;

export const STEP_PROGRESS_MESSAGES = {
  [StepIds.CREATE_ACCOUNT]: 'Created account',
  [StepIds.INTERACTIVE_TOUR]: 'Take our interactive tour',
  [StepIds.INVITE_EMPLOYEE]: 'Invite your first employee',
  [StepIds.TRACK_TIME]: 'Get an employee to track time',
  [StepIds.ADJUST_SETTINGS]: 'Review & adjust settings',
  [StepIds.CHOOSE_PLAN]: 'Choose your plan',
  completeStepId: 'completed',
} as const;
