import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BillingDetail } from 'src/app/pages/new-billing/types/api.types';

@Component({
  selector: 'app-actionable-item',
  templateUrl: './actionable-item.component.html',
  styleUrls: ['./actionable-item.component.scss'],
})
export class ActionableItemComponent implements BillingDetail {
  @Input()
  label: string;

  @Input()
  content: string;

  @Input()
  actionableIcon: string;

  @Output()
  actionClick: EventEmitter<void> = new EventEmitter<void>();

  public handleIconClick(): void {
    this.actionClick.emit();
  }
}
