import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, Inject, Injector, OnDestroy, Output, Type } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { filter, map, Observable } from 'rxjs';
import { getDisableOnboarding, isTimeDoctor } from 'src/app/app/app.constants';
import { hasMandatoryCall, MenuDef, MENU_DEF } from 'src/app/common/menu-def.provider';
import { ConcretePlan } from 'src/app/pages/new-billing/models/plan.model';
import { NewBillingState } from 'src/app/pages/new-billing/store/state';
import { BillingApiData } from 'src/app/pages/new-billing/types/api.types';
import { BrandingService } from 'src/app/services/branding.service';
import { ExperimentsService } from 'src/app/services/experiments/experiments.service';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { ExperimentProps } from 'src/app/services/growthbook/types';
import { PlanSelectionService } from 'src/app/services/plan-selection/plan-selection.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { TrialWidgetService } from 'src/app/services/trial-widget/trial-widget.service';
import { AuthState } from 'src/app/store/auth/auth.state';
import {
  CompleteWelcomeStep, DismissWelcomeStep, ToggleSidebarCollapsed
} from 'src/app/store/onboarding/onboarding.actions';
import { OnboardingState } from 'src/app/store/onboarding/onboarding.state';
import { scroll, swipe } from 'src/app/util/swipe';
import { AuthCompany, AuthUser, CompanySubscription } from 'src/models';
import { AddCreditCardStep } from './items/add-credit-card';
import { AddEmployeeStep } from './items/add-employee';
import { AddProjectTaskStep } from './items/add-project-task';
import { CompanySettingsStep } from './items/company-settings';
import { ConfirmEmailStep } from './items/confirm-email';
import { CreateAccountStep } from './items/create-acc';
import { EmployeeTrackStep } from './items/employee-track';
import { SelectProductiveStep } from './items/select-productive';
import { SetupGroupsStep } from './items/setup-groups';
import { SidebarItem } from './sidebar-item';

export interface StarterSidebarChangeEvent {
  isOwner: boolean;
  allCompleted: boolean;
  welcomeSidebarCompleted: boolean;
}

const allowedStatuses: Array<CompanySubscription['status']> = ['paid', 'trial'];

@UntilDestroy()
@Component({
  selector: 'app-starter-sidebar',
  templateUrl: './starter-sidebar.component.html',
  styleUrls: ['./starter-sidebar.component.scss'],
})
export class StarterSidebarComponent implements OnDestroy {
  private step0 = CreateAccountStep;
  private step1 = ConfirmEmailStep;
  private step2 = AddEmployeeStep;
  private step3 = EmployeeTrackStep;
  private step4 = AddProjectTaskStep;
  private step5 = SetupGroupsStep;
  private step6 = CompanySettingsStep;
  private step7 = SelectProductiveStep;
  private step8 = AddCreditCardStep;

  @Output() sidebarChange: EventEmitter<StarterSidebarChangeEvent> = new EventEmitter<StarterSidebarChangeEvent>();

  @Select(AuthState.user) user$: Observable<AuthUser>;
  @Select(AuthState.company) company$: Observable<AuthCompany>;
  @Select(NewBillingState.billingData) billingData$: Observable<BillingApiData>;

  companyRdbmsStatus: string;
  steps: SidebarItem[];
  isOwner = false;
  allCompleted = false;
  trackedAllCompleted: boolean;
  progress = '';
  collapsed: boolean;
  isReporMenuItem = false;
  canShowSidebar = false;
  isMobileState: boolean;

  currentPlan: ConcretePlan;

  get isAddCardExperiment() {
    return this.experimentsService.isAddCardExperiment;
  }

  get shouldShowSocialProofOnBillingPage(): boolean {
    const feature = this.growthbookService.getFeatureValue<ExperimentProps>('billing-page-social-proof-exp');
    return feature?.variationid === 'B';
  }

  get canShowTrialWidget() {
    return this.trialWidget.canShowTrialWidget();
  }

  constructor(
    private readonly store: Store,
    private readonly injector: Injector,
    private readonly branding: BrandingService,
    private readonly segment: SegmentService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly trialWidget: TrialWidgetService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly planSelectionService: PlanSelectionService,
    private readonly experimentsService: ExperimentsService,
    private readonly growthbookService: GrowthBookService,
    @Inject(MENU_DEF) public menuDef: MenuDef,
  ) {
    this.company$.pipe(untilDestroyed(this))
      .subscribe(company => {
        this.initialize(company);
        this.isOwner = company?.role === 'owner';
      });
    store.select(OnboardingState.sidebarCollapsed).pipe(untilDestroyed(this)).subscribe(x => this.collapsed = x);
    this.initializeBreakpointObserver();
    this.subscribeToRouterEvents();
    this.initializeBillingData();
  }

  private initializeBillingData(): void {
    this.billingData$
      .pipe(
        untilDestroyed(this),
        filter((billingData: BillingApiData) => Boolean(billingData?.plans?.length)),
        map((billingData: BillingApiData) => ({
          billingData,
          userCount: this.store.selectSnapshot(AuthState.company).userCount,
        })),
      )
      .subscribe(({ billingData, userCount }) => {
        this.currentPlan = new ConcretePlan(
          billingData,
          userCount,
          this.growthbookService.getFeatureValue('billing:discount_removal_companies', false),
        );
      });
  }

  private initializeBreakpointObserver() {
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.Handset])
      .pipe(untilDestroyed(this))
      .subscribe((state: BreakpointState) => {
        this.isMobileState = state.matches;
        this.evaluateCollapseState();
      });
  }

  private subscribeToRouterEvents() {
    this.router.events.pipe(
      untilDestroyed(this),
      filter((event) => event instanceof NavigationEnd),
    ).subscribe(() => this.handleRouteData());
  }

  handleRouteData() {
    const deepChildRoute = this.getDeepestChild(this.activatedRoute);
    const routeData = deepChildRoute.snapshot.data;

    this.isReporMenuItem = Boolean(routeData?.reportId);
    this.collapsed = this.isReporMenuItem ? this.getCollapseStateForReport() : this.isMobileState;
  }

  getDeepestChild(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  getCollapseStateForReport(): boolean {
    if (this.isMobileState) {
      return true; // Always collapsed on mobile
    } else {
      return this.isReporMenuItem;
    }
  }

  private evaluateCollapseState() {
    if (!this.isReporMenuItem) {
      if (this.isMobileState) {
        this.collapsed = true;
      } else {
        this.collapsed = false;
      }
    }
  }

  ngOnDestroy() {
    this.clear();
  }

  clear() {
    this.allCompleted = false;
    if (this.steps) {
      this.steps.forEach((step) => {
        if (typeof step.clear === 'function') {
          step.clear();
        }
      });
      this.steps = null;
      this.companyRdbmsStatus = null;
    }
  }

  async initialize(company: AuthCompany) {
    this.clear();
    if (!company) return;
    if (company.role !== 'owner') return;

    const { userSettings, id: companyId, subscription } = company;
    const custom = userSettings?.custom;
    const hideSidebar = getDisableOnboarding();

    const welcomeSidebarCompleted = custom?.welcomeSidebarCompleted;
    this.trackedAllCompleted = custom?.completedStep?.['trackedAll'];

    this.canShowSidebar =
      !hideSidebar && !isTimeDoctor(companyId)
      && allowedStatuses.includes(subscription.status)
      && !custom?.welcomeSidebarCompleted && !this.trackedAllCompleted
      && !hasMandatoryCall(company);

    const steps: Type<SidebarItem>[] = [
      this.step0,
      this.step1,
      company.companySettings.trackingMode !== 'silent' ? this.step2 : null,
      company.companySettings.tasksMode !== 'off' ? this.step3 : null,
      company.companySettings.trackingMode !== 'silent' ? this.step4 : null,
      this.step5,
      this.step6,
      company.companySettings.webAndAppTracking !== 'off' ? this.step7 : null,
      this.branding.billingDisabled() ? null : this.step8,
    ].filter(x => x);

    const di = Injector.create({ providers: steps.map(x => ({ provide: x, deps: [Injector] })), parent: this.injector });
    this.steps = steps.map(x => di.get(x));
    this.steps.forEach((step) => {
      const dismissed = custom?.dismissedStep?.[step.id];
      const completed = custom?.completedStep?.[step.id];

      // Check if employee was added during onboarding
      const hasAddedEmployees = company.userCount > 1;
      const hasTrackedEmployeeStep = userSettings?.custom?.trackedSteps?.addEmployee;

      if (hasAddedEmployees && !hasTrackedEmployeeStep) {
        this.store.dispatch(new CompleteWelcomeStep('addEmployee'));
      }

      if (dismissed || completed) {
        step.completed = true;
      }
      if (!step.completed && typeof step.start === 'function') {
        step.start();
      }
    });

    this.steps?.sort((a, b) => {
      if (a.completed === b.completed) { return 0; }
      if (a.completed && !b.completed) { return -1; }
      return 1;
    });
    this.recalculateProgress();
    const payload = { isOwner: this.isOwner, allCompleted: this.allCompleted, welcomeSidebarCompleted };
    this.sidebarChange.emit(payload);
  }

  recalculateProgress() {
    if (!this.steps) {
      return;
    }
    const count = this.steps.filter(x => x.completed).length;
    this.progress = `${Math.floor((count / this.steps.length) * 100)}%`;

    this.allCompleted = count === this.steps.length;

    if (this.trackedAllCompleted) {
      this.allCompleted = true;
    }

    if (this.allCompleted && !this.trackedAllCompleted) {
      this.store.dispatch(new CompleteWelcomeStep('trackedAll'));
    }
  }

  dismissStep(step: SidebarItem): void {
    if (step.preventDismiss) return;

    // If a link is defined, simulate a click on the link
    if (step.link) {
      const linkElement = document.getElementById(`link-${step.id}`);
      if (linkElement) {
        (linkElement).click();
      }
    }

    // If an action click handler is defined, call it
    if (step.onActionClick) {
      step.onActionClick();
    }

    this.store.dispatch(new DismissWelcomeStep(step.id));
  }

  toggleSidebar(): void {
    this.collapsed = !this.collapsed;
    this.store.dispatch(new ToggleSidebarCollapsed(this.collapsed));
  }

  clickStep(step: SidebarItem) {
    if (step.id === 'addCreditCard' && this.isAddCardExperiment && !step.completed) {
      this.planSelectionService.handlePlanSelection(
        this.currentPlan, this.shouldShowSocialProofOnBillingPage, {
        page: 'trial widget',
        location: 'sidebar widget',
      });
    }
    // do nothing.
  }

  onSwipe(e: TouchEvent, when: string): void {
    swipe(e, when, (swipeDirection: string) => {
      if (swipeDirection === 'next' || swipeDirection === 'previous') {
        this.toggleSidebar();
      } else if (swipeDirection === 'up' || swipeDirection === 'down') {
        scroll(swipeDirection);
      }
    });
  }

  getStepRouterLink(step: SidebarItem): string | null {
    return this.isAddCardExperiment && step.id === 'addCreditCard'
      ? null
      : (step.link || '');
  }
}
