import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { CheckoutEventNames, CheckoutEventsData, CheckoutEventsTotals, CountryCode, PaddleEventData } from '@paddle/paddle-js';
import { take } from 'rxjs/operators';
import { ConcretePlan } from 'src/app/pages/new-billing/models/plan.model';
import { SetTransactionIdCacheOption } from 'src/app/pages/new-billing/store/actions';
import { NewBillingState } from 'src/app/pages/new-billing/store/state';
import { BillingDetailsApiData, PlanId } from 'src/app/pages/new-billing/types/api.types';
import { PaymentSummaryInfo } from 'src/app/pages/new-subscribe-plans/store/model';
import { BillingService, CacheOption, TransactionIdTypes } from 'src/app/services/billing/billing.service';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { PaddleService } from 'src/app/services/paddle/service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { PartialCtx, StaticTrackingContext, TrackingContext } from 'src/app/services/tracking/context';

export type CheckoutDialogData = {
  currentPlan: ConcretePlan;

  /** Indicates whether normal checkout or checkout for updating card is used. By default, this is false */
  isUpdatingCard?: boolean;

  /** Sets the plan id that will be passed to the transaction id API. By default, the `planId` that
   * is used is the one from the {@link currentPlan} */
  planId?: PlanId;

  /**
   * Sets the data for the payment summary left section. When this data is set, the checkout
   * won't display the billing details as usual but instead, a summary of failed charges will be
   * shown.
   */
  paymentFailureSummaryData?: PaymentSummaryInfo;

  /** Callback that will be executed after successful checkout. */
  onCheckoutSuccess?: (eventData: CheckoutEventsData, dialogRef: MatDialogRef<CheckoutComponent>) => Promise<void>;

  /** Indicates whether the overlay loader should be displayed. By default, this is true */
  withOverlayLoader?: boolean;

  /** Tracking context that will be used for any tracking props. */
  trackingCtx: PartialCtx;
  socialProof?: boolean;
};

export type CheckoutDialogClose = {
  billingDetails?: BillingDetailsApiData;
};

type TrackingEvents = Extract<
  CheckoutEventNames,
  'checkout.completed' | 'checkout.customer.updated' | 'checkout.payment.initiated' | 'checkout.payment.failed' | 'checkout.error'
>;

const eventsMap: Record<TrackingEvents, string> = {
  'checkout.completed': 'Checkout completed',
  'checkout.customer.updated': 'Clicked Continue',
  'checkout.payment.initiated': 'Clicked on Pay Now',
  'checkout.payment.failed': 'Checkout payment failed',
  'checkout.error': 'Checkout internal error',
};

@UntilDestroy()
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [
    {
      provide: TrackingContext,
      useFactory: (dialogData: CheckoutDialogData) => {
        return new StaticTrackingContext(dialogData.trackingCtx);
      },
      deps: [MAT_DIALOG_DATA],
    },
  ],
})
export class CheckoutComponent implements OnInit {
  currentPlan: ConcretePlan;
  isPaddleUpdated = false;
  status: 'loading' | 'updating' | 'idle' | 'error' = 'loading';
  withOverlayLoader: boolean;
  totalsSummary: CheckoutEventsTotals;
  isUnsupportedCountry = false;
  transactionIdCacheOption: CacheOption;
  companyLogos = [
    { filename: 'capterra.png', alt: 'Capterra Logo', width: '83px', height: '40px' },
    { filename: 'getapp.png', alt: 'Getapp logo', width: '83px', height: '40px' },
    { filename: 'g2.png', alt: 'G2 Logo', width: '83px', height: '40px' },
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: CheckoutDialogData,
    public paymentProvider: PaddleService,
    private billingService: BillingService,
    private dialogRef: MatDialogRef<CheckoutComponent>,
    private segment: SegmentService,
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef,
    private growthBook: GrowthBookService,

  ) {
    const { currentPlan, withOverlayLoader = true } = dialogData;

    this.currentPlan = currentPlan.clone();
    this.currentPlan.switchPlanRecurrence(currentPlan.paymentModel.paymentRecurrence);
    this.withOverlayLoader = withOverlayLoader;
    this.transactionIdCacheOption = store.selectSnapshot(NewBillingState.transactionIdCacheOption);
  }

  async ngOnInit() {
    this.paymentProvider.eventCallback$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(event => {
        this.trackingHandlerEvent(event.name);
        this.handleCheckoutEvents(event);
      });

    this.checkoutOpenEvent();
    this.segment.trackInAppEvents('CC Popup Screen', { ...this.dialogData.trackingCtx });
  }

  get trialCardMessage(): string {
    return !this.currentPlan.paymentModel.cardLast4 ? 'billing.trialCreditCharged' : 'billing.taxesInfoMessage';
  }

  async checkoutOpenEvent() {
    this.status = 'loading';

    const transactionType: TransactionIdTypes = this.dialogData.isUpdatingCard ? 'updatePaymentDetails' : 'checkout';
    const discountId = this.currentPlan.paymentModel.discount?.code;

    try {
      const homeCta = await this.segment.getExperimentDataByName('popup-signup-trigger');

      /**
       * The campaign discount codes.
       * These are used if no campaign discount feature flag is set.
       */
      const campaignDiscounts = 'TIMEDOCTORPARTNER10,TIMEDOCTORPARTNER20,TIMEDOCTORPARTNER50,TDDEELPROMO10';

      /**
       * Checks if the home CTA (call to action) is part of the campaign discounts.
       * Converts homeCta to uppercase and checks its presence in campaignDiscounts.
       */
      const isCampaignDiscount = typeof homeCta === 'string' && campaignDiscounts.includes(homeCta.toUpperCase());

      const transactionPayload = {
        type: transactionType,
        planId: this.dialogData.planId,
        discountId: isCampaignDiscount ? discountId : undefined,
        cache: this.transactionIdCacheOption,
      };
      const geoInfo = await this.segment.getGeoInfo();
      const paddleCountries: { code: CountryCode, name: string }[] = this.growthBook.getFeatureValue('paddle-supported-countries', []);
      const countryCode = geoInfo?.geo?.country as CountryCode;

      if (paddleCountries && !paddleCountries.find((country) => country.code === countryCode)) {
        this.isUnsupportedCountry = true;
        this.status = 'error';
        return;
      }

      this.billingService.getTransactionId(transactionPayload).pipe(take(1)).subscribe({
        next: transactionId => {
          this.paymentProvider.renderInlineCheckout({ transactionId }, { transactionType });
          this.store.dispatch(new SetTransactionIdCacheOption('store'));
        },
        error: () => {
          this.status = 'error';
        },
      });
    } catch (error) {
      this.status = 'error';
    }
  }

  toggleChanges(event: MatSlideToggleChange) {
    this.status = 'updating';

    const paymentRecurrence = event.checked ? 'year' : 'month';
    this.currentPlan.switchPlanRecurrence(paymentRecurrence);

    // If the planId is different from the current plan, we need to revalidate the cache from the transactionId
    const cacheOption = this.dialogData.currentPlan.planId !== this.currentPlan.planId ? 'no-cache' : 'store';
    this.store.dispatch(new SetTransactionIdCacheOption(cacheOption));

    this.dialogData.planId = this.currentPlan.planId;
    this.paymentProvider.updatePlan(this.currentPlan);
  }

  breadcrumbClick() {
    if (this.isPaddleUpdated) {
      this.checkoutOpenEvent();
    }
    this.isPaddleUpdated = false;
  }

  trackingHandlerEvent(eventName: CheckoutEventNames) {
    const eventNameMapped = eventsMap[eventName];

    if (eventNameMapped) this.segment.trackInAppEvents(eventNameMapped, { ...this.dialogData.trackingCtx });
  }

  // Handle the outside click event when the modal is closed
  onOutsideClick(event: MouseEvent) {
    if (event.target['className'] === 'cdk-overlay-backdrop cdk-overlay-dark-backdrop') {
      this.segment.trackInAppEvents('Click Outside of the CC popup screen',
        { ...this.dialogData.trackingCtx });
    }
  }

  private handleCheckoutEvents(event: PaddleEventData) {
    if (!event.name) this.status = 'idle';

    switch (event.name) {
      case CheckoutEventNames.CHECKOUT_CUSTOMER_UPDATED:
        this.isPaddleUpdated = true;
        break;

      case CheckoutEventNames.CHECKOUT_COMPLETED:
        this.checkoutCompleted(event.data);
        break;

      case CheckoutEventNames.CHECKOUT_LOADED:
      case CheckoutEventNames.CHECKOUT_UPDATED:
        this.handleCheckoutUpdates(event.data);
        break;
      case CheckoutEventNames.CHECKOUT_ERROR:
        this.status = 'error';
        break;
    }
  }

  private handleCheckoutUpdates(data: CheckoutEventsData): void {
    this.status = 'idle';
    this.totalsSummary = data.recurring_totals;
    this.changeDetectorRef.detectChanges();
  }

  private async checkoutCompleted(eventData: CheckoutEventsData) {
    if (this.dialogData.onCheckoutSuccess) {
      this.dialogData.onCheckoutSuccess(eventData, this.dialogRef);
    }
  }
}
