import { Component, EventEmitter, Inject, InjectionToken, Input, OnInit, Optional, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { PricingPlan } from 'src/app/components/billing/billing-types';
import { ConcretePlan } from 'src/app/pages/new-billing/models/plan.model';
import { SubscriptionModel } from 'src/app/pages/new-billing/models/subscription.model';
import { LegacyPricingPlans, PricePeriodicity } from 'src/app/pages/new-billing/types/plan.types';
import { ExperimentsService } from 'src/app/services/experiments/experiments.service';


type CardPlanStates = {
  [key in LegacyPricingPlans<'annual' | 'monthly'>]: {
    downgrade: PricingPlan[];
    upgrade: PricingPlan[];
  }
};

export type PlanCardClickEvent = {
  plan: ConcretePlan;
  actionType: 'downgrade' | 'upgrade' | 'current';
};

type BadgeType = 'popular' | 'experimentPopular' | 'current' | null;

interface BadgeInfo {
  className: string;
  translationKey: string;
}

@UntilDestroy()
@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss'],
})
export class PlanCardComponent implements OnInit {

  @Input()
  currentPlan: ConcretePlan;

  @Input()
  plan: ConcretePlan;

  @Input()
  get payRecurrence(): PricePeriodicity {
    return this.rawPayRecurrence;
  }

  set payRecurrence(value: PricePeriodicity) {
    this.rawPayRecurrence = value;
    this.cardPlanName = determineCardPlanName(this.plan.name, value);
    this.badgeInfo$.next(this.calculateBadgeInfo());
  }

  @Input()
  showPopularBadge: boolean;

  @Output()
  planCardBtnClick = new EventEmitter<PlanCardClickEvent>();

  private rawPayRecurrence: PricePeriodicity;

  isListExpanded = false;
  cardPlanName: LegacyPricingPlans;
  subscription: SubscriptionModel;

  get isAddCardExperiment() {
    return this.experimentsService.isAddCardExperiment;
  }

  badgeInfo$ = new BehaviorSubject<BadgeInfo | null>(null);

  get hasCard(): boolean {
    return !!this.currentPlan.billingApiData.billingDetails.cardLastFourDigits;
  }

  get isCurrentPlan(): boolean {
    return this.determinePlanState() === 'current';
  }

  get isShowAddCardButton(): boolean {
    return this.isCurrentPlan && !this.hasCard;
  }

  get isCurrentPlanOrNoCard(): boolean {
    return !this.isCurrentPlan || (this.isCurrentPlan && !this.hasCard);
  }

  get isCurrentPlanOrAddCard(): boolean {
    return this.isCurrentPlan || this.isShowAddCardButton;
  }

  get isChoosePlanButton(): boolean {
    return !this.isCurrentPlan && !this.isShowAddCardButton;
  }

  get isCurrentPlanWithCard(): boolean {
    return this.isCurrentPlan && this.hasCard;
  }

  get buttonState(): 'addCard' | 'nextPayment' | 'choosePlan' {
    if (this.isCurrentPlan || this.isShowAddCardButton) {
      return !this.hasCard ? 'addCard' : 'nextPayment';
    }
    return 'choosePlan';
  }

  constructor(
    private translate: TranslateService,
    private readonly experimentsService: ExperimentsService,
    @Inject(USE_CHOOSE_PLAN_INJECTION_TOKEN) @Optional() public useChoosePlan?: boolean,
  ) {}

  ngOnInit() {
    // Calculate once and store the result
    this.badgeInfo$.next(this.calculateBadgeInfo());
  }

  public determinePlanState(): 'downgrade' | 'upgrade' | 'current' {
    this.subscription = this.currentPlan.subscription;

    if (this.cardPlanName === this.currentPlan.name) return 'current';

    if (planStates[this.currentPlan.name].downgrade.includes(this.cardPlanName)) return 'downgrade';
    if (planStates[this.currentPlan.name].upgrade.includes(this.cardPlanName)) return 'upgrade';
  }

  public priceToDisplay(): string {
    if (this.payRecurrence === 'year') {
      // Adjust the annual price with a small 0.5 value so that the monthly price is always rounded up
      const yearPrice = this.plan.pricePerRecurrence * 10 + 0.5;
      return (yearPrice / 12).toFixed(1);
    }

    return this.plan.pricePerRecurrence.toString();
  }

  public handlePlanCardBtnClick() {
    const plan = this.plan.clone();
    plan.switchPlanRecurrence(this.payRecurrence);

    this.planCardBtnClick.emit({
      plan,
      actionType: this.determinePlanState(),
    });
  }

  public choosePlanTrackData() {
    return {
      page: this.isAddCardExperiment && this.isShowAddCardButton
        ? 'Add credit card'
        : 'Clicked Choose Plan',
      terms: this.payRecurrence === 'year' ? 'annually' : 'monthly',
      plan: this.plan.productId,
      price: this.plan.unitPrice,
    };
  }

  public get recurrenceMessage(): string {
    return this.payRecurrence === 'year' ? 'billing.billedAnnual' : 'billing.billedMonthly';
  }

  public get recurrenceMessageEachPlan(): string {
    const allPlanTitle = this.translate.instant('billing.AllPlans') + ' ';
    const prevPlan = this.translate.instant('billing.' + this.plan.tierUnderPlan + 'Plan') + ' plus:';
    return this.plan.hasFeaturesInOtherPlan() ? allPlanTitle + prevPlan : 'billing.basicPlanIncludes';
  }

  private calculateBadgeInfo(): BadgeInfo {
    const type = this.getBadgeType();

    if (!type) {
      return { className: '', translationKey: '' };
    }

    const badgeConfig: Record<BadgeType, BadgeInfo> = {
      popular: { className: 'popular-badge', translationKey: 'billing.popular' },
      experimentPopular: { className: 'add-card-experiment', translationKey: 'billing.popular' },
      current: { className: 'current-plan-badge', translationKey: 'billing.currentPlan' },
    };

    return badgeConfig[type];
  }

  getBadgeType(): BadgeType {
    if (!this.isAddCardExperiment && this.showPopularBadge) {
      return 'popular';
    }

    if (this.isAddCardExperiment && this.showPopularBadge && !this.isCurrentPlan) {
      return 'experimentPopular';
    }

    if (this.isAddCardExperiment && this.isCurrentPlan) {
      return 'current';
    }

    return null;
  }
}

const planStates: CardPlanStates = {
  basic_plan: {
    downgrade: [],
    upgrade: ['basic_plan_annual', 'standard_plan', 'standard_plan_annual', 'premium_plan', 'premium_plan_annual'],
  },
  basic_plan_annual: {
    downgrade: ['basic_plan'],
    upgrade: ['standard_plan', 'standard_plan_annual', 'premium_plan', 'premium_plan_annual'],
  },
  standard_plan: {
    downgrade: ['basic_plan', 'basic_plan_annual'],
    upgrade: ['standard_plan_annual', 'premium_plan', 'premium_plan_annual'],
  },
  standard_plan_annual: {
    downgrade: ['basic_plan', 'basic_plan_annual', 'standard_plan'],
    upgrade: ['premium_plan', 'premium_plan_annual'],
  },
  premium_plan: {
    downgrade: ['basic_plan', 'basic_plan_annual', 'standard_plan', 'standard_plan_annual'],
    upgrade: ['premium_plan_annual'],
  },
  premium_plan_annual: {
    downgrade: ['basic_plan', 'basic_plan_annual', 'standard_plan', 'standard_plan_annual', 'premium_plan'],
    upgrade: [],
  },
};

export const determineCardPlanName = (planName: LegacyPricingPlans, payRecurrence: PricePeriodicity) => {
  const planSuffix = payRecurrence === 'year' ? '_annual' : '';
  return `${planName}${planSuffix}` as LegacyPricingPlans;
};

export const USE_CHOOSE_PLAN_INJECTION_TOKEN = new InjectionToken<boolean>('USE_CHOOSE_PLAN_INJECTOR');
