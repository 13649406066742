import { PlanId } from 'src/app/pages/new-billing/types/api.types';
import { LegacyPricingPlans } from 'src/app/pages/new-billing/types/plan.types';
import { EditTimeType, LastSeen, LastTrack, ReportType, UserRole } from './common';
import { IntegrationId } from './task';

export type PricingPlan =
  | PlanId
  | LegacyPricingPlans
  | 'premium_annual'
  | 'premium'
  | 'premium_july20'
  | 'standard_new_annual'
  | 'standard_new'
  | 'standard_july20'
  | 'basic'
  | 'basic_annual'
  | 'basic_new'
  | 'basic_new_annual'
  | 'basic_july20'
  | 'business'
  | 'business_annual'
  | 'enterprise'
  | 'enterprise_annual'
  | '10peruserpermonth'
  | 'resellers';

export interface CustomUserSettings {
  visitedNotification?: {
    [key: string]: boolean;
  };
  browserExtensionEnabled?: boolean | 'per-user';
  allowedReports?: {
    [key in ReportType]?: boolean;
  };
  unsubscribedFromEmails?: boolean;
  collapseOnboardingChecklist?: boolean;
  gettingStartedExperimentVariant?: string;
}

export enum WebAppTrackingMode {
  Off = 'off',
  Basic = 'basic',
  Extended = 'extended',
  Custom = 'custom',
}

export interface BrowserExtensionSettings {
  integrationSettings?: {
    [key in IntegrationId]: {
      integrationMapping?: number;
    };
  };
}

export type ScreenshotAccess = 'none' | 'owner' | 'owner_admin' | 'owner_manager' | 'owner_admin_manager';

export interface CustomCompanySettings extends CustomUserSettings {
  splitTest?: { [key: string]: string };
  welcomeSidebarCompleted?: boolean;
  dismissedStep?: {
    [key: string]: boolean;
  };
  completedStep?: {
    [key: string]: boolean;
  };
  completedOnBoarding?: boolean | 'yes';
  browserExtensionSettings?: BrowserExtensionSettings;
  hasInviteLink?: boolean;
  dismissedBanners?: {
    [key: string]: boolean;
  };
  trackedSteps?: {
    [key: string]: boolean;
  };
  gettingStartedExperimentVariant?: string;
}

interface AutoApproveManualTimeSettings {
  autoApproveManualTimeThresholdSeconds: number;
}

export interface CompanySettings {
  active: boolean;
  allowAdminSID: boolean;
  allowDeleteScreenshots: boolean;
  allowEditTime: boolean;
  allowManagerTagCategories: boolean;
  allowManagerInviteUsers: boolean;
  allowManagerProjectsTasks: boolean;
  allowManagerWorkSchedules: boolean;
  allowUsersActivitySummaryReport: boolean;
  allowManagersExecutiveDashboard: boolean;
  allowLeavesForRegularUsers: boolean;
  allowRegularUsersSetUpTheirShifts: boolean;
  allowUsersUpdateEmail: boolean;
  ssoOnlyAuth: boolean;
  justInTimeProvisioningDisabled: boolean;
  allowNoBreak: boolean;
  autoApproveManualTimeConfiguration?: AutoApproveManualTimeSettings | null;
  disableRegularUsersScreencasts: boolean;
  blurScreenshots: boolean;
  canEditTime?: EditTimeType;
  custom: CustomCompanySettings;
  emailReports: string;
  weeklyEmailReports?: boolean;
  employeeId: string;
  exists: boolean;
  firstDayOfWeek: number;
  forceAutostart: boolean;
  trackConnectivity: boolean;
  hideScreencasts: boolean;
  name: string;
  payrollAccess: boolean;
  payrollFeature: boolean;
  workScheduleFeature: boolean;
  allowBreakLimit: boolean;
  billingAccess: boolean;
  poorTimeusePopup: boolean;
  screencastsFeature: boolean;
  screenshots: string | number; // seconds
  showOnReports: boolean;
  signupStep: number;
  stripUrlQuery: boolean;
  tasksMode: 'preset' | 'off';
  trackingMode: 'interactive' | 'silent' | 'mixed';
  trackingType: 'presetTasks' | 'noTasks';
  interactiveTrackingMode?: 'automated' | 'manual';
  videos: 'on' | 'off';
  webAndAppTracking: WebAppTrackingMode;
  workCheckInterval: string | number;
  worklife: WorkLife;
  whoCanAccessScreenshots: ScreenshotAccess;
  jobTitle?: string;
  contractionBlocker?: CompanyContractionBlockerSettings;
  disableReportsAccess: boolean;
  emailSubCount?: number;
  forceSsoOTP?: boolean;
  disableManualUserProvisioning?: boolean;
  mobileAppTracking?: 'on' | 'off' | 'user';
}

export interface CompanySubscription {
  expires: string;
  plan: 'paid' | 'free';
  status: 'paid' | 'free' | 'trial';
  data?: {
    orgId?: '903a8d67-f04b-4fcb-ba2d-32588bd1ae44';
    oAuthClientId?: '936106ca-1993-49f6-96de-de7fae50a090';
    oAuthClientSecret?: string;
    requestorId?: string;
  };
  provider?: 'genesys' | 'stripe' | 'paddle';
}

export interface GlobalSettings {
  maxGroups?: number;
  beta?: boolean;
}

export interface AuthCompany {
  id: string;
  oldCompanyId: string;
  allUsersTagId: string;
  prefix: string;
  name: string;
  role: UserRole;
  timezone: string;
  companyOwnerEmail: string;
  companyTimezone: string;
  time: string;
  hasManagedScreencasts: boolean;
  hasManagedApprovals: boolean;
  everHadBreak: boolean;
  locked: boolean;

  lastTrack: LastTrack;
  lastSeen: LastSeen;
  userCount: number;
  historicalMaxUserCount: number;
  companyCreatedAt: string;
  tagIds: string[];
  onlyDomainsFor: string[];
  subscription: CompanySubscription;
  minBillableUsers: number;

  companySettings: CompanySettings;
  userSettings: CompanySettings;
  /** @deprecated Use companySettings or userSettings instead. */
  settings: CompanySettings;
  pricingPlan?: PricingPlan;
  billingChangeDate?: string;
  globalSettings?: GlobalSettings;
  splitTest: { name: string, value: any }[];
  silentTrackingTimes: SilentTrackingTimes;
  oauth?: {
    enabled?: boolean;
    provider?: SsoProviderType;
  };
  inviteLink?: string;
}

export interface Company {
  id: string;
  name: string;
  createdAt: string;
  creatorId: string;
  parentId: string;
  oldCompanyId: string;
  noWorkspaces: boolean;
  description: string;
  timezone: string;
  locked: boolean;
  userCount: number;
  historicalMaxUserCount: number;
  userDiscount: number;
  uniqueUserCount: number;
  minBillableUsers: number;
  allUsersTagId: string;
  subscription: CompanySubscription;
  settings: CompanySettings;
  splitTest: { name: string, value: any }[];
}

export enum SsoProviderType {
  Okta = 'okta',
  Azure = 'azure',
  WorkOS = 'workos',
  Google = 'google',
}

export interface OAuthCompany {
  provider: SsoProviderType;
  company: string;
  issuer: string;
  clientId: string;
}

export interface SsoProviderParams {
  domain: string[];
  issuer: string;
  clientId: string;
  clientSecret: string;
  redirectURI?: string;
}

export interface SilentTrackingTimes {
  days?: number[];
  hours?: string[][];
  timezone?: string;
}

export interface WorkLife {
  tooManyHours: WorkLifeObject;
  lateHoursAfter: WorkLifeObject;
  hoursOutsideShiftWork: WorkLifeObject;
  weekendWork: WorkLifeObject;
}
export interface WorkLifeObject {
  included: boolean;
  value?: number;
}

export interface CompanyContractionBlockerSettings {
  enabled?: boolean;
  manualLimit: number;
  limit: number;
}
