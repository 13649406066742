<!-- Title Section (Hidden if not provided) -->
<div *ngIf="title"
     fxLayout="row wrap"
     [fxLayoutAlign]="alignment"
     style="" class="title">
  {{ title }}
</div>

<!-- Logos Section -->
<div class="logo-section" fxLayout="row wrap" [fxLayoutAlign]="alignment" fxLayoutGap="24px" [style.padding]="logoPadding" [style.opacity]="opacity">
  <img *ngFor="let logo of logos"
       [src]="'/assets/images/billing/social-proof-section/' + logo.filename"
       [alt]="logo.alt"
       [style.width]="logo.width"
       [style.height]="logo.height"
       [class.social-proof-logos]="applySocialProofClass">
</div>
