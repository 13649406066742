import { Injector } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { combineLatest, tap } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { PlanCardClickEvent } from 'src/app/components/new-billing/plan-card/plan-card.component';
import { ConcretePlan } from 'src/app/pages/new-billing/models/plan.model';
import { UpdateBillingDetails } from 'src/app/pages/new-billing/store/actions';
import { BillingDetailsApiData } from 'src/app/pages/new-billing/types/api.types';
import { PricePeriodicity } from 'src/app/pages/new-billing/types/plan.types';
import {
  UpgradeBillingResult
} from 'src/app/pages/new-billing/upgrade-billing-details/upgrade-billing-details.component';
import { BillingActionsService } from 'src/app/services/billing/billing-actions.service';
import { BillingService } from 'src/app/services/billing/billing.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { CompanySettingsChanged } from 'src/app/store/auth/auth.actions';
import { PricingPlan, WebAppTrackingMode } from 'src/models';


type DialogGenericType = {
  isLoadingAction: boolean;
  errorMsg: string;
};

export type DialogOptions = {
  actionType: PlanCardClickEvent['actionType'];
  hasFixedRecurrence?: boolean;
  paymentRecurrence?: PricePeriodicity;
};

export abstract class UpgradeDowngradeBaseModel {
  public abstract openDialog(
    cardPlan: ConcretePlan,
    options: DialogOptions,
  ): MatDialogRef<DialogGenericType, UpgradeBillingResult>;

  public abstract handleError(err: unknown, cardPlan: ConcretePlan): void;

  protected dialogRef: MatDialogRef<DialogGenericType, UpgradeBillingResult>;

  protected billingActions: BillingActionsService;
  protected billingService: BillingService;
  protected companyService: CompanyService;
  protected matDialog: MatDialog;
  protected store: Store;
  protected translateService: TranslateService;
  protected growthBookService: GrowthBookService;

  constructor(
    injector: Injector,
  ) {
    this.billingActions = injector.get(BillingActionsService);
    this.billingService = injector.get(BillingService);
    this.companyService = injector.get(CompanyService);
    this.growthBookService = injector.get(GrowthBookService);
    this.matDialog = injector.get(MatDialog);
    this.store = injector.get(Store);
    this.translateService = injector.get(TranslateService);
  }

  protected handleSwitchPlan(cardPlan: ConcretePlan, actionType: PlanCardClickEvent['actionType']) {
    this.dialogRef.componentInstance.errorMsg = null;
    this.dialogRef.componentInstance.isLoadingAction = true;
    const switchCompanyPlan$ = actionType === 'upgrade'
      ? this.companyService.switchCurrentCompanyPlan(cardPlan.planId)
      : Promise.resolve();

    this.billingService.modifySubscriptionPlan({ planId: cardPlan.planId }).pipe(
      take(1),
      switchMap(() => combineLatest([
        this.billingService.getBillingDetails('no-cache'),
        switchCompanyPlan$,
      ])),
      tap(() => this.dialogRef.componentInstance.isLoadingAction = false),
    ).subscribe({
      next: ([billingDetails]) => {
        this.handleSuccess(billingDetails);
        this.dialogRef.close({ didChangePlan: true });
      },
      error: (err: unknown) => {
        this.dialogRef.componentInstance.isLoadingAction = false;
        this.handleError(err, cardPlan);
      },
      complete: () => this.dialogRef.componentInstance.isLoadingAction = false,
    });
  }

  protected handleSuccess(billingDetails: BillingDetailsApiData) {
    this.store.dispatch(new UpdateBillingDetails(billingDetails));
    this.updateStoreCompanySettings(billingDetails);
    this.dialogRef.close({ didChangePlan: true });
  }

  private getWebAndAppTrackingByPlan(pricingPlan: string) {
    if (pricingPlan.includes('basic')) {
      return { webAndAppTracking: WebAppTrackingMode.Off };
    }
    return { webAndAppTracking: WebAppTrackingMode.Extended };
  }

  private updateStoreCompanySettings(billingDetails: BillingDetailsApiData): void {
    const pricingPlan = billingDetails.currentPlanId as PricingPlan;
    const companySettings = this.getWebAndAppTrackingByPlan(pricingPlan);

    this.store.dispatch(new CompanySettingsChanged(
      { pricingPlan },
      companySettings,
      null,
    ));
  }
}



