<app-billing-detail-base-item *ngIf="!plan.paymentModel.cardLast4; else cardAdded">
  <div left-column>
    {{ label | translate }}
  </div>
  <div right-column>
    <button
      mat-button
      [ngClass]="{
        'add-credit-card-btn': true,
        'mat-primary': isAddCardExperiment,
        'mat-raised-button': isAddCardExperiment
      }"
      (click)="handleAddCreditCardBtnClick()"
      [trackClick]="addCreditCardTrackData()"
    >
      <mat-icon>add</mat-icon>
      {{ isAddCardExperiment ? ('billing.addCard' | translate | uppercase) : ('billing.addCard' | translate) }}
    </button>
  </div>
</app-billing-detail-base-item>

<ng-template #cardAdded>
  <app-actionable-item
    [label]="label | translate"
    [content]="'**** **** **** ' + plan.paymentModel.cardLast4"
    [actionableIcon]="canUpdateCard ? 'edit' : null"
    (actionClick)="handleEditCreditCard()"
    billing-detail-item
  ></app-actionable-item>
</ng-template>
