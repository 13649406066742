import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-theme-change-modal',
  templateUrl: './theme-change-modal.component.html',
  styleUrls: ['./theme-change-modal.component.scss'],
  animations: [
    trigger('slideOverlay', [
      state('void', style({
        clipPath: 'inset(0 100% 0 0)',
      })),
      state('visible', style({
        clipPath: 'inset(0 0 0 0)',
      })),
      transition('void => visible', [
        style({ clipPath: 'inset(0 100% 0 0)' }),
        animate('3000ms ease-in-out'),
      ]),
    ]),
  ],
})
export class ThemeChangeModalComponent implements OnChanges {

  @Input() isLoading = false;
  @Output() closeModal = new EventEmitter<void>();

  animationState = 'void';
  private imagesLoaded = 0;
  private readonly totalImages = 2;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isLoading']?.currentValue === false && this.imagesLoaded === this.totalImages) {
      this.startAnimation();
    }
  }

  onImageLoad() {
    this.imagesLoaded++;
    if (this.imagesLoaded === this.totalImages && !this.isLoading) {
      this.startAnimation();
    }
  }

  private startAnimation() {
    setTimeout(() => {
      this.animationState = 'visible';
    }, 2000);
  }

  onGotItClick(): void {
    this.closeModal.emit();
  }
}
