import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, LayoutGapStyleBuilder } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AnnouncekitModule } from 'announcekit-angular';
import { AvatarModule } from 'ngx-avatar';
import { AddonsPageComponent } from 'src/app/components/addons-page/addons-page.component';
import {
  CopyToClipboardFieldComponent
} from 'src/app/components/copy-to-clipboard-field/copy-to-clipboard-field.component';
import { TrialWidgetComponent } from 'src/app/components/starter-sidebar/trial-widget/trial-widget.component';
import { YoutubePlayerComponent } from 'src/app/components/youtube-player/youtube-player.component';
import { OutsideClickDirective } from 'src/app/directives/outside-click.directive';
import { TypeSafeMatCellDefDirective } from 'src/app/directives/type-safe-mat-cell.directive';
import { AnnounceButtonComponent } from '../components/announce-button/announce-button.component';
import { SharedAvatarComponent } from '../components/avatar/avatar.component';
import { BackHeaderComponent } from '../components/back-header/back-header.component';
import { BadgeComponent } from '../components/badge/badge.component';
import { CopyToClipboardComponent } from '../components/copy-to-clipboard/copy-to-clipboard.component';
import { DailyTimetubeComponent } from '../components/daily-timetube/daily-timetube.component';
import { EmptyStateComponent } from '../components/empty-state/empty-state.component';
import { EmptyComponent } from '../components/empty/empty.component';
import { ExportDocButtonComponent } from '../components/export-doc-button/component';
import { ExportOptionsButtonComponent } from '../components/export-options-button/export-options-button.component';
import { InfoTooltipComponent } from '../components/info-tooltip/info-tooltip.component';
import { InlineEditComponent } from '../components/inline-edit/inline-edit.component';
import { LargeNumberTextComponent } from '../components/large-number-text/large-number-text.component';
import {
  VariableVirtualScrollDirective,
  VariableVirtualScrollItemDirective
} from '../components/lazy-list/virtual-scroll-variable';
import { LinkComponent } from '../components/link/link.component';
import { LogoutComponent } from '../components/logout/component';
import { PdfReportHeaderComponent } from '../components/pdf-report-header/pdf-report-header.component';
import { PercentageBarComponent } from '../components/percentage-bar/percentage-bar.component';
import { RoleSelectorComponent } from '../components/role-selector/role-selector.component';
import { SearchComponent } from '../components/search/search.component';
import { SharedBannerComponent } from '../components/shared-banner/component';
import { SidepanelComponent } from '../components/sidepanel';
import { SidepanelHeaderComponent } from '../components/sidepanel-header';
import { StatusAvatarComponent } from '../components/status-avatar/status-avatar.component';
import { TDLogoComponent } from '../components/td-logo/td-logo.component';
import { TDTaskProgressComponent } from '../components/td-task-progress/td-task-progress.component';
import { ThresholdMenuComponent } from '../components/threshold-menu/threshold-menu.component';
import { TimetubeComponent } from '../components/timetube/timetube.component';
import { TruncatedTextComponent } from '../components/truncated-text/truncated-text.component';
import { UserFilterDropdownComponent } from '../components/user-filter-dropdown/user-filter-dropdown.component';
import { UserComponent } from '../components/user/component';
import { WorklogLegendComponent } from '../components/worklog-legend/component';
import { AutoKeyHandlerDirective } from '../directives/auto-key-handler.directive';
import { AutofocusDirective } from '../directives/autofocus.directive';
import { FixedFooterDirective } from '../directives/cdk-fixed-footer.component';
import { FixedHeaderDirective } from '../directives/cdk-fixed-header.component';
import { CustomLayoutGapStyleBuilder } from '../directives/flex-gap-style-builder.directive';
import { FocusInvalidInputDirective } from '../directives/focus-invalid-input.directive';
import { HasHintDirective } from '../directives/has-hint.directive';
import { HasScrollbarDirective } from '../directives/has-scrollbar.directive';
import { NgIfMediaQueryDirective } from '../directives/if-media.directive';
import { LetDirective } from '../directives/let.directive';
import { MatSelectOnlyChildDirective } from '../directives/mat-select-only-child.directive';
import { MinValidator } from '../directives/min-max-validators.directive';
import { MouseMoveDirective } from '../directives/mousemove.directive';
import { RestoreScrollDirective } from '../directives/restore-scroll.directive';
import { RxModelDirective } from '../directives/rx-model/rx-model.directive';
import { ScrollIntoViewDirective } from '../directives/scroll-into-view.directive';
import { ScrolledDirective } from '../directives/scrolled.directive';
import { SkipNavigationDirective } from '../directives/skip-navigation.directive';
import { ClickStopPropagationDirective } from '../directives/stop-propagation.directive';
import { SubmitDirective } from '../directives/submit.directive';
import { TypedTemplateDirective } from '../directives/typed-template.directive';
import { VarDirective } from '../directives/var.directive';
import { VerticalTabsDirective } from '../directives/vertical-tabs.directive';
import { WatchSizeDirective } from '../directives/watch-size.directive';
import { CapitalizePipe } from '../pipes/capitalize';
import { ClockPipe } from '../pipes/clock';
import { ClockRangePipe } from '../pipes/clock-range/clock-range.pipe';
import { DurationPipe } from '../pipes/duration';
import { DurationHmsPipe } from '../pipes/duration-hms';
import { DurationHumanizePipe } from '../pipes/duration-long/duration-humanize.pipe';
import { ExtendedDatePipe } from '../pipes/extended-date/extended-date.pipe';
import { FirstNamePipe } from '../pipes/first-name';
import { HumanizePipe } from '../pipes/humanize';
import { LogPipe } from '../pipes/log';
import { SafePipe } from '../pipes/safe/safe.pipe';
import { TranslateCutPipe } from '../pipes/translate-cut';
import { TranslatexPipe } from '../pipes/translatex';
import { NgIfFeatureDirective } from '../services/growthbook/directive';
import { TrackingModule } from '../services/tracking/module';
import { CustomBreakPointsProvider, CustomShowHideDirective } from '../util/responsive';
import { MaterialModule } from './material.module';


const modules = [
  CommonModule,
  FlexLayoutModule,
  TranslateModule,
  FormsModule,
  MaterialModule,
  ReactiveFormsModule,
  RouterModule,
  TrackingModule,
  AnnouncekitModule,
  AvatarModule,
] as const;

const components = [
  BadgeComponent,
  SharedBannerComponent,
  SharedAvatarComponent,
  EmptyComponent,
  LogoutComponent,
  SearchComponent,
  InfoTooltipComponent,
  EmptyStateComponent,
  StatusAvatarComponent,
  CopyToClipboardComponent,
  CopyToClipboardFieldComponent,
  RoleSelectorComponent,
  TimetubeComponent,
  WorklogLegendComponent,
  DailyTimetubeComponent,
  UserFilterDropdownComponent,
  TruncatedTextComponent,
  PercentageBarComponent,
  InlineEditComponent,
  BackHeaderComponent,
  FixedHeaderDirective,
  FixedFooterDirective,
  SkipNavigationDirective,
  RxModelDirective,
  SubmitDirective,
  CustomShowHideDirective,
  ClickStopPropagationDirective,
  FocusInvalidInputDirective,
  MinValidator,
  MinValidator,
  ScrollIntoViewDirective,
  ScrolledDirective,
  WatchSizeDirective,
  ExportOptionsButtonComponent,
  ExportDocButtonComponent,
  SidepanelComponent,
  SidepanelHeaderComponent,
  UserComponent,
  ThresholdMenuComponent,
  PdfReportHeaderComponent,
  AnnounceButtonComponent,
  LinkComponent,
  LargeNumberTextComponent,
  NgIfFeatureDirective,
  TypeSafeMatCellDefDirective,
  OutsideClickDirective,
  VariableVirtualScrollDirective,
  VariableVirtualScrollItemDirective,
  TrialWidgetComponent,
  MouseMoveDirective,
  VerticalTabsDirective,
  TypedTemplateDirective,
  RestoreScrollDirective,

  VarDirective,
  LetDirective,
  AutofocusDirective,
  AutoKeyHandlerDirective,
  NgIfMediaQueryDirective,
  HasHintDirective,
  HasScrollbarDirective,
  MatSelectOnlyChildDirective,
  // Pipes

  ClockRangePipe,
  DurationHumanizePipe,
  SafePipe,
  ExtendedDatePipe,
  FirstNamePipe,
  TranslateCutPipe,
  TranslatexPipe,
  DurationPipe,
  DurationHmsPipe,
  HumanizePipe,
  ClockPipe,
  LogPipe,
  TDLogoComponent,
  AddonsPageComponent,
  YoutubePlayerComponent,
  TDTaskProgressComponent,
  CapitalizePipe,
] as const;

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    ...modules,
  ],
  exports: [
    ...modules,
    ...components,
  ],
  providers: [
    DecimalPipe,
    CustomBreakPointsProvider,
    { provide: LayoutGapStyleBuilder, useClass: CustomLayoutGapStyleBuilder },
  ],
})
export class SharedModule { }
