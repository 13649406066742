import { NgModule } from '@angular/core';
import { NavigationError, Router, RouterModule, Routes } from '@angular/router';
import { NewEndOfTrialGuard } from 'src/app/guards/end-of-trial/new-end-of-trial.guard';
import { BillingGuard } from 'src/app/pages/billing/billing.guard';
import { CheckoutSuccessGuard } from 'src/app/pages/new-subscribe-plans/checkout-success/checkout-success.guard';
import { GrowthBookResolver } from 'src/app/services/growthbook/resolver';
import { ReportType } from 'src/models';
import { EmptyComponent } from '../components/empty/empty.component';
import { ErrorComponent } from '../components/error/error.component';
import { LogoutComponent } from '../components/logout/component';
import { AuthGuard } from '../guards/auth/auth.guard';
import { BasicPricingGuard } from '../guards/basic-pricing/basic-pricing.guard';
import { CompanySelectedGuard } from '../guards/company-selected/company-selected.guard';
import { ConfirmGuard } from '../guards/confirm/confirm.guard';
import { EndOfTrialGuard } from '../guards/end-of-trial/end-of-trial.guard';
import { FirstLoginGenesysGuard } from '../guards/first-login/first-login-genesys.guard';
import { FirstLoginGuard } from '../guards/first-login/first-login.guard';
import { HomeGuard } from '../guards/home/home.guard';
import { LoadAllUsersGuard } from '../guards/load-all-users.guard';
import { MaintenanceGuard } from '../guards/maintenance/maintenance.guard';
import { MenuGuard } from '../guards/menu/menu.guard';
import { PaidProtectedGuard } from '../guards/paid-protected/paid-protected.guard';
import { PayrollGuard } from '../guards/payroll/payroll.guard';
import { SubscribePlansGuard } from '../guards/subscribe-plans/subscribe-plans.guard';
import { TokenLoginGuard } from '../guards/token-login/token-login.guard';
import { BillingResolver } from '../services/billing/billing.resolver';
import { growthbookMatcher } from '../services/growthbook/matcher';

const routes: Routes = [
  {
    path: 'new',
    redirectTo: '',
  },
  {
    path: 'hours-tracked-new',
    redirectTo: '/hours-tracked-v2',
  },
  {
    path: 'error',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'error/:error',
    component: ErrorComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'maintenance-mode',
    loadChildren: () => import('../pages/maintenance/maintenance.module').then(m => m.MaintenanceModule),
  },
  {
    path: 'login',
    loadChildren: () => import('../modules/login.routing').then(m => m.LoginRoutingModule),
  },
  {
    path: 'join',
    loadChildren: () => import('../pages/join/join.module').then(m => m.JoinModule),
  },
  {
    path: 'activate',
    loadChildren: () => import('../pages/activate/activate.module').then(m => m.ActivateModule),
  },
  {
    path: 'onboarding',
    canActivate: [AuthGuard, CompanySelectedGuard],
    loadChildren: () => import('../pages/onboarding/onboarding.module').then(m => m.OnboardingModule),
    data: { title: 'onboarding.title' },
  },
  {
    canActivate: [AuthGuard, SubscribePlansGuard, FirstLoginGenesysGuard, MaintenanceGuard],
    path: 'subscribe-plans',
    loadChildren: () => import('../pages/subscribe-plans/subscribe-plans.module').then(m => m.SubscribePlansModule),
    data: { title: 'subscription.title' },
  },
  {
    canActivate: [AuthGuard, FirstLoginGenesysGuard, NewEndOfTrialGuard],
    path: 'new-end-of-trial',
    loadChildren: () => import('../pages/new-billing/new-end-of-trial/new-end-of-trial.module').then(m => m.NewEndOfTrialModule),
  },
  {
    canActivate: [AuthGuard, FirstLoginGenesysGuard, EndOfTrialGuard],
    path: 'end-of-trial',
    loadChildren: () => import('../pages/end-of-trial/end-of-trial.module').then(m => m.EndOfTrialModule),
  },
  {
    canActivate: [AuthGuard, FirstLoginGenesysGuard, EndOfTrialGuard],
    path: 'eot-subscribe-plans',
    loadChildren: () => import('../pages/subscribe-plans/subscribe-plans.module').then(m => m.SubscribePlansModule),
    data: { fromEndOfTrial: true, successUrl: '/eot-subscribe-success' },
  },
  {
    canActivate: [AuthGuard, FirstLoginGenesysGuard],
    path: 'eot-subscribe-success',
    loadChildren: () => import('../pages/subscribe-plans/success/success.module').then(m => m.SubscribeSuccessModule),
    data: { fromEndOfTrial: true },
  },
  {
    canActivate: [AuthGuard, SubscribePlansGuard, FirstLoginGenesysGuard],
    path: 'new-subscribe-plans',
    loadChildren: () => import('../pages/new-subscribe-plans/new-subscribe-plans.module').then(m => m.NewSubscribePlansModule),
    data: { title: 'subscription.title' },
  },
  {
    canActivate: [CheckoutSuccessGuard],
    path: 'checkout-success',
    loadChildren: () => import('../pages/new-subscribe-plans/checkout-success/checkout-success.module').then(m => m.CheckoutSuccessModule),
  },
  {
    canActivate: [AuthGuard],
    path: 'office-vs-remote',
    loadChildren: () => import('../pages/office-vs-remote/module').then(m => m.OfficeVsRemoteReportModule),
    canMatch: [growthbookMatcher('office-vs-remote-report')],
  },
  {
    path: 'confirm',
    component: EmptyComponent,
    canActivate: [ConfirmGuard],
  },
  {
    path: 'reset-password',
    loadChildren: () => import('../pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'genesys-onboarding',
    loadChildren: () => import('../pages/genesys-onboarding/module').then(m => m.GenesysOnboardingModule),
  },
  {
    path: '',
    canActivate: [TokenLoginGuard],
    children: [{
      path: '',
      canActivate: [AuthGuard],
      children: [{
        path: '',
        canActivate: [CompanySelectedGuard],
        resolve: { billingData: BillingResolver },
        children: [
          {
            canActivate: [PaidProtectedGuard],
            path: 'profile-edit',
            loadChildren: () => import('../pages/user-profile/user-profile.module').then(m => m.UserProfileModule),
            data: { title: 'userProfile.title' },
          },
          {
            path: 'new-billing',
            loadChildren: () => import('../pages/new-billing/new-billing.module').then(m => m.NewBillingModule),
            data: { title: 'billing.title' },
            resolve: { growthbook: GrowthBookResolver },
          },
          {
            path: '',
            canActivateChild: [MenuGuard, FirstLoginGenesysGuard, MaintenanceGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                component: EmptyComponent,
                canActivate: [HomeGuard],
              },
              {
                canActivate: [FirstLoginGuard, PaidProtectedGuard],
                path: 'get-started',
                loadChildren: () => import('../pages/get-started/get-started.module').then(m => m.GetStartedModule),
              },
              {
                canActivate: [FirstLoginGuard, PaidProtectedGuard],
                path: 'dashboard',
                loadChildren: () => import('../pages/dashboard/dashboard-team/dashboard-team.module').then(m => m.DashboardTeamModule),
                data: { title: 'dashboard.titleTeam' },
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'dashboard-individual',
                loadChildren: () => import('../pages/dashboard/dashboard-individual/dashboard-individual.module').then(m => m.DashboardIndividualModule),
                data: { title: 'dashboard.titleIndividual' },
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'dashboard-executive',
                loadChildren: () => import('../pages/dashboard/dashboard-executive/dashboard-executive.module').then(m => m.DashboardExecutiveModule),
                data: { title: 'dashboard.titleExecutive' },
              },
              {
                // Admin Pages
                path: '',
                children: [
                  {
                    path: 'manage-users',
                    loadChildren: () => import('../pages/manage-users/manage-users.module').then(m => m.ManageUsersModule),
                    data: { title: 'manageUsers.title' },
                  },
                  {
                    canActivate: [PaidProtectedGuard],
                    path: 'projects-and-tasks',
                    loadChildren: () => import('../pages/manage-projects/manage-projects.module').then(m => m.ManageProjectsModule),
                    data: { title: 'manageProjects.title' },
                  },
                  {
                    canActivate: [PaidProtectedGuard],
                    path: 'manage-user-groups',
                    loadChildren: () => import('../pages/manage-groups/manage-groups.module').then(m => m.ManageGroupsModule),
                    data: { title: 'manageGroups.title' },
                  },
                  {
                    canActivate: [PaidProtectedGuard],
                    path: 'invite',
                    loadChildren: () => import('../pages/invite/invite.module').then(m => m.InviteModule),
                    data: { title: 'invite.title' },
                  },
                  {
                    canActivate: [PaidProtectedGuard],
                    path: 'add-managers',
                    loadChildren: () => import('../pages/add-managers/add-managers.module').then(m => m.AddManagersModule),
                    data: { title: 'addManagers.title' },
                  },
                  {
                    canActivate: [PaidProtectedGuard],
                    path: 'notifications',
                    loadChildren: () => import('../pages/notifications/module').then(m => m.NotificationsModule),
                    data: { title: 'notifications.title' },
                  },
                  {
                    canActivate: [PaidProtectedGuard, LoadAllUsersGuard],
                    path: 'company',
                    loadChildren: () => import('../pages/company-settings/company-settings.module').then(m => m.CompanySettingsModule),
                  },
                  {
                    canActivate: [PaidProtectedGuard],
                    path: 'schedules',
                    loadChildren: () => import('../pages/schedules/module').then(m => m.SchedulesModule),
                    data: { title: 'schedules.title' },
                  },
                  {
                    canActivate: [EndOfTrialGuard, BillingGuard],
                    path: 'billing',
                    loadChildren: () => import('../pages/billing/billing.module').then(m => m.BillingModule),
                    data: { title: 'billing.title' },
                  },
                  {
                    canActivate: [PaidProtectedGuard],
                    path: 'productivity-ratings',
                    loadChildren: () => import('../pages/productivity-ratings/productivity-ratings.module')
                      .then(m => m.ProductivityRatingsModule),
                    data: { title: 'productivityRating.title' },
                  },
                  {
                    canActivate: [PaidProtectedGuard, BasicPricingGuard],
                    path: 'breaks',
                    loadChildren: () => import('../pages/breaks/breaks.module').then(m => m.BreaksModule),
                    data: { title: 'breaks.title' },
                  },
                  {
                    canActivate: [PaidProtectedGuard],
                    path: 'approvals',
                    loadChildren: () => import('../pages/approvals/approvals.module').then(m => m.ApprovalsModule),
                    data: { title: 'approvals.title' },
                  },
                ],
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'hours-tracked',
                loadChildren: () => import('../pages/hours-tracked-new/module').then(x => x.HoursTrackedModule),
                data: { title: 'hoursTracked.title', reportId: 'hoursTracked' as ReportType },
                canMatch: [growthbookMatcher('new-hours-tracked-report')],
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'hours-tracked-v2',
                loadChildren: () => import('../pages/hours-tracked-new/module').then(x => x.HoursTrackedModule),
                data: { title: 'hoursTracked.title', reportId: 'hoursTracked' as ReportType },
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'projects-report',
                loadChildren: () => import('../pages/projects-report-new/module').then(x => x.ProjectsReportModule),
                data: { title: 'projects.title', reportId: 'projectsAndTasks' as ReportType },
                canMatch: [growthbookMatcher('new-projects-report')],
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'projects-report-v2',
                loadChildren: () => import('../pages/projects-report-new/module').then(x => x.ProjectsReportModule),
                data: { title: 'projects.title', reportId: 'projectsAndTasks' as ReportType },
              },
              {
                // Reports
                path: '',
                canActivateChild: [PaidProtectedGuard, LoadAllUsersGuard],
                children: [
                  {
                    path: 'timeline',
                    loadChildren: () => import('../pages/timeline/module').then(m => m.TimelineReportModule),
                    data: { title: 'timeline.title', reportId: 'timeline' as ReportType },
                  },
                  {
                    path: 'activity-summary',
                    loadChildren: () => import('../pages/activity-summary/activity-summary.module').then(m => m.ActivitySummaryModule),
                    data: { title: 'activitySummary.title', reportId: 'activitySummary' as ReportType },
                  },
                  {
                    path: 'hours-tracked',
                    loadChildren: () => import('../pages/hours-tracked/hours-tracked.module').then(m => m.HoursTrackedModule),
                    data: { title: 'hoursTracked.title', reportId: 'hoursTracked' as ReportType },
                  },
                  {
                    path: 'hours-tracked-v1',
                    loadChildren: () => import('../pages/hours-tracked/hours-tracked.module').then(m => m.HoursTrackedModule),
                    data: { title: 'hoursTracked.title', reportId: 'hoursTracked' as ReportType },
                  },
                  {
                    path: 'projects-report',
                    loadChildren: () => import('../pages/projects-report/projects-report.module').then(m => m.ProjectsReportModule),
                    data: { title: 'projects.title', reportId: 'projectsAndTasks' as ReportType },
                  },
                  {
                    path: 'projects-report-v1',
                    loadChildren: () => import('../pages/projects-report/projects-report.module').then(m => m.ProjectsReportModule),
                    data: { title: 'projects.title', reportId: 'projectsAndTasks' as ReportType },
                  },
                ],
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'unusual-activity',
                loadChildren: () => import('../pages/unusual-activity/module').then(m => m.UnusualActivityModule),
                data: { title: 'unusualActivity.title', reportId: 'unusualActivity' as ReportType },
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'screencasts',
                loadChildren: () => import('../pages/screencasts/screencasts.module').then(m => m.ScreencastsModule),
                data: { title: 'screencasts.title', reportId: 'screencasts' as ReportType },
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'web-app-usage',
                loadChildren: () => import('../pages/productivity/productivity.module').then(m => m.ProductivityReportModule),
                data: { title: 'productivity.title', reportId: 'webAndAppUsage' as ReportType },
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'internet-report',
                loadChildren: () => import('../pages/internet-report/internet-report.module').then(m => m.InternetConnectivityModule),
                data: { title: 'connectivity.title', reportId: 'connectivity' as ReportType },
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'benchmarks',
                loadChildren: () => import('../pages/benchmarking/benchmarking.module').then(m => m.BenchmarkingModule),
                data: { title: 'benchmarking.title', reportId: 'benchmarks' as ReportType },
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'experimental-reports',
                loadChildren: () => import('../pages/experimental-reports/module').then(m => m.ExperimentalReportsModule),
                data: { title: 'experimental.title', reportId: 'experimental' },
              },
              {
                // Same route as web-app-usage but included for backward-compatibility
                canActivate: [PaidProtectedGuard],
                path: 'productivity',
                loadChildren: () => import('../pages/productivity/productivity.module').then(m => m.ProductivityReportModule),
                data: { title: 'productivity.title', reportId: 'webAndAppUsage' as ReportType },
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'csv',
                loadChildren: () => import('../pages/csv/csv.module').then(m => m.CsvModule),
                data: { title: 'csv.title', reportId: 'csv' as ReportType },
              },
              {
                path: 'downloads',
                loadChildren: () => import('../pages/downloads/downloads.module').then(m => m.DownloadsModule),
                data: { title: 'download.title' },
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'integrations',
                loadChildren: () => import('../pages/integrations/integrations.module').then(m => m.IntegrationsModule),
                data: { title: 'integrations.title' },
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'edit-time',
                loadChildren: () => import('../pages/edit-time/edit-time.module').then(m => m.EditTimeModule),
                data: { title: 'editTime.title' },
              },
              {
                canActivate: [PaidProtectedGuard, BasicPricingGuard, PayrollGuard],
                path: 'payroll',
                loadChildren: () => import('../pages/payroll/payroll.module').then(m => m.PayrollModule),
                data: { title: 'payroll.title' },
              },
              {
                canActivate: [PaidProtectedGuard],
                path: 'attendance',
                loadChildren: () => import('../pages/attendance/module').then(m => m.AttendanceModule),
                data: { title: 'attendance.title', reportId: 'attendance' as ReportType },
              },
            ],
          }],
      },
      ],
    }],
  },
  {
    path: '**',
    component: ErrorComponent,
    data: { error: 'pageNotFound' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private router: Router) {

    const prevErrorHandler = this.router.errorHandler;
    this.router.errorHandler = (err: any) => {
      if (err.name === 'ChunkLoadError') {
        return undefined;
      }

      return prevErrorHandler.call(this.router, err);
    };

    this.router.events.subscribe(err => {
      if (err instanceof NavigationError) {
        if (err.error.name === 'ChunkLoadError') {
          window.location.href = err.url;
        }
      }
    });
  }
}
