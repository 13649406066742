import { Injectable } from '@angular/core';
import { GrowthBookService } from '../growthbook/service';
import { ExperimentProps } from '../growthbook/types';

@Injectable({
  providedIn: 'root',
})
export class ExperimentsService {
  constructor(private readonly growthBook: GrowthBookService) {
  }

  get isAddCardExperiment(): boolean {
    const feature = this.growthBook.getFeatureValue<ExperimentProps>('choose-your-plan-usability-exp');
    return feature?.variationid === 'B';
  }

  get showInteractiveTour(): boolean {
    const feature = this.growthBook.getFeatureValue<ExperimentProps>('onboarding-interactive-tour-exp');
    return feature?.variationid === 'B';
  }
}
