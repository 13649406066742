<div style="position: relative">
  <div class="loader-overlay" *ngIf="status === 'loading' && withOverlayLoader">
    <mat-spinner class="absolute-centered" diameter="50"></mat-spinner>
  </div>

  <button mat-icon-button mat-dialog-close class="close-btn" aria-label="close checkout dialog"
    [trackClick]="{page: 'Clicked X Button'}">
    <mat-icon>close</mat-icon>
  </button>

  <div class="inline-checkout-wrapper" *ngIf="status !== 'error'; else errorState">
    <div *ngIf="!dialogData.paymentFailureSummaryData; else paymentFailureSummary" class="billing-details-wrapper">
      <app-billing-details (appOutsideClick)="onOutsideClick($event)">
        <!-- Plan Name -->
        <app-actionable-item [label]="'billing.plan' | translate" [content]="'billing.' + currentPlan.name  | translate"
          billing-detail-item></app-actionable-item>

        <!-- Users in company -->
        <app-actionable-item [label]="'billing.usersInCompany' | translate"
          [content]="currentPlan.usersCount.toString()" billing-detail-item></app-actionable-item>

        <!-- Minimum billable users -->
        <app-actionable-item *ngIf="currentPlan.paymentModel.minBillableQuantity > 1"
          [content]="currentPlan.paymentModel.minBillableQuantity.toString()" billing-detail-item>
          <div class="tooltip-container" label-info>
            <span>
              {{ 'billing.minBillableUsers' | translate }}
            </span>
            <app-info [tooltip]="'billing.minBillableUsersTooltip' | translate"></app-info>
          </div>
        </app-actionable-item>

        <!-- Price -->
        <app-actionable-item [label]="'billing.price' | translate" billing-detail-item>
          <span style="text-transform: lowercase" content>
            {{ currentPlan.paymentModel.priceMonthly | currency: '$' }}
            /{{ 'common.user' | translate }}/{{ 'billing.month' | translate }}
          </span>
        </app-actionable-item>


        <!-- Total annual bill -->
        <app-actionable-item *ngIf="currentPlan.paymentModel.paymentRecurrence === 'year'"
          [label]="'billing.totalAnnualBill' | translate" billing-detail-item>
          <span style="text-transform: lowercase" content>{{ currentPlan.paymentModel.totalBillingPrice | currency: '$'
            }}</span>
        </app-actionable-item>

        <!-- Annual billing discount -->
        <app-actionable-item [label]="'billing.annualBillingDiscount' | translate" billing-detail-item>
          <div class="toggle-container" content>
            {{ currentPlan.paymentModel.annualPayDiscount * -1 | currency: '$' }}
            <div>
              <mat-slide-toggle color="primary" (change)="toggleChanges($event)"
                [checked]="currentPlan.paymentModel.paymentRecurrence === 'year'"
                [disabled]="dialogData.isUpdatingCard">
              </mat-slide-toggle>
            </div>
          </div>
        </app-actionable-item>

        <!-- Discount -->
        <app-actionable-item [label]="'billing.disc' | translate"
          *ngIf="totalsSummary?.discount || currentPlan.paymentModel.discount" billing-detail-item>
          <span style="text-transform: lowercase" content *ngIf="status !== 'updating'; else contentLoader">
            {{ ((totalsSummary?.discount ||
            (currentPlan.paymentModel.discount.type === 'percentage' || currentPlan.paymentModel.discount.type ===
            'amount_per_seat'
            ? currentPlan.paymentModel.discount.value * currentPlan.paymentModel.billableUsers
            : currentPlan.paymentModel.discount.value)) * -1) | currency: '$' }}
          </span>
        </app-actionable-item>

        <!-- Tax/VAT -->
        <app-actionable-item [label]="'billing.tax' | translate"
          *ngIf="totalsSummary?.tax || currentPlan.paymentModel.tax" billing-detail-item>
          <span style="text-transform: lowercase" content *ngIf="status !== 'updating'; else contentLoader">
            {{ (totalsSummary?.tax || currentPlan.paymentModel.tax) | currency: '$' }}
          </span>
        </app-actionable-item>

        <!-- Next payment amount -->
        <app-actionable-item [label]="'billing.nextPaymentAmount' | translate" billing-detail-item>
          <span class="next-payment-amount" content *ngIf="status !== 'updating'; else contentLoader">
            <b>
              {{ (totalsSummary?.total || currentPlan.paymentModel.nextPaymentAmount) | currency: '$' }}
            </b>
            <em *ngIf="currentPlan.paymentModel.hasAddons" class="addon-fee-label">
              {{ 'billing.includeAddons' | translate }}
            </em>
          </span>
        </app-actionable-item>
        <div footer-content
             class="discount-removal-note"
             [innerHTML]="'billing.discountRemovalNote' | translate"
             *ngIfFeature="'billing:discount_removal_companies'"
             [ngStyle]="{'color': dialogData?.socialProof ? '#585A6E' : '#8c8f99'}"
        >
        </div>

        <div footer-content *ngIf="dialogData?.socialProof" style="padding-top: 58px;">
          <app-billing-logos-section
            [opacity]="'1'"
            [logos]="companyLogos"
            alignment="start center"
            billing-detail-item
            [applySocialProofClass]="false">
          </app-billing-logos-section>
        </div>
      </app-billing-details>
    </div>

    <!--  Paddle Section-->
    <div class="checkout-column">
      <nav>
        <button [class.enabled]="isPaddleUpdated" [trackClick]="{page: 'Clicked Personal info'}" class="breadcrumb"
          (click)="breadcrumbClick()">
          <span [class.enabled]="isPaddleUpdated" class="breadcrumb-text">{{
            'billing.checkoutDialog.breadcrumb.personalInfo' | translate }}</span>
        </button>
        <ng-container *ngIf="isPaddleUpdated">
          <button class="breadcrumb">
            <span class="breadcrumb-text">{{ 'billing.checkoutDialog.breadcrumb.paymentInfo' | translate }}</span>
          </button>
        </ng-container>
      </nav>

      <!-- Here goes the Paddle checkout iframe -->
      <div class="inline-checkout-container" [attr.data-sandbox]="paymentProvider.isSandbox"></div>
    </div>
  </div>
</div>

<ng-template #errorState>
  <app-empty-state [icon]="'error'">
    <h3 title>{{ (isUnsupportedCountry ? 'billing.checkoutDialog.unsupportedCountry.title' :
      'billing.checkoutDialog.loadError.title')| translate }}</h3>
    <div description [ngClass]="{'unsupported-country': isUnsupportedCountry}" [innerHTML]="(isUnsupportedCountry ?
      'billing.checkoutDialog.unsupportedCountry.description' : 'billing.checkoutDialog.loadError.description')|
      translate"> </div>
  </app-empty-state>
</ng-template>

<ng-template #paymentFailureSummary>
  <app-payment-summary [inputData]="dialogData.paymentFailureSummaryData"></app-payment-summary>
</ng-template>

<ng-template #contentLoader>
  <mat-spinner diameter="18" content></mat-spinner>
</ng-template>
