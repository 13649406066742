import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { CheckoutEventsData } from '@paddle/paddle-js';
import { BehaviorSubject, combineLatest, lastValueFrom, take, timer } from 'rxjs';
import {
  CheckoutComponent,
  CheckoutDialogClose,
  CheckoutDialogData
} from 'src/app/components/new-billing/checkout/checkout.component';
import { AddCreditCardStep } from 'src/app/components/starter-sidebar/items/add-credit-card';
import { CLOSE_DIALOG_DELAY } from 'src/app/constants/billing.constants';
import { UpdateBillingDetails } from 'src/app/pages/new-billing/store/actions';
import { NewBillingState } from 'src/app/pages/new-billing/store/state';
import { BillingApiData, BillingDetailsApiData } from 'src/app/pages/new-billing/types/api.types';
import { BillingService } from 'src/app/services/billing/billing.service';
import { CompleteWelcomeStep } from 'src/app/store/onboarding/onboarding.actions';

type OpenCheckoutDialogOptions = {
    checkoutSuccessCallback?: CheckoutDialogData['onCheckoutSuccess'];
    socialProof?: boolean;
  }
  & Pick<CheckoutDialogData, 'currentPlan' | 'isUpdatingCard' | 'planId' | 'paymentFailureSummaryData' | 'withOverlayLoader' | 'trackingCtx'>;

export type OpenSnackbarOptions = {
  action?: string;
  duration?: number;
  message: string;
  onActionCallback?: () => void;
};

export const USERS_SNACKBAR_DURATION = 5000;

@Injectable({
  providedIn: 'root',
})
export class BillingActionsService {
  private _isSubmitting$ = new BehaviorSubject(false);

  get isSubmitting$() {
    return this._isSubmitting$.asObservable();
  }

  constructor(
    private billingService: BillingService,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private store: Store,
    private translateService: TranslateService,
  ) { }

  public setIsSubmitting(isSubmitting: boolean) {
    this._isSubmitting$.next(isSubmitting);
  }

  public async onCardUpdatedCompleted(
    { payment }: CheckoutEventsData,
    dialogRef: MatDialogRef<CheckoutComponent, Pick<BillingApiData, 'billingDetails'>>,
  ) {
    const pollCondition = (billingDetails: BillingDetailsApiData) =>
      billingDetails.cardLastFourDigits === payment.method_details.card.last4;

    const timer$ = timer(CLOSE_DIALOG_DELAY);
    const pollBillingDetails$ = this.billingService.pollBillingDetailsUntilConditionMet(pollCondition);

    combineLatest([timer$, pollBillingDetails$]).pipe(
      take(1),
    ).subscribe({
      next: ([, billingDetails]) => {
        this.store.dispatch(new CompleteWelcomeStep(AddCreditCardStep.stepId));
        this.store.dispatch(new UpdateBillingDetails(billingDetails));

        dialogRef.close({ billingDetails });
      },
      error: () => {
        console.error('Polling for billing details timed out after adding credit card');
        const rawBillingDetails = this.store.selectSnapshot(NewBillingState.updatedBillingDetails);
        const billingDetails = { ...rawBillingDetails, cardLastFourDigits: payment.method_details.card.last4 };
        this.store.dispatch(new CompleteWelcomeStep(AddCreditCardStep.stepId));
        this.store.dispatch(new UpdateBillingDetails(billingDetails));
        dialogRef.close({ billingDetails });
      },
    });
  }

  public async onUsersAdded(prevUserCount: number, invitedEmails: string[]) {
    const pollCondition = ({ quantity }: BillingDetailsApiData) => quantity > prevUserCount;

    try {
      const billingDetails = await lastValueFrom(this.billingService.pollBillingDetailsUntilConditionMet(pollCondition));
      this.store.dispatch(new UpdateBillingDetails(billingDetails));

      this.openSnackbar({
        message: this.translateService.instant('billing.inviteSuccessMsg', { invitedCount: invitedEmails.length }),
        action: this.translateService.instant('billing.goToUsers'),
        onActionCallback: () => this.router.navigate(['/manage-users']),
        duration: USERS_SNACKBAR_DURATION,
      });
    } catch (e) {
      console.error('Polling for billing details timed out after adding users');
    }
  }

  public async onCancelRequestSent() {
    const pollCondition = ({ cancellationRequest }: BillingDetailsApiData) => cancellationRequest.requestDate != null;

    try {
      const billingDetails = await lastValueFrom(this.billingService.pollBillingDetailsUntilConditionMet(pollCondition));
      this.store.dispatch(new UpdateBillingDetails(billingDetails));
    } catch (e) {
      console.error('Polling for billing details timed out after cancelling subscription');
    }
  }

  public openCheckoutDialog({
    checkoutSuccessCallback, socialProof,
    ...rest
  }: OpenCheckoutDialogOptions) {
    const onCheckoutSuccess = checkoutSuccessCallback ?? this.onCardUpdatedCompleted.bind(this);

    return this.dialog.open<CheckoutComponent, CheckoutDialogData, CheckoutDialogClose>(CheckoutComponent, {
      data: {
        onCheckoutSuccess,
        socialProof,
        ...rest,
      },
      autoFocus: false,
      panelClass: 'checkout-dialog',
    });
  }

  public openSnackbar({ action, duration = 3000, message, onActionCallback }: OpenSnackbarOptions) {
    this.matSnackBar.open(message, action, {
      duration,
      horizontalPosition: 'left',
      panelClass: 'billing-snackbar',
    })
      .onAction()
      .subscribe(() => onActionCallback?.());
  }
}
