import {
  AfterViewInit,
  Component, EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit, Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceDetector } from 'src/app/services/segment/device-detector';
import { SegmentService } from 'src/app/services/segment/segment.service';

@UntilDestroy()
@Component({
  selector: 'app-floating-widget',
  templateUrl: './floating-widget.component.html',
  styleUrls: ['./floating-widget.component.scss'],
})
export class FloatingWidgetComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() widgetWidth?: number = 380;
  @Input() widgetHeight?: number = 200;
  @Output() isWidgetDragging: EventEmitter<boolean> = new EventEmitter<boolean>();
  public position = this.getPosition();
  public isDragging = false;
  public offsetX = 0;
  public offsetY = 0;
  public isMobile = false;
  public touchMoved = false; // Track if a drag occurred
  public floatingTrialWidgetHeight = 40;
  drag = false;

  constructor(
    public deviceDetector: DeviceDetector,
    private readonly segment: SegmentService,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.deviceDetector
      .isBrowsingFromMobile()
      .pipe(untilDestroyed(this))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.adjustForMobile();
      });

    this.position = this.getPosition();
    this.segment.trackInAppEvents('Viewed Getting Started Trial Widget', { widgetmode: 'collapsed'});
  }

  getWidgetWidth(): number {
    return this.isMobile ? window.innerWidth : Math.min(window.innerWidth, 380);
  }

  getPosition() {
    return {
      top: Math.max(0, window.innerHeight - this.widgetHeight - 20),
      left: Math.max(0, window.innerWidth - this.widgetWidth - 20),
    };
  }

  ngAfterViewInit(): void {
    this.ensureInBounds();
    document.querySelector('#floating-widget-spacer')?.classList.add('enabled');
  }

  // Adjust the widget for mobile view
  public adjustForMobile(): void {
    if (this.isMobile) {
      // On mobile, make the widget full width and position it at the bottom
      this.widgetWidth = window.innerWidth;
      this.position.left = 0;
      this.position.top = window.innerHeight - this.widgetHeight - 20;
    } else {
      // On desktop, revert to default widget width
      this.widgetWidth = 380;
      this.position = this.getPosition();
    }
    this.ensureInBounds();
  }

  public ensureInBounds(): void {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    if (!this.isMobile) {
      // Only enforce bounds for desktop
      this.position.left = Math.max(0, Math.min(this.position.left, windowWidth - this.widgetWidth));
      this.position.top = Math.max(0, Math.min(this.position.top, windowHeight - this.widgetHeight));
    }
  }

  onMouseDown(event: MouseEvent): void {
    if (this.isMobile) return;
    this.isDragging = true;
    this.drag = false;

    this.offsetX = event.clientX - this.position.left;
    this.offsetY = event.clientY - this.position.top;
    event.preventDefault();
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(): void {
    this.isDragging = false;
    this.drag = false;
    this.isWidgetDragging.emit(false);
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (this.isDragging) {
      this.isWidgetDragging.emit(true);
      this.drag = true;

      const newLeft = event.clientX - this.offsetX;
      const newTop = event.clientY - this.offsetY;

      // Update position with bounds check
      this.position.left = Math.max(0, Math.min(newLeft, window.innerWidth - this.widgetWidth));
      this.position.top = Math.max(0, Math.min(newTop, window.innerHeight - this.widgetHeight));
    }
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.position.left = window.innerWidth - this.getWidgetWidth() - 20;
    this.position.top = window.innerHeight - this.widgetHeight - 20;
    this.ensureInBounds();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['widgetHeight'] || changes['widgetWidth']) {
      this.ensureInBounds();
    }
  }

  // Handle touch start
  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent): void {
    const touch = event.touches[0];
    this.isDragging = true;
    this.touchMoved = false; // Reset touchMoved state
    this.offsetX = touch.clientX - this.position.left;
    this.offsetY = touch.clientY - this.position.top;
  }

  // Handle touch move
  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent): void {
    if (this.isDragging) {
      const touch = event.touches[0];
      const newLeft = touch.clientX - this.offsetX;
      const newTop = touch.clientY - this.offsetY;

      // Update position with bounds check
      this.position.left = Math.max(0, Math.min(newLeft, window.innerWidth - this.widgetWidth));
      this.position.top = Math.max(0, Math.min(newTop, window.innerHeight - this.widgetHeight));
      this.touchMoved = true; // Mark that a move occurred
      this.isWidgetDragging.emit(true);
      event.preventDefault();
    }
  }

  // Handle touch end
  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent): void {
    if (this.isDragging && !this.touchMoved) {
      event.stopPropagation();
    }
    this.isDragging = false;
    this.isWidgetDragging.emit(false);
  }

  onTrialWidgetPanelOpened(widgetHeight: number) {
    this.floatingTrialWidgetHeight = widgetHeight;

    const availableSpaceBelow = window.innerHeight - this.position.top - this.widgetHeight;

    if (availableSpaceBelow < widgetHeight) {
      // Not enough space below, position the widget to open upwards
      this.position.top = Math.max(0, this.position.top - (widgetHeight - availableSpaceBelow));
    }
    this.segment.trackInAppEvents('Clicked On Getting Started Trial Widget',  { widgetmode: 'expanded' });
    document.querySelector('#hubspot-messages-iframe-container')?.classList.add('expanded');
  }

  onTrialWidgetPanelClosed(): void {
    // Reset position to be at the bottom of the screen
    this.position.top = Math.max(0, window.innerHeight - this.widgetHeight - 20);
    this.segment.trackInAppEvents('Clicked On Getting Started Trial Widget', { widgetmode: 'collapsed'});
    document.querySelector('#hubspot-messages-iframe-container')?.classList.remove('expanded');
  }
}
