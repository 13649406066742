<header class="section-header">
  <h3 class="section-title">{{ 'billing.chooseYourPlan' | translate }}</h3>
</header>

<mat-radio-group
  class="payment-recurrence-wrapper"
  [value]="payRecurrence" (change)="handlePayRecurrenceChange($event)"
  [(ngModel)]="payRecurrence"
  aria-label="payment recurrence"
>
  <mat-radio-button
    value="year" color="primary"
    [ngClass]="payRecurrence === 'year' ? 'text-bold' : 'recurrence-option'"
    [trackClick]="{ page: 'Selected Plan Terms', terms: 'Pay Annually' }"
  >
    {{'billing.planOfferAnnually' | translate}}
  </mat-radio-button>
  <mat-radio-button
    value="month" color="primary"
    [ngClass]="payRecurrence === 'month' ? 'text-bold' : 'recurrence-option'"
    [trackClick]="{ page: 'Selected Plan Terms', terms: 'Pay Monthly' }"
  >
    {{'billing.payMonthly' | translate}}
  </mat-radio-button>
</mat-radio-group>

<div class="plan-cards-wrapper">
  <app-plan-card
    *ngFor="let cardPlan of plans"
    [plan]="cardPlan.plan" [currentPlan]="currentPlan"
    [showPopularBadge]="cardPlan.isPopular"
    [payRecurrence]="payRecurrence"
    (planCardBtnClick)="planCardBtnClick.emit($event)"
  ></app-plan-card>
</div>

