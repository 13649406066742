import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { CheckoutEventsData, CheckoutEventsPayment } from '@paddle/paddle-js';
import { lastValueFrom } from 'rxjs';
import { CheckoutComponent } from 'src/app/components/new-billing/checkout/checkout.component';
import { AddCreditCardStep } from 'src/app/components/starter-sidebar/items/add-credit-card';
import { ConcretePlan } from 'src/app/pages/new-billing/models/plan.model';
import { LegacyPricingPlans } from 'src/app/pages/new-billing/types/plan.types';
import { SetCheckoutSuccessInfo } from 'src/app/pages/new-subscribe-plans/store/actions';
import { CompleteWelcomeStep } from 'src/app/store/onboarding/onboarding.actions';
import { BillingActionsService } from '../billing/billing-actions.service';
import { CompanyService } from '../company/company.service';
import { TrackingContextType } from '../tracking/context';

@Injectable({
  providedIn: 'root',
})
export class PlanSelectionService {
  currentPlan: ConcretePlan;
  isCheckoutOpened: boolean;

  constructor(
    private readonly billingActions: BillingActionsService,
    private readonly companyService: CompanyService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly translateService: TranslateService,
  ) {}

  public handlePlanSelection(plan: ConcretePlan, socialProof: boolean, trackingCtx: Partial<TrackingContextType>) {
    if (this.isCheckoutOpened) return;
    this.isCheckoutOpened = true;
    const dlg = this.billingActions.openCheckoutDialog({
      currentPlan: plan,
      planId: plan.planId,
      checkoutSuccessCallback: (eventData, dialogRef) => this.checkoutSuccessCallback(eventData, dialogRef),
      socialProof,
      trackingCtx,
    });
    dlg?.afterClosed()?.subscribe(() => {
      this.isCheckoutOpened = false;
    });
  }

  private async checkoutSuccessCallback(
    { payment }: CheckoutEventsData,
    dialogRef: MatDialogRef<CheckoutComponent>,
  ) {
    const { componentInstance } = dialogRef;
    this.store.dispatch(new CompleteWelcomeStep(AddCreditCardStep.stepId));

    this.updateCompanyPlan(componentInstance);
    await this.setCheckoutSuccessInfo(payment, componentInstance);
    this.redirectToSuccessPage(dialogRef);
  }

  private updateCompanyPlan(componentInstance: CheckoutComponent): void {
    const planId = componentInstance.dialogData.planId as LegacyPricingPlans;
    this.companyService.switchCurrentCompanyPlan(planId);
  }

  private async setCheckoutSuccessInfo(
    payment: CheckoutEventsPayment,
    componentInstance: CheckoutComponent,
  ): Promise<void> {
    const planInDialog = componentInstance.currentPlan;
    const subscriptionRecurrence = planInDialog.paymentModel.paymentRecurrence === 'month' ? 'monthly' : 'yearly';
    const subscriptionChargeRecurrence = this.translateService.instant(`subscription.${subscriptionRecurrence}`);

    await lastValueFrom(this.store.dispatch(new SetCheckoutSuccessInfo({
      cardInfo: {
        cardBrand: payment.method_details.card.type,
        cardLastFour: payment.method_details.card.last4,
      },
      planInfo: {
        name: planInDialog.name,
        recurrence: planInDialog.paymentModel.paymentRecurrence,
        unitPrice: planInDialog.paymentModel.nextPaymentPerUser,
      },
      hasAddons: planInDialog.paymentModel.hasAddons,
      nextPaymentDate: planInDialog.paymentModel.nextPaymentDate,
      totalAmount: planInDialog.paymentModel.nextPaymentAmountCalculated,
      billableUsers: planInDialog.paymentModel.billableUsers,
      title: 'subscription.allSet',
      description: 'subscription.plan',
      type: 'success',
      isEndOfTrialExp: true,
      paymentSummaryText: 'subscription.paymentSummary',
      buttons: {
        action: {
          text: 'subscription.goToDashboard',
          onClick: () => { this.router.navigate(['/dashboard']); },
          trackingCtx: { page: 'Clicked Continue to Dashboard' },
        },
      },
      footerText: this.translateService.instant(`subscription.charged`, { plan: subscriptionChargeRecurrence }),
    })));
  }

  private redirectToSuccessPage(dialogRef: MatDialogRef<CheckoutComponent>): void {
    dialogRef.close();
    this.router.navigate(['/checkout-success']);
  }
}
